export const TWEET_LABELS = {
  bot: "BOT",
  unsure: "UNCERTAIN",
  human: "HUMAN",
};

export const routerBasePath = process.env.REACT_APP_ROUTER_BASE
  ? process.env.REACT_APP_ROUTER_BASE
  : "";

export const THRESHOLD_BOT = 0.65;
export const THRESHOLD_UNSURE = 0.5;

export const API_URL = process.env.REACT_APP_API_URL || "https://api.spot-a-bot.net";

// static for now
export const featureExplanations: {
  [key: string]: { title: string; explanation: string };
} = {
  "Tweet Count": {
    title: "Tweet Count",
    explanation: "The total number of tweets.",
  },
  "Follower Count": {
    title: "Follower Count",
    explanation: "The total number of followers.",
  },
  "Following Count": {
    title: "Following Count",
    explanation: "The total number of accounts this user follows.",
  },
  "Mentions Ratio": {
    title: "Avg Mentions per Tweet",
    explanation: "The average amount of @mentions.",
  },
  "Url Ratio": {
    title: "Avg URLs per Tweet",
    explanation: "The average amount of urls posted with the tweet.",
  },
  "Emoji Ratio": {
    title: "Avg Emojis per Tweet",
    explanation: "The average amount of emojis used.",
  },
  "Avg Hashtags": {
    title: "Avg Hashtags per Tweet",
    explanation: "The average amount of hashtags used by the users.",
  },
  "Retweet Average": {
    title: "Avg Retweets per Tweet",
    explanation: "The average amount of retweets per tweet.",
  },
  "Reply Average": {
    title: "Avg Replies per Tweet",
    explanation: "The average amount of replies per tweet.",
  },
  "Quote Average": {
    title: "Avg Quotes per Tweet",
    explanation: "The average number of quote tweets per tweet.",
  },
  "Like Average": {
    title: "Avg Likes per Tweet",
    explanation: "The average number of likes per tweet.",
  },
  "Cosine Sim": {
    title: "Text Cosine Similarity",
    explanation:
      "A measurement based on vectors to determine tweet similarity.",
  },
  "Levenshtein Distance": {
    title: "Text Variance Levenshtein",
    explanation:
      "Similarity between tweets, based on edits needed to make them equal.",
  },
  "Jaccard Distance": {
    title: "Text Variance Jaccard",
    explanation: "Similarity between tweets, based on size of intersection.",
  },
  "Var Tweet Length": {
    title: "Variance Tweet Length",
    explanation: "The amount of how much a tweet varies in length.",
  },
  "Avg Tweet Length": {
    title: "Avg Tweet Length",
    explanation: "The average length of a tweet.",
  },
  "External Url Ratio": {
    title: "Avg External Urls per Tweet",
    explanation: "The average amount of urls linking to an external target.",
  },
  "Unique Diff Ratio": {
    title: "Unique Difference Ratio",
    explanation: "The ratio of structurally unique tweets.",
  },
  "Avg Tweet Per Day": {
    title: "Avg Tweets per Day",
    explanation: "The average number of tweets per day.",
  },
  "Following Account Age Ratio": {
    title: "Avg Followings per Day",
    explanation: "The amount of accounts followed in relation to accounts age.",
  },
  "Follower Account Age Ratio": {
    title: "Avg Follower per Day",
    explanation: "The amount of followers in relation to accounts age.",
  },
  "Ff Ratio": {
    title: "Number of Friends per Follower",
    explanation: "The ratio of followers to followed accounts.",
  },
};
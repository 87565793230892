import { useEffect, useState } from "react";
import { TrendStatistics } from "../types/TrendStatistics";
import { API_URL } from "../constants";
import { TweetType } from "../types/TweetType";
import { AuthorDetails } from "../types/AuthorDetails";
import { TweetTypeLabeled } from "../types/TweetTypeLabeled";
import {TrendHistoryType} from "../types/TrendHistoryType";
import {TrendHistoryObjectType} from "../types/TrendHistoryObjectType";
import {ChartHistoryEntry} from "../types/ChartHistoryEntry";
import {formatDate} from "../utils/formatDate";

type ApiResponse = {
  data: any;
  error: any;
  loading: boolean;
};

type TrendsApiResponse = Omit<ApiResponse, "data"> & {
  data: TrendStatistics[];
};

type TrendAndTweetsResponse = Omit<ApiResponse, "data"> & {
  trend: TrendStatistics;
  tweets: TweetType[];
};

type AuthorAndAuthorTweetsResponse = Omit<ApiResponse, "data"> & {
  authorDetails: AuthorDetails;
  authorTweets: TweetTypeLabeled[];
};

type AuthorResponse = Omit<ApiResponse, "data"> & {
  authorDetails: AuthorDetails;
};

type TrendHistoryResponse = Omit<ApiResponse, "data"> & {
  trendHistory: TrendHistoryObjectType[];
  chartData: ChartHistoryEntry[];
  dateRange: { min: Date, max: Date};
};

const useFetch = (url: string): ApiResponse => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [url]);

  return { data, loading, error };
};

const useTrendsFetch = (): TrendsApiResponse => {
  return useFetch(`${API_URL}/trends`) as TrendsApiResponse;
};

const useRandomAuthorFetch = (
  trendName: string,
  lastAuthorId: string
): AuthorResponse => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/trends/${trendName}/random/${lastAuthorId}`)
      .then((res) => res.json())
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [lastAuthorId, trendName]);

  return {
    authorDetails: data,
    loading,
    error,
  } as AuthorResponse;
};

const useTrendAndTweetsFetch = (trendName: string): TrendAndTweetsResponse => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    Promise.all([
      fetch(`${API_URL}/trends/${encodeURIComponent(String(trendName))}`),
      fetch(
        `${API_URL}/trends/${encodeURIComponent(String(trendName))}/tweets`
      ),
    ])
      .then((responses) => Promise.all(responses.map((res) => res.json())))
      .then(([trend, tweets]) => {
        if (trend.hasOwnProperty("error") || tweets.hasOwnProperty("error")) {
          throw new Error("HTTP Error Response when calling trends and tweets");
        } else {
          setData({ trend, tweets });
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [trendName]);

  return {
    trend: data?.trend,
    tweets: data?.tweets,
    loading,
    error,
  } as TrendAndTweetsResponse;
};

const useAuthorAndAuthorTweetsFetch = (
  authorId: string
): AuthorAndAuthorTweetsResponse => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    Promise.all([
      fetch(`${API_URL}/authors/${authorId}`),
      fetch(`${API_URL}/authors/${authorId}/tweets`),
    ])
      .then(([authorResponse, tweetsResponse]) => {
        return Promise.all([authorResponse.json(), tweetsResponse.json()]);
      })
      .then(([authorDetails, authorTweets]) => {
        if (
          authorDetails.hasOwnProperty("error") ||
          authorTweets.hasOwnProperty("error")
        ) {
          throw new Error(
            "HTTP Error Response when calling author details and author tweets"
          );
        } else {
          setData({ authorDetails, authorTweets });
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [authorId]);

  return {
    authorDetails: data?.authorDetails,
    authorTweets: data?.authorTweets,
    loading,
    error,
  } as AuthorAndAuthorTweetsResponse;
};

const useTrendHistoryFetch = (): TrendHistoryResponse => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/history`)
        .then(res => res.json())
        .then(trendHistory => {
          const dates: Array<Date> = (trendHistory as Array<TrendHistoryType>)
              .map(history => new Date(history.trended_on))
          const minDate = new Date(new Date(dates.reduce((a, b) => a < b ? a : b)).setHours(0,0,0,0))
          const maxDate = new Date(new Date(dates.reduce((a, b) => a > b ? a : b)).setHours(0,0,0,0))

          // prepare chart data
          const chartHistoryData: Array<ChartHistoryEntry> = []
          trendHistory.forEach((history: TrendHistoryType) => history.countries.forEach((country: string) => {
            // calculate sum to convert bot/human/unsure results to percentages
            const total = history.bot_result + history.human_result + history.unsure_result
            chartHistoryData.push({
              trendName: history.trend_name,
              trendedOn: new Date(new Date(history.trended_on).setHours(0, 0, 0, 0)),
              botResult: history.bot_result / total,
              country: country
            })
          }))

          // prepare data for search
          const groupedHistoryData: Array<TrendHistoryObjectType> = []
          trendHistory.forEach((history: TrendHistoryType) => {
            const index = groupedHistoryData.findIndex(entry => entry.trendName === history.trend_name)
            if(index === -1) {
              groupedHistoryData.push({
                trendName: history.trend_name,
                dates: [formatDate(new Date(history.trended_on))],
                histories: [history]
              })
            }
            else {
              groupedHistoryData[index].dates.push(formatDate(new Date(history.trended_on)))
              groupedHistoryData[index].histories.push(history)
            }
          })

          setData({ trendHistory: groupedHistoryData, chartData: chartHistoryData, dateRange: {min: minDate, max: maxDate} });
        })
        .catch(setError)
        .finally(() => setLoading(false));
  }, []);

  return {
    trendHistory: data?.trendHistory,
    chartData: data?.chartData,
    dateRange: data?.dateRange,
    loading,
    error,
  } as TrendHistoryResponse;

};

export {
  useFetch,
  useTrendsFetch,
  useTrendAndTweetsFetch,
  useAuthorAndAuthorTweetsFetch,
  useRandomAuthorFetch,
  useTrendHistoryFetch
};

import React, {useEffect, useState} from "react";
import AxisLeft from "./AxisLeft";
import AxisBottom from "./AxisBottom";
import Lines from "./Lines";
import {extent, scaleLinear, scaleOrdinal, scaleTime} from "d3";
import Legend from "./Legend";
import Tooltip from "./Tooltip";
import {LineChartProps} from "../../../types/LineChartProps";
import {ChartHistoryEntry} from "../../../types/ChartHistoryEntry";
import {GroupedChartDataType} from "../../../types/GroupedChartDataType";

const getDatesBetweenDates = (startDate: Date, endDate: Date) => {
    let dates: Array<Date> = []
    //to avoid modifying the original date
    const theDate = new Date(startDate)
    while (theDate < endDate) {
        dates = [...dates, new Date(theDate)]
        theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, endDate]
    return dates
}

const LineChart = ({initialWidth, initialHeight, data, dateRange}: LineChartProps) => {
    // DEFINE MARGINS, HEIGHT, WIDTH
    const margin = {top: 20, right: 30, bottom: 30, left: 70}
    const height = initialHeight - margin.top - margin.bottom
    // keep track of width for browser resize
    const [width, setWidth] = useState(initialWidth)

    // PREPARE DATA
    // group data per country
    const groupedData: Array<GroupedChartDataType> = []
    data.forEach((history: ChartHistoryEntry) => {
        const index = groupedData.findIndex(entry => entry.name === history.country)
        if(index === -1) {
            groupedData.push({
                name: history.country,
                values: [{
                    trendName: history.trendName,
                    trendedOn: history.trendedOn,
                    botResult: history.botResult
                }]
            })
        }
        else {
            groupedData[index].values.push({
                trendName: history.trendName,
                trendedOn: history.trendedOn,
                botResult: history.botResult
            })
        }
    })

    // handle missing data
    const dates = getDatesBetweenDates(dateRange.min, dateRange.max).map(d => d.getTime())
    groupedData.forEach((group, index) => {
        const presentDates = group.values.map(g => g.trendedOn.getTime())
        const missingDates = dates.filter((d) => !presentDates.includes(d))
        missingDates.forEach(d => {
            groupedData[index].values.push({
                trendName: "",
                trendedOn: new Date(d),
                botResult: -1
            })
        })
    })

    // sort entries by date
    groupedData.forEach(group => group.values.sort((a, b) => {
        return Number(a.trendedOn) - Number(b.trendedOn)
    }))

    // extract countries
    const countries = groupedData.map(group => group.name)


    // DEFINE SCALES (X, Y, COLOR)
    const scaleX = scaleTime()
        .domain(extent(data, (d) => d.trendedOn) as [Date, Date])
        .range([margin.left, width - margin.right])

    const maxY = Math.round(((Math.max(...data.map(d => d.botResult)) + 0.2) * 10)) / 10 // + 0.2 --> to round up to 20/40/60/80/.. %
    const scaleY = scaleLinear()
        .domain([0, maxY > 1 ? 1 : maxY]) // set max value to 100 (to compensate roundings above 100%)
        .range([height, 0])

    const scaleColor = scaleOrdinal()
        .domain(countries)
        .range(["#0D66FB", "#FB720D", "#6EA3FD"])

    // RESIZE CHART
    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
        return () => window.removeEventListener("resize", () => {
        });
    });

    return (
        <>
            <Legend labels={countries} scaleColor={scaleColor} />
            <div className="relative">
                <svg
                    width={width}
                    height={height + margin.top + margin.bottom}
                >
                    <g transform={`translate(0, ${margin.top})`}>
                        <AxisLeft scale={scaleY} maxY={maxY} width={width} />
                        <AxisBottom scale={scaleX} transform={`translate(0, ${height})`} />
                        <Lines data={groupedData} scaleX={scaleX} scaleY={scaleY} scaleColor={scaleColor} />
                    </g>
                </svg>
                <Tooltip
                    width={width}
                    height={height}
                    margin={margin}
                    transform={`translate(0, ${margin.top})`}
                    data={groupedData}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    scaleColor={scaleColor}/>
            </div>
        </>
    );
};

export default LineChart;

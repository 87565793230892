import React from "react";

function FeatureDiagram() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 827.62 512.81">
            <g id="Group_943" data-name="Group 943" transform="translate(-966.268 -2580.098)">
                <g id="Group_849" data-name="Group 849" transform="translate(733.912 2283.785)">
                    <g id="Rectangle_818" data-name="Rectangle 818" transform="translate(263.824 546)" fill="#0d66fb"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="147" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="146" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="feature_1" data-name="feature 1" transform="translate(337.824 587)" fill="#fff" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-37.829" y="0">feature 1</tspan>
                    </text>
                </g>
                <g id="Group_860" data-name="Group 860" transform="translate(834.912 2093.66)">
                    <g id="Rectangle_818-5" data-name="Rectangle 818" transform="translate(268.824 546)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="137" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="136" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="feature" transform="translate(337.824 587)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-32.676" y="0">feature</tspan>
                    </text>
                </g>
                <g id="Group_851" data-name="Group 851" transform="translate(917.412 2283.785)">
                    <g id="Rectangle_818-6" data-name="Rectangle 818" transform="translate(261.824 546)" fill="#0d66fb"
                       stroke="#1266f9" strokeWidth="1" opacity="0.8">
                        <rect width="151" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="150" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="feature_2" data-name="feature 2" transform="translate(337.824 587)" fill="#fff" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-39.873" y="0">feature 2</tspan>
                    </text>
                </g>
                <g id="Group_852" data-name="Group 852" transform="translate(1289.432 2284.705)">
                    <g id="Rectangle_818-10" data-name="Rectangle 818" transform="translate(261.824 546)" fill="#0d66fb"
                       stroke="#1266f9" strokeWidth="1" opacity="0.2">
                        <rect width="151" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="150" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="feature_4" data-name="feature 4" transform="translate(337.824 587)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-40.376" y="0">feature 4</tspan>
                    </text>
                </g>
                <g id="Group_846" data-name="Group 846" transform="translate(1365.781 2643.527)">
                    <g id="Rectangle_818-14" data-name="Rectangle 818" transform="translate(-17 -4)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="237" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="236" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="public_profile_data_" data-name="public profile data " transform="translate(102 37)"
                          fill="#1266f9" fontSize="20" fontFamily="General Sans Variable">
                        <tspan x="-82.578" y="0">public profile data</tspan>
                    </text>
                </g>
                <g id="Group_859" data-name="Group 859" transform="translate(1627.926 2640.527)">
                    <g id="Rectangle_818-15" data-name="Rectangle 818" fill="none" stroke="#1266f9" strokeWidth="1">
                        <rect width="135" height="65" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="134" height="64" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="tweets_" data-name="tweets " transform="translate(68 40)" fill="#1266f9" fontSize="19"
                          fontFamily="General Sans Variable">
                        <tspan x="-31.643" y="0">tweets</tspan>
                    </text>
                </g>
                <g id="Group_845" data-name="Group 845" transform="translate(834.912 2477.861)">
                    <g id="Rectangle_818-16" data-name="Rectangle 818" transform="translate(221.824 534)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="231" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="230" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="Machine_Learning" data-name="Machine Learning" transform="translate(337.824 575)" fill="#1266f9"
                          fontSize="20" fontFamily="General Sans Variable">
                        <tspan x="-79.573" y="0">Machine Learning</tspan>
                    </text>
                </g>
                <g id="Group_847" data-name="Group 847" transform="translate(-1719.639 4041.115) rotate(-90)">
                    <line id="Line_67" data-name="Line 67" y2="78.621" transform="translate(1368.5 2959.375)" fill="none"
                          stroke="#1266f9" strokeWidth="1"></line>
                </g>
                <g id="Group_918" data-name="Group 918" transform="translate(-200.248 -261.715)">
                    <line id="Line_67-2" data-name="Line 67" y2="69.803" transform="translate(1368.5 2967.375)" fill="none"
                          stroke="#1266f9" strokeWidth="1"></line>
                    <g id="Group_825" data-name="Group 825" transform="translate(-2 -2)">
                        <line id="Line_119" data-name="Line 119" x1="7" y1="7" transform="translate(1363.5 3032.5)" fill="none"
                              stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                        <line id="Line_120" data-name="Line 120" y1="7" x2="7" transform="translate(1370.5 3032.5)" fill="none"
                              stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                    </g>
                </g>
                <g id="Rectangle_1902" data-name="Rectangle 1902" transform="translate(966.268 2808.785)" fill="none"
                   stroke="#1266f9" strokeWidth="1">
                    <rect width="772.453" height="109.842" rx="19" stroke="none"></rect>
                    <rect x="0.5" y="0.5" width="771.453" height="108.842" rx="18.5" fill="none"></rect>
                </g>
                <g id="Group_862" data-name="Group 862" transform="translate(-196.264 -49.865)">
                    <line id="Line_67-3" data-name="Line 67" y2="69.803" transform="translate(1368.5 2967.375)" fill="none"
                          stroke="#1266f9" strokeWidth="1"></line>
                    <g id="Group_825-2" data-name="Group 825" transform="translate(-2 -2)">
                        <line id="Line_119-2" data-name="Line 119" x1="7" y1="7" transform="translate(1363.5 3032.5)"
                              fill="none" stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                        <line id="Line_120-2" data-name="Line 120" y1="7" x2="7" transform="translate(1370.5 3032.5)"
                              fill="none" stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                    </g>
                </g>
                <g id="Group_903" data-name="Group 903" transform="translate(-1637.865 4413.361) rotate(-90)">
                    <line id="Line_67-5" data-name="Line 67" y2="142.164" transform="translate(1368.5 2925.223)" fill="none"
                          stroke="#1266f9" strokeWidth="1"></line>
                </g>
                <g id="Group_906" data-name="Group 906" transform="translate(-20000.943 -18283.732)">
                    <text id="_-_detects_patterns_in_the_features_-_generates_new_features_parameter_-_ranks_importance_of_all_features"
                          data-name="- detects patterns in the features - ranks importance of all features - uses new knowledge for predictions"
                          transform="translate(21503.398 21299.813)" fill="#0d66fb" fontSize="15"
                          fontFamily="General Sans Variable">
                        <tspan x="0" y="15">- detects patterns in the features</tspan>
                        <tspan x="0" y="35">- ranks importance of all features</tspan>
                        <tspan x="0" y="55">- uses these learnings for predictions</tspan>
                    </text>
                    <g id="Group_902" data-name="Group 902" transform="translate(21451.246 21314.57)">
                        <rect id="Rectangle_1711" data-name="Rectangle 1711" width="1.713" height="1.713"
                              transform="matrix(0.707, -0.707, 0.707, 0.707, 10.901, 1.211)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1712" data-name="Rectangle 1712" width="1.713" height="3.426"
                              transform="matrix(0.707, -0.707, 0.707, 0.707, 13.324, 1.211)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1713" data-name="Rectangle 1713" width="3.426" height="1.713"
                              transform="translate(7.268 2.422) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1714" data-name="Rectangle 1714" width="1.713" height="6.852"
                              transform="translate(16.958 2.422) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1715" data-name="Rectangle 1715" width="6.852" height="1.713"
                              transform="translate(1.211 6.056) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1716" data-name="Rectangle 1716" width="1.713" height="1.713"
                              transform="translate(21.803 12.113) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1717" data-name="Rectangle 1717" width="1.713" height="3.426"
                              transform="translate(20.592 8.479) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1718" data-name="Rectangle 1718" width="3.426" height="1.713"
                              transform="translate(20.592 15.746) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1719" data-name="Rectangle 1719" width="6.852" height="1.713"
                              transform="translate(16.958 21.803) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1720" data-name="Rectangle 1720" width="1.713" height="1.713"
                              transform="translate(10.901 23.014) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1721" data-name="Rectangle 1721" width="1.713" height="3.426"
                              transform="translate(7.268 21.803) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1722" data-name="Rectangle 1722" width="3.426" height="1.713"
                              transform="translate(13.324 23.014) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1723" data-name="Rectangle 1723" width="1.713" height="6.852"
                              transform="translate(1.211 18.169) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1724" data-name="Rectangle 1724" width="1.713" height="1.713"
                              transform="translate(0 12.113) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1725" data-name="Rectangle 1725" width="1.713" height="3.426"
                              transform="translate(0 14.535) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1726" data-name="Rectangle 1726" width="3.426" height="1.713"
                              transform="translate(0 9.69) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1727" data-name="Rectangle 1727" width="1.713" height="1.713"
                              transform="translate(18.169 20.591) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1728" data-name="Rectangle 1728" width="1.713" height="1.713"
                              transform="translate(19.38 21.803) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1729" data-name="Rectangle 1729" width="1.713" height="1.713"
                              transform="translate(20.592 23.014) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1730" data-name="Rectangle 1730" width="1.713" height="1.713"
                              transform="translate(21.803 24.225) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1731" data-name="Rectangle 1731" width="1.713" height="1.713"
                              transform="translate(23.014 25.437) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1732" data-name="Rectangle 1732" width="1.713" height="1.713"
                              transform="translate(24.225 26.648) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1733" data-name="Rectangle 1733" width="1.713" height="1.713"
                              transform="translate(25.437 27.859) rotate(-44.99)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1734" data-name="Rectangle 1734" width="1.713" height="1.713"
                              transform="translate(19.38 19.38) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1735" data-name="Rectangle 1735" width="1.713" height="1.713"
                              transform="translate(20.592 20.591) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1736" data-name="Rectangle 1736" width="1.713" height="1.713"
                              transform="translate(21.803 21.803) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1737" data-name="Rectangle 1737" width="1.713" height="1.713"
                              transform="translate(23.014 23.014) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1738" data-name="Rectangle 1738" width="1.713" height="1.713"
                              transform="translate(24.225 24.225) rotate(-45)" fill="#0d66fb"></rect>
                        <rect id="Rectangle_1739" data-name="Rectangle 1739" width="1.713" height="1.713"
                              transform="translate(25.437 25.436) rotate(-44.99)" fill="#0d66fb"></rect>
                    </g>
                    <g id="Rectangle_1920" data-name="Rectangle 1920" transform="translate(21430.465 21280.594)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="358.031" height="96.047" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="357.031" height="95.047" rx="18.5" fill="none"></rect>
                    </g>
                </g>
                <g id="Group_905" data-name="Group 905" transform="translate(1103.432 2284.705)">
                    <g id="Rectangle_818-17" data-name="Rectangle 818" transform="translate(260.824 546)" fill="#0d66fb"
                       stroke="#1266f9" strokeWidth="1" opacity="0.6">
                        <rect width="152" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="151" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="feature_3" data-name="feature 3" transform="translate(337.824 587)" fill="#fff" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-40.485" y="0">feature 3</tspan>
                    </text>
                </g>
                <text id="pre_calculated_by_us_out_of:" data-name="pre calculated by us out of:"
                      transform="translate(1348.857 2602.094)" fill="#0d66fb" fontSize="15"
                      fontFamily="General Sans Variable">
                    <tspan x="116.881" y="15">pre calculated by us out of:</tspan>
                </text>
                <text id="_" data-name="+" transform="translate(1579.686 2657.613)" fill="#0d66fb" fontSize="20"
                      fontFamily="General Sans Variable">
                    <tspan x="22.597" y="20">+</tspan>
                </text>
                <g id="Rectangle_1920-2" data-name="Rectangle 1920" transform="translate(1318.857 2580.098)" fill="none"
                   stroke="#1266f9" strokeWidth="1">
                    <rect width="475.031" height="149.047" rx="19" stroke="none"></rect>
                    <rect x="0.5" y="0.5" width="474.031" height="148.047" rx="18.5" fill="none"></rect>
                </g>
            </g>
        </svg>

    );
}

export default FeatureDiagram;
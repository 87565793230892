import {axisBottom, select, timeFormat} from "d3";
import { useEffect, useRef } from "react";
import {LineChartAxisBottomProps} from "../../../types/LineChartAxisBottomProps";

function AxisBottom({ scale, transform }: LineChartAxisBottomProps) {
    const ref = useRef<SVGGElement>(null);

    useEffect(() => {
        const currentSVGGroup = ref.current;
        if (currentSVGGroup) {
            select(currentSVGGroup)
                .style("font-size", "1rem")
                .call(axisBottom(scale)
                    .ticks(6)
                    .tickSize(0)
                    .tickFormat(timeFormat("%d %b") as (dv: Date | { valueOf(): number }, i: number) => string));

            select(currentSVGGroup)
                .selectAll("text")
                .attr("y", "16")
        }
    }, [scale]);

    return <g ref={ref} transform={transform} />;
}

export default AxisBottom;

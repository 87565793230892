import React from "react";
import { BarExplanationProps } from "../../../types/BarExplanationProps";


function BarExplanation({
  label,
  explanation,
  isNegative,
  onMouseEnter,
  x,
  y,
}: BarExplanationProps) {
  return (
    <g
      id={"g-" + label}
      style={{ opacity: 0 }}
      className="pointer-events-none sm:pointer-events-auto"
      onMouseEnter={() => onMouseEnter()}
      transform={`translate(${x}, ${y})`}
    >
      <text key={`text-${label}`} textAnchor={!isNegative ? "start" : "end"}>
        {explanation}
        <a className="fill-blue" href={"/how-it-works"}>
          {" "}
          More
        </a>
      </text>
    </g>
  );
}

export default BarExplanation;

import React from "react";
import VotingButton from "./VotingButton";
import { useNavigate } from "react-router-dom";
import { useRandomAuthorFetch } from "../../../hooks/customHooks";
import NavigationButton from "./NavigationButton";

type VotingAreaProps = {
  onVote: (vote: boolean) => void;
  alreadyVoted: boolean;
  trendName: string;
  lastAuthorId: string;
  onNavigate: () => void;
};

function VotingArea({
  alreadyVoted,
  onVote,
  trendName,
  lastAuthorId,
  onNavigate,
}: VotingAreaProps) {
  const {
    authorDetails: nextAuthorData,
    error: authorError,
    loading: authorLoading,
  } = useRandomAuthorFetch(trendName, lastAuthorId);
  const navigate = useNavigate();

  const navigateToNewAuthor = () => {
    if (authorError || authorLoading) return;
    onNavigate();
    navigate(`/trend/${trendName}/${nextAuthorData?.author_id}`);
  };

  if (alreadyVoted) {
    return (
      <div className="w-full flex flex-wrap sm:flex-nowrap">
        <NavigationButton
          onClick={() => navigate(`/trend/${encodeURIComponent(trendName)}`)}
          label={"Back to trend"}
          inverted={false}
        />
        <NavigationButton
          onClick={() => navigateToNewAuthor()}
          label={authorError ? "No more authors" : "Check another account"}
          inverted={true}
        />
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex">
        <VotingButton onClick={() => onVote(true)} inverted={false} />
        <VotingButton onClick={() => onVote(false)} inverted={true} />
      </div>
    </>
  );
}

export default VotingArea;

import React from "react";

function USAIcon() {
    return (
        <svg className="h-full" id="Group_752" xmlns="http://www.w3.org/2000/svg" width="116.656" height="82.887" viewBox="0 0 116.656 82.887">
            <g>
                <rect id="Rectangle_221" width="3.07" height="3.07" transform="translate(64.468 6.14)" />
                <rect id="Rectangle_222" width="3.07" height="3.07" transform="translate(35.304 6.14)" />
                <rect id="Rectangle_223" width="3.07" height="3.07" transform="translate(38.374 6.14)" />
                <rect id="Rectangle_224" width="3.07" height="3.07" transform="translate(67.538 9.21)" />
                <rect id="Rectangle_225" width="3.07" height="3.07" transform="translate(70.608 12.28)" />
                <rect id="Rectangle_226" width="3.07" height="3.07" transform="translate(73.678 12.28)" />
                <rect id="Rectangle_227" width="3.07" height="3.07" transform="translate(76.748 15.35)" />
                <rect id="Rectangle_228" width="3.07" height="3.07" transform="translate(76.748 18.419)" />
                <rect id="Rectangle_229" width="3.07" height="3.07" transform="translate(76.748 21.489)" />
                <rect id="Rectangle_230" width="3.07" height="3.07" transform="translate(82.887 15.35)" />
                <rect id="Rectangle_231" width="3.07" height="3.07" transform="translate(82.887 18.419)" />
                <rect id="Rectangle_232" width="3.07" height="3.07" transform="translate(85.957 18.419)" />
                <rect id="Rectangle_233" width="3.07" height="3.07" transform="translate(92.097 18.419)" />
                <rect id="Rectangle_234" width="3.07" height="3.07" transform="translate(95.167 15.35)" />
                <rect id="Rectangle_235" width="3.07" height="3.07" transform="translate(98.237 12.28)" />
                <rect id="Rectangle_236" width="3.07" height="3.07" transform="translate(101.307 9.21)" />
                <rect id="Rectangle_237" width="3.07" height="3.07" transform="translate(104.377 6.14)" />
                <rect id="Rectangle_238" width="3.07" height="3.07" transform="translate(104.377 3.07)" />
                <rect id="Rectangle_239" width="3.07" height="3.07" transform="translate(113.586 6.14)" />
                <rect id="Rectangle_240" width="3.07" height="3.071" transform="translate(113.586 9.209)" />
                <rect id="Rectangle_241" width="3.07" height="3.07" transform="translate(110.516 12.28)" />
                <rect id="Rectangle_242" width="3.07" height="3.07" transform="translate(110.516 15.35)" />
                <rect id="Rectangle_243" width="3.07" height="3.07" transform="translate(113.586 18.419)" />
                <rect id="Rectangle_244" width="3.07" height="3.07" transform="translate(110.516 3.07)" />
                <rect id="Rectangle_245" width="3.07" height="3.07" transform="translate(107.447)" />
                <rect id="Rectangle_246" width="3.07" height="3.07" transform="translate(89.027 21.489)" />
                <rect id="Rectangle_247" width="3.07" height="3.07" transform="translate(107.447 30.699)" />
                <rect id="Rectangle_248" width="3.07" height="3.07" transform="translate(107.447 27.629)" />
                <rect id="Rectangle_249" width="3.07" height="3.07" transform="translate(107.447 24.559)" />
                <rect id="Rectangle_250" width="3.07" height="3.07" transform="translate(101.307 42.979)" />
                <rect id="Rectangle_251" width="3.07" height="3.07" transform="translate(101.307 39.909)" />
                <rect id="Rectangle_252" width="3.07" height="3.07" transform="translate(101.307 36.839)" />
                <rect id="Rectangle_253" width="3.07" height="3.07" transform="translate(110.516 21.489)" />
                <rect id="Rectangle_254" width="3.07" height="3.07" transform="translate(104.377 33.769)" />
                <rect id="Rectangle_255" width="3.07" height="3.07" transform="translate(95.167 52.188)" />
                <rect id="Rectangle_256" width="3.07" height="3.07" transform="translate(95.167 49.118)" />
                <rect id="Rectangle_257" width="3.07" height="3.07" transform="translate(98.237 46.049)" />
                <rect id="Rectangle_258" width="3.07" height="3.07" transform="translate(98.237 55.258)" />
                <rect id="Rectangle_259" width="3.07" height="3.07" transform="translate(101.307 58.328)" />
                <rect id="Rectangle_260" width="3.07" height="3.07" transform="translate(104.377 61.398)" />
                <rect id="Rectangle_261" width="3.07" height="3.07" transform="translate(104.377 64.468)" />
                <rect id="Rectangle_262" width="3.07" height="3.07" transform="translate(101.307 67.538)" />
                <rect id="Rectangle_263" width="3.07" height="3.07" transform="translate(98.237 64.468)" />
                <rect id="Rectangle_264" width="3.07" height="3.07" transform="translate(95.167 61.398)" />
                <rect id="Rectangle_265" width="3.07" height="3.07" transform="translate(92.097 61.398)" />
                <rect id="Rectangle_266" width="3.07" height="3.07" transform="translate(89.027 61.398)" />
                <rect id="Rectangle_267" width="3.07" height="3.07" transform="translate(85.957 64.468)" />
                <rect id="Rectangle_268" width="3.07" height="3.07" transform="translate(82.887 64.468)" />
                <rect id="Rectangle_269" width="3.07" height="3.07" transform="translate(79.817 64.468)" />
                <rect id="Rectangle_270" width="3.07" height="3.07" transform="translate(76.748 67.538)" />
                <rect id="Rectangle_271" width="3.07" height="3.07" transform="translate(73.678 67.538)" />
                <rect id="Rectangle_272" width="3.07" height="3.07" transform="translate(70.608 67.538)" />
                <rect id="Rectangle_273" width="3.07" height="3.07" transform="translate(67.538 67.538)" />
                <rect id="Rectangle_274" width="3.07" height="3.07" transform="translate(64.468 67.538)" />
                <rect id="Rectangle_275" width="3.07" height="3.07" transform="translate(61.398 70.608)" />
                <rect id="Rectangle_276" width="3.07" height="3.07" transform="translate(61.398 73.678)" />
                <rect id="Rectangle_277" width="3.07" height="3.07" transform="translate(55.258 73.678)" />
                <rect id="Rectangle_278" width="3.07" height="3.07" transform="translate(61.398 76.748)" />
                <rect id="Rectangle_279" width="3.07" height="3.07" transform="translate(55.258 76.748)" />
                <rect id="Rectangle_280" width="3.071" height="3.07" transform="translate(58.328 79.817)" />
                <rect id="Rectangle_281" width="3.07" height="3.07" transform="translate(52.188 70.608)" />
                <rect id="Rectangle_282" width="3.07" height="3.07" transform="translate(49.118 67.538)" />
                <rect id="Rectangle_283" width="3.07" height="3.07" transform="translate(46.049 70.608)" />
                <rect id="Rectangle_284" width="3.07" height="3.07" transform="translate(42.979 70.608)" />
                <rect id="Rectangle_285" width="3.07" height="3.07" transform="translate(39.909 67.538)" />
                <rect id="Rectangle_286" width="3.07" height="3.07" transform="translate(36.839 64.468)" />
                <rect id="Rectangle_287" width="3.07" height="3.07" transform="translate(30.699 64.468)" />
                <rect id="Rectangle_288" width="3.07" height="3.07" transform="translate(27.629 64.468)" />
                <rect id="Rectangle_289" width="3.07" height="3.07" transform="translate(24.559 64.468)" />
                <rect id="Rectangle_290" width="3.07" height="3.07" transform="translate(21.489 61.398)" />
                <rect id="Rectangle_291" width="3.07" height="3.07" transform="translate(18.419 58.328)" />
                <rect id="Rectangle_292" width="3.07" height="3.07" transform="translate(15.35 58.328)" />
                <rect id="Rectangle_293" width="3.07" height="3.07" transform="translate(12.28 58.328)" />
                <rect id="Rectangle_294" width="3.07" height="3.07" transform="translate(9.21 55.258)" />
                <rect id="Rectangle_295" width="3.07" height="3.07" transform="translate(6.14 52.188)" />
                <rect id="Rectangle_296" width="3.07" height="3.07" transform="translate(3.07 49.118)" />
                <rect id="Rectangle_297" width="3.07" height="3.07" transform="translate(0 46.049)" />
                <rect id="Rectangle_298" width="3.07" height="3.071" transform="translate(0 42.978)" />
                <rect id="Rectangle_299" width="3.07" height="3.07" transform="translate(0 39.909)" />
                <rect id="Rectangle_300" width="3.07" height="3.071" transform="translate(0 36.838)" />
                <rect id="Rectangle_301" width="3.07" height="3.07" transform="translate(0 33.769)" />
                <rect id="Rectangle_302" width="3.07" height="3.071" transform="translate(0 30.698)" />
                <rect id="Rectangle_303" width="3.07" height="3.07" transform="translate(0 27.629)" />
                <rect id="Rectangle_304" width="3.07" height="3.07" transform="translate(0 24.559)" />
                <rect id="Rectangle_305" width="3.07" height="3.071" transform="translate(0 21.489)" />
                <rect id="Rectangle_306" width="3.07" height="3.07" transform="translate(3.07 18.419)" />
                <rect id="Rectangle_307" width="3.07" height="3.07" transform="translate(3.07 15.35)" />
                <rect id="Rectangle_308" width="3.07" height="3.07" transform="translate(3.07 12.28)" />
                <rect id="Rectangle_309" width="29.164" height="3.07" transform="translate(6.135 9.209)" />
                <rect id="Rectangle_310" width="23.024" height="3.07" transform="translate(41.444 9.21)" />
                <rect id="Rectangle_311" width="3.07" height="3.07" transform="translate(33.769 61.398)" />
                <rect id="Rectangle_312" width="3.07" height="3.07" transform="translate(82.887 21.489)" />
                <rect id="Rectangle_313" width="3.07" height="3.07" transform="translate(79.817 24.559)" />
            </g>
        </svg>

    );
}

export default USAIcon;
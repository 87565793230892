import React, { useState, MouseEvent, PropsWithChildren } from "react";

function ScrollDragContainer({ children }: PropsWithChildren<{}>) {
  const scrollContainer = React.useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [disabledArrows, setDisabledArrows] = useState({
    left: true,
    right: false,
  });

  const itemWidth = 352;

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging || !scrollContainer.current) return;
    event.preventDefault();
    scrollContainer.current.scrollLeft -= event.movementX;
    checkArrowDisable();
  };

  const checkArrowDisable = () => {
    if (!scrollContainer.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
    setDisabledArrows({
      left: scrollLeft <= 0,
      right: scrollWidth - clientWidth <= scrollLeft + 2,
    });
  };

  const handleScrollArrow = (forwards: boolean) => {
    if (!scrollContainer.current) return;
    const scrollAmount = forwards ? itemWidth : -itemWidth;

    scrollContainer.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.current;
    setDisabledArrows({
      left: !forwards && scrollLeft <= itemWidth,
      right: forwards && scrollWidth - clientWidth < scrollLeft + itemWidth,
    });
  };

  return (
    <div className="inline-flex w-full">
      <button
        className="flex self-end md:self-auto sm:inline-block mr-0 mt-4 z-30 md:mr-4 md:mt-0 select-none text-4xl text-blue disabled:text-gray-300"
        onClick={() => handleScrollArrow(false)}
        disabled={disabledArrows.left}
      >
        &larr;
      </button>
      <div className="-mx-6 my-4 md:m-0 relative overflow-hidden w-full">
        <div
          data-testid="scroll-container"
          ref={scrollContainer}
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          onMouseLeave={() => setIsDragging(false)}
          onMouseMove={handleMouseMove}
          onTouchMove={() => checkArrowDisable()}
          className="scroll-drag-container no-scrollbar overflow-x-scroll inline-flex py-8 px-2 md:px-6 w-full cursor-grab active:cursor-grabbing items-center"
        >
          {children}
        </div>
        <div className="absolute pointer-events-none top-0 w-full h-full side-gradient z-10"></div>
      </div>

      <button
        className="flex self-end md:self-auto sm:inline-block mr-0 mt-4 z-30 md:mr-4 md:mt-0 select-none text-4xl text-blue disabled:text-gray-300 ml-auto"
        onClick={() => handleScrollArrow(true)}
        disabled={disabledArrows.right}
      >
        &rarr;
      </button>
    </div>
  );
}

export default ScrollDragContainer;

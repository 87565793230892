import React from "react";
import { Link } from "react-router-dom";
import HomepageBanner from "./HomepageBanner";
import { useTrendsFetch } from "../../../hooks/customHooks";
import Skeleton from "react-loading-skeleton";
import NotAvailable from "../../Functionality/NotAvailable";

function HomePageBannerContainer() {
  const { data: trends, loading, error } = useTrendsFetch();
  const arrayOfN = [...Array(3)];
  if (error) {
    return <NotAvailable notice={"No current trends available."} />;
  }

  return (
    <>
      {loading
        ? arrayOfN.map((_, i) => {
            return (
              <div key={i} className="border-b border-black py-8">
                <Skeleton highlightColor={"#0D66FB"} height={44} />
              </div>
            );
          })
        : trends.map((trend, index) => (
            <Link title="Trend"
              to={`/trend/${encodeURIComponent(trend.trend_name)}`}
              key={index}
              data-cy={`current-trend-${index}`}
            >
              <HomepageBanner trend={trend} />
            </Link>
          ))}
    </>
  );
}

export default HomePageBannerContainer;

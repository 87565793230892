import React from "react";
import { jsPDF } from "jspdf";
import {PDFReportProps} from "../../types/PDFReportProps";
import {TrendStatistics} from "../../types/TrendStatistics";
import {routerBasePath} from "../../constants";
import IconButton from "../Functionality/IconButton";

const PDFReport = ({trendStatistics}: PDFReportProps) => {
    return <IconButton iconPath={`${routerBasePath}/img/pdf_icon.svg`} iconAltText={"PDF icon"} onClick={async () => {
        downloadReport(makeStatistics(trendStatistics), getFileName(trendStatistics.trend_name,trendStatistics.updated_at))
    }} label="PDF Report" />
}

const getFileName = (trendName:string, time: string) => {
    let newTime = time.replace(/ +/g, "-").replace(",","").replace(/:+/g,"_");
    return trendName+"-"+ newTime + ".pdf"
}

const makeStatistics = (trendStatistics: TrendStatistics) => {
    const statistics :{Metric: string|undefined, Value:string|number|undefined}[] = [
        {
            Metric: "Trend name",
            Value: trendStatistics?.trend_name,
        },
        {
            Metric: "Completed analysis on",
            Value: trendStatistics?.created_at,
        },
        {
            Metric: "Countries",
            Value: trendStatistics?.countries.join(", "),
        },
        {
            Metric: "Tweets analyzed",
            Value: trendStatistics.tweet_size +""
        },
        {
            Metric: "Trend tweet volume",
            Value: trendStatistics.tweet_volume! +""
        },
        {
            Metric: "Analyzed accounts",
            Value: trendStatistics.author_size +""
        },
        {
            Metric: "Bot",
            Value:  trendStatistics.bot_result + " ("+ Math.round((trendStatistics.bot_result/trendStatistics.author_size)*100) + "%)"
        },
        {
            Metric: "Unsure",
            Value: trendStatistics.unsure_result + " ("+ Math.round((trendStatistics.unsure_result/trendStatistics.author_size)*100) + "%)"
        },
        {
            Metric: "Human",
            Value: trendStatistics.human_result + " ("+Math.round((trendStatistics.human_result/trendStatistics.author_size)*100) + "%)"
        },
        {
            Metric: "Unsure threshold",
            Value: "50-65%"
        },
        {
            Metric: "Bot threshold",
            Value: "65-100%"
        },

    ];

    return statistics;
}

function createHeaders(keys: string[]) {
    let result = [];
    for (let i = 0; i < keys.length; i += 1) {
        result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 350,
            align: "left",
            padding: 25
        });
    }
    return result;
}

function downloadReport(trendStats: {Metric: string|undefined, Value:string|number|undefined}[], fileName:string){

    const report = new jsPDF("portrait","pt","a4");

    report.setFillColor(50,121,239);
    report.rect(0,0,600,60, "F");
    const logo_img = `${routerBasePath}/botwatch_logo.png`
    report.addImage(logo_img, "png", 190, 15, 225, 29)

    report.text("Trend analysis report",40,95)

    // @ts-ignore
    report.table(35,120,trendStats,createHeaders(["Metric","Value"]))

    const qr_img = `${routerBasePath}/qr.png`
    report.addImage(qr_img, "png", 220, 400, 150, 150)

    report.text("spot-a-bot.net",256,570)

    report.save(fileName)
}

export default PDFReport;

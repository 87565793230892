import React from "react";

function Imprint() {

    return (
      <React.Fragment>
        <div className="bg-blue text-white pt-20 pb-6 text-left">
          <div className="max-w-[850px] mx-auto px-6">
            <h1 className="text-xl max-w-4xl font-bot mb-6">IMPRINT</h1>
            <p className="mb-4 italic">
              Information obligation according to §5 E-Commerce Law, §14
              Corporate Code, §63 Trade Regulations and disclosure obligation
              according to §25 Media Act.
            </p>
          </div>
        </div>
        <div>
          <div className="px-6 pt-6 pb-12 max-w-[850px] mx-auto text-left">
            <p>
              Spot A Bot is a master's project by six students at the Salzburg
              University of Applied Sciences.
            </p>
            <address className="not-italic mb-12">
              If you are interested in working with us or just want to know more
              about the project, simply reach out to us on{" "}
              <a
                target="_blank"
                href="https://twitter.com/SpotABot_"
                rel="noreferrer"
                className="link-underline-blue"
              >
                Twitter
              </a>
              !
              <br />
              <br />
              <strong>University of Applied Sciences Salzburg</strong>
              <br />
              Urstein Süd 3
              <br />
              5412 Puch bei Hallein, Austria
            </address>
            <h2 className="mb-2 text-md">Meet the team behind Spot A Bot</h2>
            <h3 className="mb-2 text-base">Idea, Concept and Development</h3>
            <ul className="list-disc ml-6 mb-4">
              <li className="text-blue my-3">
                <a
                  target="_blank"
                  href="https://twitter.com/nckgrf"
                  rel="noreferrer"
                  className="link-underline-blue"
                >
                  Nicolas Graf
                </a>
              </li>
              <li className="text-blue my-3">
                <a
                  target="_blank"
                  href="https://twitter.com/lenahglr"
                  rel="noreferrer"
                  className="link-underline-blue"
                >
                  Lena Heiglauer
                </a>
              </li>
              <li className="text-blue my-3">
                <a
                  target="_blank"
                  href="https://twitter.com/realKevHarizaj"
                  rel="noreferrer"
                  className="link-underline-blue"
                >
                  Kevin Harizaj
                </a>
              </li>
              <li className="text-blue my-3">
                <a
                  target="_blank"
                  href="https://twitter.com/Dadonia_"
                  rel="noreferrer"
                  className="link-underline-blue"
                >
                  Daniela Dottolo
                </a>
              </li>
            </ul>
            <h3 className="mb-2 text-md">Design Concept</h3>
            <ul className="list-disc ml-6 mb-4">
              <li className="my-3">Denise Hoedl</li>
              <li className="my-3">Magdalena Umkehrer</li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Imprint;

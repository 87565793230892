import React from "react";

function GermanyIcon() {
    return (
        <svg className="h-full" xmlns="http://www.w3.org/2000/svg" width="90.586" height="110.662" viewBox="0 0 90.586 110.662">
            <g id="germany" className="h-full" transform="translate(-91.53 -56.22)">
                <rect id="Rectangle_1149" width="2.518" height="2.518" transform="translate(134.304 164.364)" />
                <rect id="Rectangle_1150" width="2.518" height="2.518" transform="translate(136.822 164.364)" />
                <rect id="Rectangle_1151" width="2.518" height="2.518" transform="translate(139.337 164.364)" />
                <rect id="Rectangle_1152" width="2.518" height="2.518" transform="translate(141.854 164.364)" />
                <rect id="Rectangle_1153" width="2.518" height="2.518" transform="translate(144.369 164.364)" />
                <rect id="Rectangle_1154" width="2.518" height="2.518" transform="translate(146.887 161.846)" />
                <rect id="Rectangle_1155" width="2.518" height="2.518" transform="translate(149.401 161.846)" />
                <rect id="Rectangle_1156" width="2.518" height="2.518" transform="translate(151.919 161.846)" />
                <rect id="Rectangle_1157" width="2.518" height="2.518" transform="translate(154.437 159.332)" />
                <rect id="Rectangle_1158" width="2.518" height="2.518" transform="translate(156.951 161.846)" />
                <rect id="Rectangle_1159" width="2.518" height="2.518" transform="translate(159.469 159.332)" />
                <rect id="Rectangle_1160" width="2.518" height="2.518" transform="translate(156.951 156.814)" />
                <rect id="Rectangle_1161" width="2.518" height="2.518" transform="translate(154.437 154.299)" />
                <rect id="Rectangle_1162" width="2.518" height="2.518" transform="translate(156.951 151.782)" />
                <rect id="Rectangle_1163" width="2.518" height="2.518" transform="translate(159.469 151.782)" />
                <rect id="Rectangle_1164" width="2.518" height="2.518" transform="translate(161.984 151.782)" />
                <rect id="Rectangle_1165" width="2.518" height="2.518" transform="translate(161.984 149.264)" />
                <rect id="Rectangle_1166" width="2.518" height="2.518" transform="translate(164.501 146.749)" />
                <rect id="Rectangle_1167" width="2.518" height="2.518" transform="translate(156.951 136.684)" />
                <rect id="Rectangle_1168" width="2.518" height="2.518" transform="translate(156.951 134.167)" />
                <rect id="Rectangle_1169" width="2.518" height="2.518" transform="translate(154.437 131.652)" />
                <rect id="Rectangle_1170" width="2.518" height="2.518" transform="translate(154.437 129.134)" />
                <rect id="Rectangle_1171" width="2.518" height="2.518" transform="translate(151.919 126.62)" />
                <rect id="Rectangle_1172" width="2.518" height="2.518" transform="translate(151.919 124.102)" />
                <rect id="Rectangle_1173" width="2.518" height="2.518" transform="translate(154.437 121.587)" />
                <rect id="Rectangle_1174" width="2.518" height="2.518" transform="translate(156.951 121.587)" />
                <rect id="Rectangle_1175" width="2.518" height="2.518" transform="translate(159.469 119.069)" />
                <rect id="Rectangle_1176" width="2.518" height="2.518" transform="translate(161.984 119.069)" />
                <rect id="Rectangle_1177" width="2.518" height="2.518" transform="translate(164.501 116.555)" />
                <rect id="Rectangle_1178" width="2.518" height="2.518" transform="translate(167.016 116.555)" />
                <rect id="Rectangle_1179" width="2.518" height="2.518" transform="translate(169.534 114.037)" />
                <rect id="Rectangle_1180" width="2.518" height="2.518" transform="translate(177.081 114.037)" />
                <rect id="Rectangle_1181" width="2.518" height="2.518" transform="translate(174.566 114.037)" />
                <rect id="Rectangle_1182" width="2.518" height="2.518" transform="translate(172.049 111.522)" />
                <rect id="Rectangle_1183" width="2.518" height="2.518" transform="translate(179.599 111.522)" />
                <rect id="Rectangle_1184" width="2.518" height="2.518" transform="translate(177.081 109.062)" />
                <rect id="Rectangle_1185" width="2.518" height="2.518" transform="translate(177.081 106.544)" />
                <rect id="Rectangle_1186" width="2.518" height="2.518" transform="translate(177.081 104.029)" />
                <rect id="Rectangle_1187" width="2.518" height="2.518" transform="translate(174.566 101.512)" />
                <rect id="Rectangle_1188" width="2.518" height="2.518" transform="translate(174.566 98.997)" />
                <rect id="Rectangle_1189" width="2.518" height="2.518" transform="translate(174.566 96.479)" />
                <rect id="Rectangle_1190" width="2.518" height="2.518" transform="translate(174.566 93.965)" />
                <rect id="Rectangle_1191" width="2.518" height="2.518" transform="translate(174.566 91.447)" />
                <rect id="Rectangle_1192" width="2.518" height="2.518" transform="translate(172.049 88.932)" />
                <rect id="Rectangle_1193" width="2.518" height="2.518" transform="translate(169.534 86.414)" />
                <rect id="Rectangle_1194" width="2.518" height="2.518" transform="translate(172.049 83.9)" />
                <rect id="Rectangle_1195" width="2.518" height="2.518" transform="translate(172.049 81.382)" />
                <rect id="Rectangle_1196" width="2.518" height="2.518" transform="translate(172.049 78.867)" />
                <rect id="Rectangle_1197" width="2.518" height="2.518" transform="translate(172.049 76.35)" />
                <rect id="Rectangle_1198" width="2.518" height="2.518" transform="translate(164.501 68.802)" />
                <rect id="Rectangle_1199" width="2.518" height="2.518" transform="translate(167.016 71.317)" />
                <rect id="Rectangle_1200" width="2.518" height="2.518" transform="translate(167.016 68.802)" />
                <rect id="Rectangle_1201" width="2.518" height="2.518" transform="translate(159.469 66.285)" />
                <rect id="Rectangle_1202" width="2.518" height="2.518" transform="translate(151.919 66.285)" />
                <rect id="Rectangle_1203" width="2.518" height="2.518" transform="translate(149.401 68.802)" />
                <rect id="Rectangle_1204" width="2.518" height="2.518" transform="translate(146.887 71.317)" />
                <rect id="Rectangle_1205" width="2.518" height="2.518" transform="translate(144.369 71.317)" />
                <rect id="Rectangle_1206" width="2.518" height="2.518" transform="translate(141.854 68.802)" />
                <rect id="Rectangle_1207" width="2.518" height="2.518" transform="translate(141.854 66.285)" />
                <rect id="Rectangle_1208" width="2.518" height="2.518" transform="translate(139.337 63.767)" />
                <rect id="Rectangle_1209" width="2.518" height="2.518" transform="translate(136.822 63.767)" />
                <rect id="Rectangle_1210" width="2.518" height="2.518" transform="translate(134.304 66.285)" />
                <rect id="Rectangle_1211" width="2.518" height="2.518" transform="translate(131.789 63.767)" />
                <rect id="Rectangle_1212" width="2.518" height="2.518" transform="translate(131.789 61.252)" />
                <rect id="Rectangle_1213" width="2.518" height="2.518" transform="translate(131.789 58.735)" />
                <rect id="Rectangle_1214" width="2.518" height="2.518" transform="translate(129.272 58.735)" />
                <rect id="Rectangle_1215" width="2.518" height="2.518" transform="translate(126.757 58.735)" />
                <rect id="Rectangle_1216" width="2.518" height="2.518" transform="translate(124.239 56.22)" />
                <rect id="Rectangle_1217" width="2.518" height="2.518" transform="translate(121.724 56.22)" />
                <rect id="Rectangle_1218" width="2.518" height="2.518" transform="translate(119.207 56.22)" />
                <rect id="Rectangle_1219" width="2.518" height="2.518" transform="translate(119.207 58.735)" />
                <rect id="Rectangle_1220" width="2.518" height="2.518" transform="translate(121.724 61.252)" />
                <rect id="Rectangle_1221" width="2.518" height="2.518" transform="translate(121.724 63.767)" />
                <rect id="Rectangle_1222" width="2.518" height="2.518" transform="translate(119.207 66.285)" />
                <rect id="Rectangle_1223" width="2.518" height="2.518" transform="translate(121.724 68.802)" />
                <rect id="Rectangle_1224" width="2.518" height="2.518" transform="translate(121.724 71.317)" />
                <rect id="Rectangle_1225" width="2.518" height="2.518" transform="translate(119.207 78.867)" />
                <rect id="Rectangle_1226" width="2.518" height="2.518" transform="translate(116.692 76.35)" />
                <rect id="Rectangle_1227" width="2.518" height="2.518" transform="translate(119.207 73.835)" />
                <rect id="Rectangle_1228" width="2.518" height="2.518" transform="translate(124.239 71.317)" />
                <rect id="Rectangle_1229" width="2.518" height="2.518" transform="translate(106.627 73.835)" />
                <rect id="Rectangle_1230" width="2.518" height="2.518" transform="translate(104.11 76.35)" />
                <rect id="Rectangle_1231" width="2.518" height="2.518" transform="translate(104.11 78.867)" />
                <rect id="Rectangle_1232" width="2.518" height="2.518" transform="translate(104.11 83.9)" />
                <rect id="Rectangle_1233" width="2.518" height="2.518" transform="translate(104.11 86.414)" />
                <rect id="Rectangle_1234" width="2.518" height="2.518" transform="translate(104.11 91.447)" />
                <rect id="Rectangle_1235" width="2.518" height="2.518" transform="translate(104.11 93.965)" />
                <rect id="Rectangle_1236" width="2.518" height="2.518" transform="translate(101.595 96.479)" />
                <rect id="Rectangle_1237" width="2.518" height="2.518" transform="translate(99.08 98.997)" />
                <rect id="Rectangle_1238" width="2.518" height="2.518" transform="translate(96.562 98.997)" />
                <rect id="Rectangle_1239" width="2.518" height="2.518" transform="translate(94.048 98.997)" />
                <rect id="Rectangle_1240" width="2.518" height="2.518" transform="translate(94.048 104.029)" />
                <rect id="Rectangle_1241" width="2.518" height="2.518" transform="translate(94.048 106.544)" />
                <rect id="Rectangle_1242" width="2.518" height="2.518" transform="translate(94.048 109.062)" />
                <rect id="Rectangle_1243" width="2.518" height="2.518" transform="translate(94.048 111.579)" />
                <rect id="Rectangle_1244" width="2.518" height="2.518" transform="translate(91.53 114.094)" />
                <rect id="Rectangle_1245" width="2.518" height="2.518" transform="translate(94.048 116.612)" />
                <rect id="Rectangle_1246" width="2.518" height="2.518" transform="translate(94.048 119.127)" />
                <rect id="Rectangle_1247" width="2.518" height="2.518" transform="translate(94.048 121.541)" />
                <rect id="Rectangle_1248" width="2.518" height="2.518" transform="translate(91.53 124.059)" />
                <rect id="Rectangle_1249" width="2.518" height="2.518" transform="translate(91.53 126.574)" />
                <rect id="Rectangle_1250" width="2.518" height="2.518" transform="translate(94.048 129.091)" />
                <rect id="Rectangle_1251" width="2.518" height="2.518" transform="translate(94.048 131.606)" />
                <rect id="Rectangle_1252" width="2.518" height="2.518" transform="translate(94.048 134.124)" />
                <rect id="Rectangle_1253" width="2.518" height="2.518" transform="translate(96.562 136.639)" />
                <rect id="Rectangle_1254" width="2.518" height="2.518" transform="translate(99.08 136.639)" />
                <rect id="Rectangle_1255" width="2.518" height="2.518" transform="translate(101.595 139.156)" />
                <rect id="Rectangle_1256" width="2.518" height="2.518" transform="translate(104.112 139.156)" />
                <rect id="Rectangle_1257" width="2.518" height="2.518" transform="translate(106.627 139.156)" />
                <rect id="Rectangle_1258" width="2.518" height="2.518" transform="translate(106.627 144.189)" />
                <rect id="Rectangle_1259" width="2.518" height="2.518" transform="translate(106.627 146.703)" />
                <rect id="Rectangle_1260" width="2.518" height="2.518" transform="translate(106.627 149.221)" />
                <rect id="Rectangle_1261" width="2.518" height="2.518" transform="translate(104.112 151.739)" />
                <rect id="Rectangle_1262" width="2.518" height="2.518" transform="translate(111.66 139.156)" />
                <rect id="Rectangle_1263" width="2.518" height="2.518" transform="translate(109.145 141.671)" />
                <rect id="Rectangle_1264" width="2.518" height="2.518" transform="translate(91.53 101.512)" />
                <rect id="Rectangle_1265" width="2.518" height="2.518" transform="translate(106.627 81.382)" />
                <rect id="Rectangle_1266" width="2.518" height="2.518" transform="translate(109.142 73.835)" />
                <rect id="Rectangle_1267" width="2.518" height="2.518" transform="translate(111.66 73.835)" />
                <rect id="Rectangle_1268" width="2.518" height="2.518" transform="translate(114.174 76.35)" />
                <rect id="Rectangle_1269" width="2.518" height="2.518" transform="translate(139.337 71.317)" />
                <rect id="Rectangle_1270" width="2.518" height="2.518" transform="translate(161.984 66.285)" />
                <rect id="Rectangle_1271" width="2.518" height="2.518" transform="translate(169.534 73.835)" />
                <rect id="Rectangle_1272" width="2.518" height="2.518" transform="translate(167.019 144.232)" />
                <rect id="Rectangle_1273" width="2.518" height="2.518" transform="translate(164.501 141.717)" />
                <rect id="Rectangle_1274" width="2.518" height="2.518" transform="translate(161.984 139.199)" />
                <rect id="Rectangle_1275" width="2.518" height="2.518" transform="translate(159.469 136.684)" />
                <rect id="Rectangle_1276" width="2.518" height="2.518" transform="translate(154.437 63.767)" />
                <rect id="Rectangle_1277" width="2.518" height="2.518" transform="translate(156.951 63.767)" />
                <rect id="Rectangle_1278" width="2.518" height="2.518" transform="translate(101.595 154.254)" />
                <rect id="Rectangle_1279" width="2.518" height="2.518" transform="translate(101.595 156.771)" />
                <rect id="Rectangle_1280" width="2.518" height="2.518" transform="translate(101.595 159.286)" />
                <rect id="Rectangle_1281" width="2.518" height="2.518" transform="translate(111.659 161.783) rotate(90)" />
                <rect id="Rectangle_1282" width="2.518" height="2.518" transform="translate(116.693 159.267) rotate(90)" />
                <rect id="Rectangle_1283" width="2.518" height="2.518" transform="translate(119.209 159.265) rotate(90)" />
                <rect id="Rectangle_1284" width="2.518" height="2.518" transform="translate(121.726 159.266) rotate(90)" />
                <rect id="Rectangle_1285" width="2.581" height="2.518" transform="translate(124.241 161.782) rotate(90)" />
                <rect id="Rectangle_1286" width="2.581" height="2.518" transform="translate(126.758 161.783) rotate(90)" />
                <rect id="Rectangle_1287" width="2.581" height="2.518" transform="translate(129.274 161.782) rotate(90)" />
                <rect id="Rectangle_1288" width="2.518" height="2.518" transform="translate(134.305 164.363) rotate(90)" />
                <rect id="Rectangle_1289" width="2.518" height="2.518" transform="translate(131.79 164.363) rotate(90)" />
                <rect id="Rectangle_1290" width="2.518" height="2.518" transform="translate(114.177 159.265) rotate(90)" />
                <rect id="Rectangle_1291" width="2.518" height="2.518" transform="translate(109.143 161.782) rotate(90)" />
                <rect id="Rectangle_1292" width="2.518" height="2.518" transform="translate(106.627 161.783) rotate(90)" />
            </g>
        </svg>

    );
}

export default GermanyIcon;
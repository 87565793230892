import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TweetGrid from "./TweetGrid/TweetGrid";
import Separator from "../Functionality/Separator";
import { useTrendAndTweetsFetch } from "../../hooks/customHooks";
import DetailPageHeader from "./Header/DetailPageHeader";
import DoughnutChartRow from "../Functionality/DoughnutChart/DoughnutChartRow";
import FilterContainer from "./TweetGrid/FilterContainer";
import NotAvailable from "../Functionality/NotAvailable";
import { THRESHOLD_BOT, THRESHOLD_UNSURE, TWEET_LABELS } from "../../constants";
import CountUpStatistics from "../Homepage/CountUpStatistics/CountUpStatistics";
import { CountUpProps } from "../../types/CountUpProps";

function DetailPage() {
  const { trendName } = useParams();
  const { trend, tweets, loading, error } = useTrendAndTweetsFetch(
    trendName || ""
  );

  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [showOnlyVoted, setShowOnlyVoted] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("none");
  const [scrollable, setScrollable] = useState(true);

  const results = trend
    ? [trend.bot_result, trend.unsure_result, trend.human_result]
    : [0, 0, 0];

  const randomAuthorId = tweets
    ? tweets[Math.floor(Math.random() * tweets.length)].author_id
    : "not-found";

  const trendDetails: CountUpProps["details"] = [
    // check if trend is not undefined and tweet volume is not null
    // tweet_volume is defined as number || null
    trend?.tweet_volume ? trend.tweet_volume : 0,
    trend ? trend.tweet_size : 0,
    trend ? trend.author_size : 0,
  ];

  const sumResults = results.reduce(
    (previousValue: number, currentValue: number) =>
      previousValue + currentValue
  );
  const [botPercentage, unsurePercentage, humanPercentage] =
    sumResults === 0
      ? results.map((_) => 0)
      : results.map((n: number) => Math.round((n / sumResults) * 100));

  const doughnutChartData = [
    {
      percentage: botPercentage,
      label: TWEET_LABELS.bot,
      thresholdMin: THRESHOLD_BOT * 100,
      thresholdMax: 100,
    },
    {
      percentage: unsurePercentage,
      label: TWEET_LABELS.unsure,
      thresholdMin: THRESHOLD_UNSURE * 100,
      thresholdMax: THRESHOLD_BOT * 100,
    },
    {
      percentage: humanPercentage,
      label: TWEET_LABELS.human,
      thresholdMin: 0,
      thresholdMax: THRESHOLD_UNSURE * 100,
    },
  ];

  const getDetailPageContent = () => {
    if (error || !trendName)
      return <NotAvailable notice={"We couldn't find this trend."} />;

    return (
      <div>
        <Separator primaryLabel={"Distribution of Accounts"} />
        <DoughnutChartRow data={doughnutChartData} />
        <Separator primaryLabel={"Trend Statistics"} />
        <CountUpStatistics details={trendDetails} flag="detailpage" />
        <Separator primaryLabel={"Sample Tweets"} />
        <FilterContainer
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          showOnlyVoted={showOnlyVoted}
          setShowOnlyVoted={setShowOnlyVoted}
          sort={sort}
          setSort={setSort}
        />

        <div
          id={"tweet-grid"}
          className={`p-4 no-scrollbar md:p-6 h-[70vh] md:h-[80vh] ${
            scrollable ? "overflow-y-scroll" : "overflow-y-hidden"
          } overflow-x-hidden`}
        >
          <TweetGrid
            trendName={trendName}
            activeFilters={activeFilters}
            showOnlyVoted={showOnlyVoted}
            tweets={tweets}
            sort={sort}
          />
        </div>
      </div>
    );
  };

  // only allow tweet grid to scroll if the user has scrolled so far to 2 thirds of it in the screen
  if (tweets && tweets.length > 0 && window.innerWidth > 768) {
    const tweetGrid = document.getElementById("tweet-grid");
    window.onscroll = () => {
      if (!tweetGrid) return;
      if (
        document.documentElement.scrollTop + window.innerHeight / 3 >=
        tweetGrid.offsetTop
      ) {
        setScrollable(true);
      } else {
        setScrollable(false);
      }
    };
  }

  return (
    <div>
      <DetailPageHeader
        trend={trend}
        loading={loading}
        randomAuthorId={randomAuthorId}
      />
      {getDetailPageContent()}
    </div>
  );
}

export default DetailPage;

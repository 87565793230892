import React, { PropsWithChildren } from "react";
import { routerBasePath } from "../../constants";

function Infobox({ children }: PropsWithChildren<{}>) {
  return (
    <div className="text-center md:text-left flex flex-col md:flex-row py-2 items-center mb-2">
      <img
        className="h-8 mb-4 md:mb-0 md:mr-4"
        alt={"info-icon"}
        src={`${routerBasePath}/img/SAB-Info.svg`}
      />
      <p className="text-base text-blue">{children}</p>
    </div>
  );
}

export default Infobox;

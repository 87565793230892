import React from "react";
import {DoughnutChartProps} from "../../../types/DoughnutChartTypes";


function DoughnutChart({percentage, label, thresholdMin, thresholdMax}: DoughnutChartProps) {
    return (
      <div className="w-full">
        <div className="relative">
          <svg
            viewBox="0 0 36 36"
            className="block my-2.5 max-h-[300px] mx-auto"
          >
            <path
              d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              strokeWidth="0.25"
              className="stroke-blue60"
            />
            <path
              d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              strokeWidth="1"
              strokeDasharray={`${percentage}, 100`}
              className="stroke-blue animate-doughnut-chart"
            />
          </svg>
          <div className="absolute left-0 right-0 top-2/4 translate-y-[-50%]">
            <p className="text-blue text-md sm:text-base md:text-md font-bot mb-2">
              {percentage}%
            </p>
            <p className="text-blue text-md sm:text-base md:text-md">{label}</p>
          </div>
        </div>
        <p className="text-sm">
          Bot Probability: {thresholdMax}-{thresholdMin}%
        </p>
      </div>
    );
}

export default DoughnutChart;
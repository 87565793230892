import React from "react";

function SupervisedLearningDiagram() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 515.51 692.56">
            <g id="Group_941" data-name="Group 941" transform="translate(-1092.777 -242.406)">
                <g id="Group_586" data-name="Group 586" transform="translate(1210.387 -33)">
                    <g id="Rectangle_818" data-name="Rectangle 818" transform="translate(84.824 546)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="143" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="142" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="Training" transform="translate(156.824 587)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-35.891" y="0">Training</tspan>
                    </text>
                </g>
                <g id="Group_589" data-name="Group 589" transform="translate(1210.387 105.457)">
                    <g id="Rectangle_818-2" data-name="Rectangle 818" transform="translate(10.824 546)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="291" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="290" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="Machine_Learning_Model" data-name="Machine Learning Model"
                          transform="translate(156.824 587)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-110.039" y="0">Machine Learning Model</tspan>
                    </text>
                </g>
                <g id="Group_591" data-name="Group 591" transform="translate(1210.676 243.111)">
                    <g id="Rectangle_818-3" data-name="Rectangle 818" transform="translate(74.824 546)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="163" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="162" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="Prediction" transform="translate(156.824 587)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-46.014" y="0">Prediction</tspan>
                    </text>
                </g>
                <g id="Group_592" data-name="Group 592" transform="translate(255.5 127.387)">
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1030 746.422)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"></circle>
                    <circle id="Ellipse_561" data-name="Ellipse 561" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1030 783.182)" fill="#1266f9"></circle>
                    <text id="Human" transform="translate(1066 766.422)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="0" y="0">Human</tspan>
                    </text>
                    <text id="Bot" transform="translate(1066 802.422)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="0" y="0">Bot</tspan>
                    </text>
                </g>
                <g id="Group_584" data-name="Group 584" transform="translate(1024.953 -303.594)">
                    <g id="Rectangle_818-4" data-name="Rectangle 818" transform="translate(67.824 546)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="177" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="176" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="Labeled_Data" data-name="labeled Data" transform="translate(156.824 587)" fill="#1266f9"
                          fontSize="20" fontFamily="General Sans Variable">
                        <tspan x="-53.443" y="0">Labeled Data</tspan>
                    </text>
                </g>
                <g id="Group_585" data-name="Group 585" transform="translate(1387.467 -303.594)">
                    <g id="Rectangle_818-5" data-name="Rectangle 818" transform="translate(91.824 546)" fill="none"
                       stroke="#1266f9" strokeWidth="1">
                        <rect width="129" height="66" rx="19" stroke="none"></rect>
                        <rect x="0.5" y="0.5" width="128" height="65" rx="18.5" fill="none"></rect>
                    </g>
                    <text id="Labels" transform="translate(156.824 587)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="-29.423" y="0">Labels</tspan>
                    </text>
                </g>
                <g id="Group_587" data-name="Group 587" transform="translate(62.777 -217.891)">
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1038.922 542.719)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"></circle>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1105.922 542.719)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"></circle>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1106.068 575.719)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"></circle>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1138.659 575.719)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"></circle>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1172.922 575.719)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"></circle>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1072.922 543.479)" fill="#1266f9"></circle>
                    <circle id="Ellipse_560" data-name="Ellipse 560" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1139.922 543.479)" fill="#1266f9"></circle>
                    <circle id="Ellipse_563" data-name="Ellipse 563" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1038.922 576.479)" fill="#1266f9"></circle>
                    <circle id="Ellipse_562" data-name="Ellipse 562" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1172.922 543.479)" fill="#1266f9"></circle>
                    <circle id="Ellipse_564" data-name="Ellipse 564" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1072.875 576.479)" fill="#1266f9"></circle>
                </g>
                <g id="Group_588" data-name="Group 588" transform="translate(449.291 -421.594)">
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1030 746.422)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"></circle>
                    <circle id="Ellipse_561-2" data-name="Ellipse 561" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1030 783.182)" fill="#1266f9"></circle>
                    <text id="Human-2" data-name="Human" transform="translate(1066 766.422)" fill="#1266f9" fontSize="20"
                          fontFamily="General Sans Variable">
                        <tspan x="0" y="0">Human</tspan>
                    </text>
                    <text id="Bot-2" data-name="Bot" transform="translate(1066 802.422)" fill="#1266f9"
                          fontSize="20" fontFamily="General Sans Variable">
                        <tspan x="0" y="0">Bot</tspan>
                    </text>
                </g>
                <g id="Group_831" data-name="Group 831" transform="translate(-1840.527 -581.529) rotate(-47)">
                    <line id="Line_67" data-name="Line 67" y2="116.074" transform="translate(1368.498 2921.102)"
                          fill="none" stroke="#1266f9" strokeWidth="1"></line>
                    <g id="Group_825" data-name="Group 825" transform="translate(-2 -2)">
                        <line id="Line_119" data-name="Line 119" x1="7" y1="7" transform="translate(1363.5 3032.5)"
                              fill="none" stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                        <line id="Line_120" data-name="Line 120" y1="7" x2="7" transform="translate(1370.5 3032.5)"
                              fill="none" stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                    </g>
                </g>
                <g id="Group_832" data-name="Group 832" transform="translate(1423.922 494.303) rotate(-133)">
                    <line id="Line_67-2" data-name="Line 67" y1="116.074" x2="0.001" transform="translate(6.998 0.323)"
                          fill="none" stroke="#1266f9" strokeWidth="1"></line>
                    <g id="Group_825-2" data-name="Group 825" transform="translate(0)">
                        <line id="Line_119-2" data-name="Line 119" x1="7" y2="7" transform="translate(0 0)" fill="none"
                              stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                        <line id="Line_120-2" data-name="Line 120" x2="7" y2="7" transform="translate(7 0)" fill="none"
                              stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                    </g>
                </g>
                <g id="Group_828" data-name="Group 828" transform="translate(-1.5 -2424.24)">
                    <line id="Line_67-3" data-name="Line 67" y2="52.438" transform="translate(1368.5 3003.24)"
                          fill="none" stroke="#1266f9" strokeWidth="1"></line>
                    <g id="Group_825-3" data-name="Group 825" transform="translate(1361.5 3050)">
                        <line id="Line_119-3" data-name="Line 119" x1="7" y1="7" fill="none" stroke="#0d66fb"
                              strokeLinecap="square" strokeWidth="1"></line>
                        <line id="Line_120-3" data-name="Line 120" y1="7" x2="7" transform="translate(7)" fill="none"
                              stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                    </g>
                </g>
                <g id="Group_829" data-name="Group 829" transform="translate(-1.5 -2285.783)">
                    <line id="Line_67-5" data-name="Line 67" y2="52.635" transform="translate(1368.5 3003.24)"
                          fill="none" stroke="#1266f9" strokeWidth="1"></line>
                    <g id="Group_825-5" data-name="Group 825" transform="translate(1361.5 3049.197)">
                        <line id="Line_119-5" data-name="Line 119" x1="7" y1="7" fill="none" stroke="#0d66fb"
                              strokeLinecap="square" strokeWidth="1"></line>
                        <line id="Line_120-5" data-name="Line 120" y1="7" x2="7" transform="translate(7)" fill="none"
                              stroke="#0d66fb" strokeLinecap="square" strokeWidth="1"></line>
                    </g>
                </g>
            </g>
        </svg>

    );
}

export default SupervisedLearningDiagram;
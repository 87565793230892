import React from "react";
import CountUp from "react-countup";
import { CountUpProps } from "../../../types/CountUpProps";

const descriptionDetailpage = [
  "Tweets for this trend",
  "Tweets analyzed",
  "Accounts analyzed"
]

const descriptionHomepage = [
  "Model accuracy",
  "Total accounts analyzed",
  "Total tweets analyzed"
]

function CountUpStatistics({ details, flag }: CountUpProps) {
  const text =
    flag === "detailpage" ? descriptionDetailpage : descriptionHomepage;
  return (
    <div className={"flex flex-col sm:flex-row justify-evenly bg-blue p-6"}>
      {Object.values(details).map((key, index) => (
        <div className="m-2 p-4 md:m-4 md:p-6 text-white" key={index}>
          <CountUp
            end={key || 0}
            duration={2.5}
            separator="."
            className="inline-block mb-1 md:mb-2 text-lg"
          />
          {text[index] === descriptionHomepage[0] && (
            <span className="text-lg">%</span>
          )}
          <p className="text-base px-8">{text[index]}</p>
        </div>
      ))}
    </div>
  );
}

export default CountUpStatistics;

import React from "react";
import {CircleIconProps} from "../../../types/CircleIconProps";

function CircleIcon({ filled = false, inverted = false}: CircleIconProps) {
    let styles = "fill-white stroke-blue";
    if (inverted && filled) styles = "fill-white stroke-white"
    else if (inverted && !filled) styles = "fill-blue stroke-white"
    else if (!inverted && filled) styles = "fill-blue stroke-blue"

    return (
        <svg className={`${styles} stroke-blue stroke-[4px] w-full`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
            <circle cx="28" cy="28" r="26" />
        </svg>
    );
}

export default CircleIcon;

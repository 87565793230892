import React from "react";
import { StatisticsTableProps } from "../../types/StatisticsTableProps";
import { Statistic } from "../../types/Statistic";

function StatisticsTable({ statistics }: StatisticsTableProps) {
  return (
    <dl className="flex flex-col items-center mb-4 w-full mx-auto">
      {statistics.map((stat: Statistic, index: number) => (
        <div
          key={index}
          className="first:border-t border-b border-black w-full flex flex-col md:flex-row justify-between items:start md:items-center p-4 md:px-6"
        >
          <dt className="text-md text-left">{stat.label}</dt>
          <dd className="text-md md:leading-9 text-blue md:ml-4 text-left md:text-right">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
}

export default StatisticsTable;

import React from "react";
import { Link } from "react-router-dom";
import Separator from "../Functionality/Separator";
import HomePageBannerContainer from "./Banner/HomePageBannerContainer";
import { useFetch } from "../../hooks/customHooks";
import { API_URL } from "../../constants";
import { ModelStatistics } from "../../types/ModelStatistics";
import CountUpStatistics from "../Homepage/CountUpStatistics/CountUpStatistics";
import { CountUpProps } from "../../types/CountUpProps";
import Button from "../Functionality/Button";
import ProductHuntEmbed from "../Functionality/ProductHuntEmbed";

function HomePage() {
  const { data } = useFetch(`${API_URL}/statistics`) as {
    data: ModelStatistics;
  };

  const homepageDetails: CountUpProps["details"] = [
    85.6,
    // fallback for these two are set to the size of the trainset
    data?.author_count ? data.author_count + 29037 : 29037,
    data?.tweet_count ? data.tweet_count + 1189169 : 1189169,
  ];

  const scrollToElement = (element: string) => {
    const el = document.getElementById(element);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="bg-blue text-white pt-20 md:pt-32 pb-8 px-4 md:px-6">
        <h1 className="text-lg md:text-xl text-left max-w-6xl mb-14">
          Spot A Bot estimates how many accounts on Twitter are bots by
          analyzing Twitter trends
        </h1>
        <div className="flex flex-col sm:flex-row sm:items-center uppercase">
          <Button
            onClick={() => scrollToElement("separator-container")}
            inverted={false}
          >
            <span className="uppercase">Check Today's Trends</span>
          </Button>
          <Link
            to={"history"}
            className={"inline-block w-full sm:w-auto mb-4 sm:mb-0"}
            data-cy="link-to-past-trends"
          >
            <Button onClick={() => {}} inverted={true}>
              <span className="uppercase">Look for past trends</span>
            </Button>
          </Link>
          <ProductHuntEmbed />
        </div>
      </div>
      <div id="separator-container">
        <Separator primaryLabel={"Choose a Trend From UK, USA or Germany"} />
      </div>
      <HomePageBannerContainer />
      <Separator primaryLabel={"Statistics"} />
      <CountUpStatistics details={homepageDetails} flag="homepage" />
    </div>
  );
}

export default HomePage;

import React from "react";
import { Link } from "react-router-dom";
import { NavigationOverlayProps } from "../../types/NavigationOverlayProps";
import { routerBasePath } from "../../constants";

function NavigationOverlay({ navItems, visible, toggleOverlay }: NavigationOverlayProps) {
  return (
    <>
      {visible && (
        <div className="flex z-20 flex-col h-screen bg-blue absolute w-full text-white">
          <button
            onClick={toggleOverlay}
            className="absolute right-0 top-0 mt-8 mr-6 text-base"
          >
            Close
          </button>
          <nav className="h-full flex flex-col justify-center items-center">
            <ul>
              {navItems.map(({ to, text }) => (
                <li key={text} className="text-4xl text-center my-6">
                  {
                    <Link onClick={toggleOverlay} to={to}>
                      {text}
                    </Link>
                  }
                </li>
              ))}
              <li
                key="Get in touch"
                className="text-4xl mb-6 mt-20 text-center"
              >
                <a
                  target="_blank"
                  href="https://twitter.com/SpotABot_"
                  className="inline-flex items-center px-2 justify-self-end"
                  rel="noreferrer"
                >
                  <img
                    src={`${routerBasePath}/img/tweet.svg`}
                    className="mr-2 h-8"
                    alt="Twitter logo"
                  ></img>
                  Get in touch
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}

export default NavigationOverlay;

import React from "react";
import Button from "../Functionality/Button";
function AboutPage() {

    return (
      <React.Fragment>
        <div className="bg-blue text-left text-white pt-8 md:pt-32 pb-8 px-4 md:px-6 text-md md:text-lg">
          <p className="mb-8 max-w-screen-lg">
            Due to recent changes in Twitter‘s API
            policy, which introduced monetarization,
            we are not able to provide daily trend
            analyses at this moment.
          </p>
          <p className="text-left mr-16 mb-8 max-w-screen-lg">
            We stay commited to our mission and
            are working on a solution. Follow us on
            Twitter to stay up to date.
          </p>
          <a
              target="_blank"
              href="https://twitter.com/SpotABot_"
              rel="noreferrer"
          >
            <Button onClick={() => null} inverted={true}>@spotabot_</Button>
          </a>
        </div>
      </React.Fragment>
    );
}

export default AboutPage;

import React, { Fragment } from "react";
import { TrendStatistics } from "../../../types/TrendStatistics";
import { prepTrendBannerInput } from "../../Functionality/PrepTrendBannerInput";

type BannerProps = {
  trend: TrendStatistics;
};

function HomepageBanner({ trend }: BannerProps) {
  const bannerElements = prepTrendBannerInput(trend.trend_name, trend.countries, 24);

  return (
    <Fragment>
      <div className="overflow-x-hidden relative border-b border-black text-4xl group">
        <div className="py-4 inline-flex fill-blue group-hover:bg-blue group-hover:text-white group-hover:fill-white animate-marquee-running group-hover:[animation-play-state:paused] whitespace-nowrap">
          {bannerElements.map((bannerContent) => bannerContent)}
        </div>
      </div>
    </Fragment>
  );
}

export default HomepageBanner;

import React from "react";

function ProductHuntEmbed() {
  return (
    <a
      className="ml-auto"
      href="https://www.producthunt.com/posts/spot-a-bot?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-spot&#0045;a&#0045;bot"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=375422&theme=neutral"
        alt="Spot&#0032;a&#0032;Bot - Get&#0032;insights&#0032;into&#0032;the&#0032;bot&#0032;activity&#0032;for&#0032;Twitter&#0032;trends | Product Hunt"
        style={{ width: "250px", height: "54px" }}
        className="inline"
        width="250"
        height="54"
      />
    </a>
  );
}

export default ProductHuntEmbed;

import React from "react";
import {MetricCardProps} from "../../types/MetricCardProps";

function MetricCard({title, text, value}: MetricCardProps){
    return (
      <div className="border border-white rounded-lg p-4 ml-4 mr-4 mt-4 max-w-xs masonry-break-inside masonry-margin-bottom lg:col-span-1">
        <p className="text-white text-md text-left">{title}</p>
        <p className="text-sm text-white text-left mt-2">
            {text}
            <strong>{value}</strong>
            .
        </p>

      </div>
    );
}

export default MetricCard;
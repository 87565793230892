import React from "react";
import CircleIcon from "../Functionality/Icons/CircleIcon";
import CircleUncertainIcon from "../Functionality/Icons/CircleUncertainIcon";

function ClassificationCards(){
    return (
        <div className="ml-2 mr-2">
            <p className="align-left mt-6 mb-2 ml-4 text-white text-sm text-left">Classification:</p>
            <div className="border border-white rounded-lg p-4 ml-4 mr-4 mt-4">
                <p className="text-white text-sm text-left">
                    The algorithm does not directly classify the subjects into human or bot categories. It assigns them a number between 0 and 1, which denotes the probability with which they are bots. This means that a value of 1 shows that the algorithm is very sure that a given subject is a bot, while 0 shows that the algorithm believes that the subject is a human.
                </p>
                <div className="mt-6">
                    <div className="mt-2 flow-root">
                        <div className="float-left flex">
                            <div className="h-6 w-6">
                                <CircleIcon filled={true} inverted={true}/>
                            </div>
                            <span className="text-white ml-2 align-top">Bot</span>
                        </div>
                        <span className="mr-4 text-white float-right">0.65 - 1</span>
                    </div>

                <div className="flex justify-between border-b border-white px-6 py-1"/>

                    <div className="mt-2 flow-root">
                        <div className="float-left flex">
                            <div className="h-6 w-6">
                                <CircleUncertainIcon inverted/>
                            </div>
                            <span className="text-white ml-2 align-top">Unsure</span>
                        </div>
                        <span className="mr-4 text-white float-right">0.5 - 0.65</span>
                    </div>

                <div className="flex justify-between border-b border-white px-6 py-1"/>
                    <div className="mt-2 flow-root">
                        <div className="float-left flex">
                            <div className="h-6 w-6">
                                <CircleIcon inverted={true} />
                            </div>
                            <span className="text-white ml-2 align-top">Human</span>
                        </div>
                        <span className="mr-4 text-white float-right">0 - 0.5</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClassificationCards;
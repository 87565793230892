import React from "react";
import { routerBasePath } from "../../../constants";

type VotingButtonProps = {
  onClick: () => void;
  inverted: boolean;
};

function VotingButton({ onClick, inverted }: VotingButtonProps) {
  let styles;
  let imgSrc;
  if (inverted) {
    styles = "bg-white text-blue";
    imgSrc = `${routerBasePath}/img/eyes-human.svg`;
  } else {
    styles = "text-white font-bot bg-blue";
    imgSrc = `${routerBasePath}/img/eyes-bot-white.svg`;
  }

  return (
    <>
      <button
        className={`w-1/2 text-4xl py-32 ${styles} group`}
        onClick={onClick}
        data-cy={`voting-${inverted ? "human" : "bot"}`}
      >
        <span className="group-hover:hidden">{inverted ? "Human" : "Bot"}</span>
        <img
          src={imgSrc}
          className="h-10 mx-auto group-hover:block hidden"
          alt={inverted ? "human-eyes" : "bot-eyes"}
        ></img>
      </button>
    </>
  );
}

export default VotingButton;

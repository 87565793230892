import React from "react";

function DataSetDiagram() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1104.707 1250.254">
            <g id="Group_942" data-name="Group 942" transform="translate(-816.748 -1180.137)">
                <rect id="Rectangle_1749" data-name="Rectangle 1749" width="886.653" height="92.37" transform="translate(816.748 1499.625)" fill="#0d66fb"/>
                <rect id="Rectangle_1810" data-name="Rectangle 1810" width="216.097" height="92.37" transform="translate(1703.902 1591.383)" fill="#0d66fb"/>
                <g id="Group_773" data-name="Group 773" transform="translate(852.01 165.242)">
                    <line id="Line_62" data-name="Line 62" x2="250.99" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_777" data-name="Group 777" transform="translate(-0.5 298.879)">
                    <line id="Line_62-2" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_778" data-name="Group 778" transform="translate(-0.5 390.635)">
                    <line id="Line_62-3" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_779" data-name="Group 779" transform="translate(-0.5 483.639)">
                    <line id="Line_62-4" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_784" data-name="Group 784" transform="translate(0 616.502)">
                    <line id="Line_62-5" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_923" data-name="Group 923" transform="translate(0 853.037)">
                    <line id="Line_62-6" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_780" data-name="Group 780" transform="translate(0 684.012)">
                    <line id="Line_62-7" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_924" data-name="Group 924" transform="translate(0 920.547)">
                    <line id="Line_62-8" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <g id="Group_787" data-name="Group 787" transform="translate(0 1103.189)">
                    <line id="Line_62-9" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <rect id="Rectangle_1740" data-name="Rectangle 1740" width="852.509" height="92.371" transform="translate(817 1273.371)" fill="#0d66fb"/>
                <rect id="Rectangle_1807" data-name="Rectangle 1807" width="1103" height="92.371" transform="translate(817 1180.137)" fill="#0d66fb"/>
                <text id="Data_set_with_37_500_Accounts" data-name="Data set with ≈ 37,500 Accounts" transform="translate(825 1251)" fill="#fff" fontSize="60" fontFamily="General Sans Variable"><tspan x="0" y="0">Dataset with ≈ 37.500 Accounts</tspan></text>
                <text id="_29_000_Accounts" data-name="29,000 Accounts" transform="translate(825.973 1344.371)" fill="#fff" fontSize="60" fontFamily="General Sans Variable"><tspan x="0" y="0">29.000 Accounts</tspan></text>
                <text id="_80_" data-name="80%" transform="translate(824.5 1576.889)" fill="#fff" fontSize="60" fontFamily="General Sans Variable"><tspan x="0" y="0">80%</tspan></text>
                <text id="_20_" data-name="20%" transform="translate(1725 1669.998)" fill="#fff" fontSize="60" fontFamily="General Sans Variable"><tspan x="0" y="0">20%</tspan></text>
                <text id="The_remaining_20_is_kept_to_test_the_algorithm_later_on." data-name="The remaining 20% is kept to test the algorithm later on." transform="translate(1188.473 1674.348)" fill="#0d66fb" fontSize="20" fontFamily="General Sans Variable"><tspan x="0" y="0">The remaining 20% is kept to test the algorithm later on.</tspan></text>
                <text id="With_this_part_we_let_the_algorithm_learn._" data-name="With this part we let the algorithm learn. " transform="translate(974.5 1573.889)" fill="#fff" fontSize="20" fontFamily="General Sans Variable"><tspan x="0" y="0">With this part we let the algorithm learn. </tspan></text>
                <text id="The_train_dataset_is_classified_into_bot_human._The_algorithm_learns_why_someone_is_a_bot_or_human_based_on_features_whose_importance_it_determines_itself." data-name="The train dataset is classified into bot &amp; human. The algorithm learns why someone is a bot or human based on features whose importance it determines itself." transform="translate(825 1900.883)" fill="#0d66fb" fontSize="15" fontFamily="General Sans Variable"><tspan x="0" y="15">The train dataset is classified into bot &amp; human. The algorithm learns why </tspan><tspan x="0" y="35">someone is a bot or human based on features whose importance it </tspan><tspan x="0" y="55">determines itself.</tspan></text>
                <text id="The_test_dataset_is_then_used_to_check_the_quality_of_the_algorithm._The_algorithm_receives_the_test_data_without_a_label_and_must_determine_what_is_bot_or_human._Then_it_is_checked_whether_the_predicted_values_are_correct_and_whether_the_model_performs_we" data-name="The test dataset is then used to check the quality of the algorithm. The algorithm receives the test data without a label and must determine what is bot or human. Then it is checked whether the predicted values are correct and whether the model performs we" transform="translate(825 2137.418)" fill="#0d66fb" fontSize="15" fontFamily="General Sans Variable"><tspan x="0" y="15">The test dataset is then used to check the quality of the algorithm. The algorithm receives the test data without a label and must </tspan><tspan x="0" y="35">determine what is bot or human. Then it is checked whether the predicted values are correct and whether the model performs well.</tspan></text>
                <text id="We_separate_our_data_into_two_parts:" data-name="We separate our data into two parts:" transform="translate(824.5 1468.889)" fill="#0d66fb" fontSize="20" fontFamily="General Sans Variable"><tspan x="0" y="20">We separate our data into two parts:</tspan></text>
                <g id="Group_761" data-name="Group 761" transform="translate(824.5 2071.064)">
                    <ellipse id="Ellipse_589" data-name="Ellipse 589" cx="16.962" cy="16.962" rx="16.962" ry="16.962" transform="translate(0 0)" fill="#707070"/>
                    <ellipse id="Ellipse_590" data-name="Ellipse 590" cx="16.962" cy="16.962" rx="16.962" ry="16.962" transform="translate(173.816 0)" fill="#707070"/>
                    <ellipse id="Ellipse_587" data-name="Ellipse 587" cx="16.962" cy="16.962" rx="16.962" ry="16.962" transform="translate(87.604 0)" fill="#707070"/>
                    <ellipse id="Ellipse_586" data-name="Ellipse 586" cx="16.962" cy="16.962" rx="16.962" ry="16.962" transform="translate(130.71 0)" fill="#707070"/>
                    <ellipse id="Ellipse_588" data-name="Ellipse 588" cx="16.962" cy="16.962" rx="16.962" ry="16.962" transform="translate(44.498 0)" fill="#707070"/>
                </g>
                <text id="Deleted_accounts_removed" data-name="Deleted accounts removed" transform="translate(1679 1341)" fill="#0d66fb" fontSize="15" fontFamily="General Sans Variable"><tspan x="0" y="15">Deleted accounts removed</tspan></text>
                <path id="Path_254" data-name="Path 254" d="M22604.51,18297.744l249.395,133.7" transform="translate(-20935 -16932)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                <path id="Path_271" data-name="Path 271" d="M23256.7,18297.746l-652.2,170.023" transform="translate(-21552.801 -16413.232)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                <g id="Group_760" data-name="Group 760" transform="translate(825 1833)">
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                             transform="translate(650.509 0)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                             transform="translate(694.707 0)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                             transform="translate(740.285 0)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                             transform="translate(784.48 0)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                            transform="translate(828.676 0)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_563" data-name="Ellipse 563" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(261.032 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_578" data-name="Ellipse 578" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(0 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_572" data-name="Ellipse 572" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(303.849 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_579" data-name="Ellipse 579" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(42.814 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_573" data-name="Ellipse 573" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(346.663 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_580" data-name="Ellipse 580" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(87.011 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_574" data-name="Ellipse 574" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(433.674 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_585" data-name="Ellipse 585" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(520.685 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_581" data-name="Ellipse 581" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(129.825 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_575" data-name="Ellipse 575" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(390.86 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_584" data-name="Ellipse 584" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(563.499 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_582" data-name="Ellipse 582" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(174.021 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_576" data-name="Ellipse 576" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(477.87 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_583" data-name="Ellipse 583" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(607.695 0)" fill="#1266f9"/>
                    <ellipse id="Ellipse_577" data-name="Ellipse 577" cx="16.847" cy="16.848" rx="16.847" ry="16.848" transform="translate(216.836 0)" fill="#1266f9"/>
                </g>
                <text id="The_results_of_this_process_determine_the_accuracy_of_our_model." data-name="The results of this process determine the accuracy of our model." transform="translate(1072.129 2426.391)" fill="#0d66fb" fontSize="15" fontFamily="General Sans Variable"><tspan x="0" y="0">The results of this process determine the accuracy of our model.</tspan></text>
                <text id="TEST_DATASET" data-name="TEST DATASET" transform="translate(825.473 2267.689)" fill="#0d66fb" fontSize="20" fontFamily="General Sans Variable"><tspan x="0" y="20">TEST DATASET, through our Model:</tspan></text>
                <text id="TRAIN_DATASET_LABELED" data-name="TRAIN DATASET, LABELED" transform="translate(825 1780)" fill="#0d66fb" fontSize="20" fontFamily="General Sans Variable"><tspan x="0" y="20">TRAIN DATASET, LABELED</tspan></text>
                <text id="TEST_DATASET_UNLABELED" data-name="TEST DATASET, UNLABELED" transform="translate(825 2016.535)" fill="#0d66fb" fontSize="20" fontFamily="General Sans Variable"><tspan x="0" y="20">TEST DATASET, UNLABELED</tspan></text>
                <g id="Group_838" data-name="Group 838" transform="translate(0 1195.834)">
                    <line id="Line_62-10" data-name="Line 62" x2="1103" transform="translate(817.5 1200.5)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                </g>
                <rect id="Rectangle_1811" data-name="Rectangle 1811" width="869.904" height="92.37" transform="translate(1051.551 2303.965)" fill="#0d66fb"/>
                <text id="XX_Model_Accuracy" data-name="XX% = Model Accuracy" transform="translate(1072.129 2312.061)" fill="#fff" fontSize="60" fontFamily="General Sans Variable"><tspan x="0" y="61">XX% = Model Accuracy</tspan></text>
                <path id="Path_274" data-name="Path 274" d="M20000.951,18939.77v67.51" transform="translate(-18949.246 -16885.232)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                <path id="Path_273" data-name="Path 273" d="M20000.951,18939.77v67.51" transform="translate(-18297.551 -17122.768)" fill="none" stroke="#0d66fb" strokeWidth="1"/>
                <g id="Group_925" data-name="Group 925" transform="translate(-7 -112.068)">
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                             transform="translate(832 2446)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                             transform="translate(875 2446)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="16.847" cy="16.848" rx="16.847" ry="16.848"
                             transform="translate(918 2446)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <ellipse id="Ellipse_593" data-name="Ellipse 593" cx="16.697" cy="16.698" rx="16.697" ry="16.698"
                             transform="translate(962 2445)" stroke="#1266f9" strokeWidth="2.5" fill="#1266f9"/>
                    <ellipse id="Ellipse_559" data-name="Ellipse 559" cx="17" cy="17" rx="16.847" ry="16.848"
                             transform="translate(1004.961 2445)" stroke="#1266f9" strokeWidth="2.5" fill="#fff"/>
                    <g id="Group_839" data-name="Group 839" transform="translate(1004.961 2445)">
                        <rect id="Rectangle_801-7" data-name="Rectangle 801" width="2.46" height="2.46" transform="translate(4.919 4.919)" fill="#0d66fb"/>
                        <rect id="Rectangle_802-7" data-name="Rectangle 802" width="4.919" height="2.46" transform="translate(7.379 2.46)" fill="#0d66fb"/>
                        <rect id="Rectangle_803-7" data-name="Rectangle 803" width="2.46" height="4.919" transform="translate(2.46 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_804-7" data-name="Rectangle 804" width="9.838" height="2.46" transform="translate(12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_805-7" data-name="Rectangle 805" width="2.46" height="9.838" transform="translate(0 12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_806-7" data-name="Rectangle 806" width="2.46" height="2.46" transform="translate(27.055 4.919)" fill="#0d66fb"/>
                        <rect id="Rectangle_807-7" data-name="Rectangle 807" width="4.919" height="2.46" transform="translate(22.136 2.46)" fill="#0d66fb"/>
                        <rect id="Rectangle_808-7" data-name="Rectangle 808" width="2.46" height="4.919" transform="translate(29.514 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_809-7" data-name="Rectangle 809" width="2.46" height="9.838" transform="translate(31.974 12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_810-7" data-name="Rectangle 810" width="2.46" height="2.46" transform="translate(27.055 27.055)" fill="#0d66fb"/>
                        <rect id="Rectangle_860" data-name="Rectangle 860" width="2.46" height="2.46" transform="translate(22.136 27.055)" fill="#0d66fb"/>
                        <rect id="Rectangle_811-7" data-name="Rectangle 811" width="4.919" height="2.46" transform="translate(22.136 29.514)" fill="#0d66fb"/>
                        <rect id="Rectangle_812-7" data-name="Rectangle 812" width="2.46" height="4.919" transform="translate(29.514 22.136)" fill="#0d66fb"/>
                        <rect id="Rectangle_813-7" data-name="Rectangle 813" width="9.838" height="2.46" transform="translate(12.298 31.974)" fill="#0d66fb"/>
                        <rect id="Rectangle_814-7" data-name="Rectangle 814" width="2.46" height="2.46" transform="translate(4.919 27.055)" fill="#0d66fb"/>
                        <rect id="Rectangle_847" data-name="Rectangle 847" width="2.46" height="2.46" transform="translate(7.379 27.055)" fill="#0d66fb"/>
                        <rect id="Rectangle_815-7" data-name="Rectangle 815" width="4.919" height="2.46" transform="translate(7.379 29.514)" fill="#0d66fb"/>
                        <rect id="Rectangle_816-7" data-name="Rectangle 816" width="2.46" height="4.919" transform="translate(2.46 22.136)" fill="#0d66fb"/>
                        <rect id="Rectangle_819" data-name="Rectangle 819" width="2.46" height="2.46" transform="translate(7.379 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_820" data-name="Rectangle 820" width="2.46" height="2.46" transform="translate(9.838 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_821" data-name="Rectangle 821" width="2.46" height="2.46" transform="translate(4.919 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_822" data-name="Rectangle 822" width="2.46" height="2.46" transform="translate(7.379 4.919)" fill="#0d66fb"/>
                        <rect id="Rectangle_823" data-name="Rectangle 823" width="2.46" height="2.46" transform="translate(4.919 12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_824" data-name="Rectangle 824" width="2.46" height="2.46" transform="translate(2.46 14.757)" fill="#0d66fb"/>
                        <rect id="Rectangle_825" data-name="Rectangle 825" width="2.46" height="2.46" transform="translate(7.379 14.757)" fill="#0d66fb"/>
                        <rect id="Rectangle_826" data-name="Rectangle 826" width="2.46" height="2.46" transform="translate(7.379 17.217)" fill="#0d66fb"/>
                        <rect id="Rectangle_827" data-name="Rectangle 827" width="2.46" height="2.46" transform="translate(9.838 17.217)" fill="#0d66fb"/>
                        <rect id="Rectangle_846" data-name="Rectangle 846" width="2.46" height="2.46" transform="translate(9.838 19.676)" fill="#0d66fb"/>
                        <rect id="Rectangle_828" data-name="Rectangle 828" width="2.46" height="2.46" transform="translate(4.919 17.217)" fill="#0d66fb"/>
                        <rect id="Rectangle_829" data-name="Rectangle 829" width="2.46" height="2.46" transform="translate(17.217 14.757)" fill="#0d66fb"/>
                        <rect id="Rectangle_830" data-name="Rectangle 830" width="2.46" height="2.46" transform="translate(19.676 14.757)" fill="#0d66fb"/>
                        <rect id="Rectangle_855" data-name="Rectangle 855" width="2.46" height="2.46" transform="translate(24.595 17.217)" fill="#0d66fb"/>
                        <rect id="Rectangle_862" data-name="Rectangle 862" width="2.46" height="2.46" transform="translate(27.055 17.217)" fill="#0d66fb"/>
                        <rect id="Rectangle_863" data-name="Rectangle 863" width="2.46" height="2.46" transform="translate(29.514 12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_857" data-name="Rectangle 857" width="2.46" height="2.46" transform="translate(27.055 22.136)" fill="#0d66fb"/>
                        <rect id="Rectangle_858" data-name="Rectangle 858" width="2.46" height="2.46" transform="translate(22.136 22.136)" fill="#0d66fb"/>
                        <rect id="Rectangle_831" data-name="Rectangle 831" width="2.46" height="2.46" transform="translate(14.757 14.757)" fill="#0d66fb"/>
                        <rect id="Rectangle_832" data-name="Rectangle 832" width="2.46" height="2.46" transform="translate(12.298 9.838)" fill="#0d66fb"/>
                        <rect id="Rectangle_833" data-name="Rectangle 833" width="2.46" height="2.46" transform="translate(14.757 12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_834" data-name="Rectangle 834" width="2.46" height="2.46" transform="translate(22.136 12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_835" data-name="Rectangle 835" width="2.46" height="2.46" transform="translate(24.595 12.298)" fill="#0d66fb"/>
                        <rect id="Rectangle_836" data-name="Rectangle 836" width="2.46" height="2.46" transform="translate(27.055 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_838" data-name="Rectangle 838" width="2.46" height="2.46" transform="translate(22.136 4.919)" fill="#0d66fb"/>
                        <rect id="Rectangle_837" data-name="Rectangle 837" width="2.46" height="2.46" transform="translate(27.055 9.838)" fill="#0d66fb"/>
                        <rect id="Rectangle_839" data-name="Rectangle 839" width="2.46" height="2.46" transform="translate(22.136 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_840" data-name="Rectangle 840" width="2.46" height="2.46" transform="translate(14.757 2.46)" fill="#0d66fb"/>
                        <rect id="Rectangle_841" data-name="Rectangle 841" width="2.46" height="2.46" transform="translate(17.217 2.46)" fill="#0d66fb"/>
                        <rect id="Rectangle_842" data-name="Rectangle 842" width="2.46" height="2.46" transform="translate(19.676 4.919)" fill="#0d66fb"/>
                        <rect id="Rectangle_843" data-name="Rectangle 843" width="2.46" height="2.46" transform="translate(17.217 7.379)" fill="#0d66fb"/>
                        <rect id="Rectangle_844" data-name="Rectangle 844" width="2.46" height="2.46" transform="translate(7.379 22.136)" fill="#0d66fb"/>
                        <rect id="Rectangle_845" data-name="Rectangle 845" width="2.46" height="2.46" transform="translate(4.919 24.595)" fill="#0d66fb"/>
                        <rect id="Rectangle_848" data-name="Rectangle 848" width="2.46" height="2.46" transform="translate(12.298 24.595)" fill="#0d66fb"/>
                        <rect id="Rectangle_849" data-name="Rectangle 849" width="2.46" height="2.46" transform="translate(17.217 24.595)" fill="#0d66fb"/>
                        <rect id="Rectangle_850" data-name="Rectangle 850" width="2.46" height="2.46" transform="translate(17.217 27.055)" fill="#0d66fb"/>
                        <rect id="Rectangle_861" data-name="Rectangle 861" width="2.46" height="2.46" transform="translate(14.757 29.514)" fill="#0d66fb"/>
                        <rect id="Rectangle_851" data-name="Rectangle 851" width="2.46" height="2.46" transform="translate(17.217 22.136)" fill="#0d66fb"/>
                        <rect id="Rectangle_852" data-name="Rectangle 852" width="2.46" height="2.46" transform="translate(14.757 19.676)" fill="#0d66fb"/>
                        <rect id="Rectangle_853" data-name="Rectangle 853" width="2.46" height="2.46" transform="translate(19.676 17.217)" fill="#0d66fb"/>
                        <rect id="Rectangle_854" data-name="Rectangle 854" width="2.46" height="2.46" transform="translate(24.595 19.676)" fill="#0d66fb"/>
                        <rect id="Rectangle_856" data-name="Rectangle 856" width="2.46" height="2.46" transform="translate(27.055 24.595)" fill="#0d66fb"/>
                        <rect id="Rectangle_859" data-name="Rectangle 859" width="2.46" height="2.46" transform="translate(19.676 24.595)" fill="#0d66fb"/>
                    </g>
                </g>
            </g>
        </svg>

    );
}

export default DataSetDiagram;
import React from "react";
import { SeparatorProps } from "../../types/SeparatorProps";

function Separator({ primaryLabel, children }: SeparatorProps) {
  return (
    <div className="flex justify-between border-b border-black px-4 md:px-6 py-1 mt-12 md:mt-16">
      <span className="text-base text-left">{primaryLabel}</span>
      {children}
    </div>
  );
}

export default Separator;

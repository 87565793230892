import React from "react";

function LearningDiagram() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="75%" height="100%" viewBox="0 0 580.246 566.133">
            <g id="Group_944" data-name="Group 944" transform="translate(-1054.207 -3801.426)">
                <g id="Group_616" data-name="Group 616" transform="translate(851.398 3509.48)">
                    <g id="Rectangle_818" data-name="Rectangle 818" transform="translate(285.824 546)" fill="none" stroke="#1266f9" strokeWidth="1">
                        <rect width="103" height="66" rx="19" stroke="none"/>
                        <rect x="0.5" y="0.5" width="102" height="65" rx="18.5" fill="none"/>
                    </g>
                    <text id="Yes" transform="translate(337.824 587)" fill="#1266f9" fontSize="20" fontFamily="General Sans Variable"><tspan x="-15.981" y="0">Yes</tspan></text>
                </g>
                <g id="Group_617" data-name="Group 617" transform="translate(1220.551 3513.402)">
                    <g id="Rectangle_818-2" data-name="Rectangle 818" transform="translate(288.824 546)" fill="none" stroke="#1266f9" strokeWidth="1">
                        <rect width="97" height="66" rx="19" stroke="none"/>
                        <rect x="0.5" y="0.5" width="96" height="65" rx="18.5" fill="none"/>
                    </g>
                    <text id="No" transform="translate(337.824 587)" fill="#1266f9" fontSize="20" fontFamily="General Sans Variable"><tspan x="-13.056" y="0">No</tspan></text>
                </g>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1287.922 3851.059)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1354.922 3851.059)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198" transform="translate(1321.922 3851.059)" fill="#1266f9"/>
                <circle id="Ellipse_562" data-name="Ellipse 562" cx="12.198" cy="12.198" r="12.198" transform="translate(1421.922 3851.059)" fill="#1266f9"/>
                <g id="Group_802" data-name="Group 802" transform="translate(0 -42.941)">
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1355.068 3926)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1387.66 3926)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1421.922 3926)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                    <circle id="Ellipse_563" data-name="Ellipse 563" cx="12.198" cy="12.198" r="12.198" transform="translate(1287.922 3926)" fill="#1266f9"/>
                    <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                            transform="translate(1322.473 3926)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                    <g id="Group_800" data-name="Group 800" transform="translate(1322.473 3926)">
                        <rect id="Rectangle_801-6" data-name="Rectangle 801" width="1.797" height="1.797" transform="translate(3.594 3.594)" fill="#0d66fb"/>
                        <rect id="Rectangle_802-6" data-name="Rectangle 802" width="3.594" height="1.797" transform="translate(5.391 1.797)" fill="#0d66fb"/>
                        <rect id="Rectangle_803-6" data-name="Rectangle 803" width="1.797" height="3.594" transform="translate(1.797 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_804-6" data-name="Rectangle 804" width="7.189" height="1.797" transform="translate(8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_805-6" data-name="Rectangle 805" width="1.797" height="7.189" transform="translate(0 8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_806-6" data-name="Rectangle 806" width="1.797" height="1.797" transform="translate(19.769 3.594)" fill="#0d66fb"/>
                        <rect id="Rectangle_807-6" data-name="Rectangle 807" width="3.594" height="1.797" transform="translate(16.174 1.797)" fill="#0d66fb"/>
                        <rect id="Rectangle_808-6" data-name="Rectangle 808" width="1.797" height="3.594" transform="translate(21.566 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_809-6" data-name="Rectangle 809" width="1.797" height="7.189" transform="translate(23.363 8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_810-6" data-name="Rectangle 810" width="1.797" height="1.797" transform="translate(19.769 19.769)" fill="#0d66fb"/>
                        <rect id="Rectangle_860" data-name="Rectangle 860" width="1.797" height="1.797" transform="translate(16.174 19.769)" fill="#0d66fb"/>
                        <rect id="Rectangle_811-6" data-name="Rectangle 811" width="3.594" height="1.797" transform="translate(16.174 21.566)" fill="#0d66fb"/>
                        <rect id="Rectangle_812-6" data-name="Rectangle 812" width="1.797" height="3.594" transform="translate(21.566 16.174)" fill="#0d66fb"/>
                        <rect id="Rectangle_813-6" data-name="Rectangle 813" width="7.189" height="1.797" transform="translate(8.986 23.363)" fill="#0d66fb"/>
                        <rect id="Rectangle_814-6" data-name="Rectangle 814" width="1.797" height="1.797" transform="translate(3.594 19.769)" fill="#0d66fb"/>
                        <rect id="Rectangle_847" data-name="Rectangle 847" width="1.797" height="1.797" transform="translate(5.391 19.769)" fill="#0d66fb"/>
                        <rect id="Rectangle_815-6" data-name="Rectangle 815" width="3.594" height="1.797" transform="translate(5.391 21.566)" fill="#0d66fb"/>
                        <rect id="Rectangle_816-6" data-name="Rectangle 816" width="1.797" height="3.594" transform="translate(1.797 16.174)" fill="#0d66fb"/>
                        <rect id="Rectangle_819" data-name="Rectangle 819" width="1.797" height="1.797" transform="translate(5.391 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_820" data-name="Rectangle 820" width="1.797" height="1.797" transform="translate(7.189 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_821" data-name="Rectangle 821" width="1.797" height="1.797" transform="translate(3.594 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_822" data-name="Rectangle 822" width="1.797" height="1.797" transform="translate(5.391 3.594)" fill="#0d66fb"/>
                        <rect id="Rectangle_823" data-name="Rectangle 823" width="1.797" height="1.797" transform="translate(3.594 8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_824" data-name="Rectangle 824" width="1.797" height="1.797" transform="translate(1.797 10.783)" fill="#0d66fb"/>
                        <rect id="Rectangle_825" data-name="Rectangle 825" width="1.797" height="1.797" transform="translate(5.391 10.783)" fill="#0d66fb"/>
                        <rect id="Rectangle_826" data-name="Rectangle 826" width="1.797" height="1.797" transform="translate(5.391 12.58)" fill="#0d66fb"/>
                        <rect id="Rectangle_827" data-name="Rectangle 827" width="1.797" height="1.797" transform="translate(7.189 12.58)" fill="#0d66fb"/>
                        <rect id="Rectangle_846" data-name="Rectangle 846" width="1.797" height="1.797" transform="translate(7.189 14.377)" fill="#0d66fb"/>
                        <rect id="Rectangle_828" data-name="Rectangle 828" width="1.797" height="1.797" transform="translate(3.594 12.58)" fill="#0d66fb"/>
                        <rect id="Rectangle_829" data-name="Rectangle 829" width="1.797" height="1.797" transform="translate(12.58 10.783)" fill="#0d66fb"/>
                        <rect id="Rectangle_830" data-name="Rectangle 830" width="1.797" height="1.797" transform="translate(14.377 10.783)" fill="#0d66fb"/>
                        <rect id="Rectangle_855" data-name="Rectangle 855" width="1.797" height="1.797" transform="translate(17.971 12.58)" fill="#0d66fb"/>
                        <rect id="Rectangle_862" data-name="Rectangle 862" width="1.797" height="1.797" transform="translate(19.769 12.58)" fill="#0d66fb"/>
                        <rect id="Rectangle_863" data-name="Rectangle 863" width="1.797" height="1.797" transform="translate(21.566 8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_857" data-name="Rectangle 857" width="1.797" height="1.797" transform="translate(19.769 16.174)" fill="#0d66fb"/>
                        <rect id="Rectangle_858" data-name="Rectangle 858" width="1.797" height="1.797" transform="translate(16.174 16.174)" fill="#0d66fb"/>
                        <rect id="Rectangle_831" data-name="Rectangle 831" width="1.797" height="1.797" transform="translate(10.783 10.783)" fill="#0d66fb"/>
                        <rect id="Rectangle_832" data-name="Rectangle 832" width="1.797" height="1.797" transform="translate(8.986 7.189)" fill="#0d66fb"/>
                        <rect id="Rectangle_833" data-name="Rectangle 833" width="1.797" height="1.797" transform="translate(10.783 8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_834" data-name="Rectangle 834" width="1.797" height="1.797" transform="translate(16.174 8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_835" data-name="Rectangle 835" width="1.797" height="1.797" transform="translate(17.971 8.986)" fill="#0d66fb"/>
                        <rect id="Rectangle_836" data-name="Rectangle 836" width="1.797" height="1.797" transform="translate(19.769 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_838" data-name="Rectangle 838" width="1.797" height="1.797" transform="translate(16.174 3.594)" fill="#0d66fb"/>
                        <rect id="Rectangle_837" data-name="Rectangle 837" width="1.797" height="1.797" transform="translate(19.769 7.189)" fill="#0d66fb"/>
                        <rect id="Rectangle_839" data-name="Rectangle 839" width="1.797" height="1.797" transform="translate(16.174 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_840" data-name="Rectangle 840" width="1.797" height="1.797" transform="translate(10.783 1.797)" fill="#0d66fb"/>
                        <rect id="Rectangle_841" data-name="Rectangle 841" width="1.797" height="1.797" transform="translate(12.58 1.797)" fill="#0d66fb"/>
                        <rect id="Rectangle_842" data-name="Rectangle 842" width="1.797" height="1.797" transform="translate(14.377 3.594)" fill="#0d66fb"/>
                        <rect id="Rectangle_843" data-name="Rectangle 843" width="1.797" height="1.797" transform="translate(12.58 5.391)" fill="#0d66fb"/>
                        <rect id="Rectangle_844" data-name="Rectangle 844" width="1.797" height="1.797" transform="translate(5.391 16.174)" fill="#0d66fb"/>
                        <rect id="Rectangle_845" data-name="Rectangle 845" width="1.797" height="1.797" transform="translate(3.594 17.971)" fill="#0d66fb"/>
                        <rect id="Rectangle_848" data-name="Rectangle 848" width="1.797" height="1.797" transform="translate(8.986 17.971)" fill="#0d66fb"/>
                        <rect id="Rectangle_849" data-name="Rectangle 849" width="1.797" height="1.797" transform="translate(12.58 17.971)" fill="#0d66fb"/>
                        <rect id="Rectangle_850" data-name="Rectangle 850" width="1.797" height="1.797" transform="translate(12.58 19.769)" fill="#0d66fb"/>
                        <rect id="Rectangle_861" data-name="Rectangle 861" width="1.797" height="1.797" transform="translate(10.783 21.566)" fill="#0d66fb"/>
                        <rect id="Rectangle_851" data-name="Rectangle 851" width="1.797" height="1.797" transform="translate(12.58 16.174)" fill="#0d66fb"/>
                        <rect id="Rectangle_852" data-name="Rectangle 852" width="1.797" height="1.797" transform="translate(10.783 14.377)" fill="#0d66fb"/>
                        <rect id="Rectangle_853" data-name="Rectangle 853" width="1.797" height="1.797" transform="translate(14.377 12.58)" fill="#0d66fb"/>
                        <rect id="Rectangle_854" data-name="Rectangle 854" width="1.797" height="1.797" transform="translate(17.971 14.377)" fill="#0d66fb"/>
                        <rect id="Rectangle_856" data-name="Rectangle 856" width="1.797" height="1.797" transform="translate(19.769 17.971)" fill="#0d66fb"/>
                        <rect id="Rectangle_859" data-name="Rectangle 859" width="1.797" height="1.797" transform="translate(14.377 17.971)" fill="#0d66fb"/>
                    </g>
                </g>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1388.473 3851.059)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <g id="Group_801" data-name="Group 801" transform="translate(1388.473 3851.059)">
                    <rect id="Rectangle_801-7" data-name="Rectangle 801" width="1.797" height="1.797" transform="translate(3.594 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_802-7" data-name="Rectangle 802" width="3.594" height="1.797" transform="translate(5.391 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_803-7" data-name="Rectangle 803" width="1.797" height="3.594" transform="translate(1.797 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_804-7" data-name="Rectangle 804" width="7.189" height="1.797" transform="translate(8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_805-7" data-name="Rectangle 805" width="1.797" height="7.189" transform="translate(0 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_806-7" data-name="Rectangle 806" width="1.797" height="1.797" transform="translate(19.769 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_807-7" data-name="Rectangle 807" width="3.594" height="1.797" transform="translate(16.174 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_808-7" data-name="Rectangle 808" width="1.797" height="3.594" transform="translate(21.566 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_809-7" data-name="Rectangle 809" width="1.797" height="7.189" transform="translate(23.363 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_810-7" data-name="Rectangle 810" width="1.797" height="1.797" transform="translate(19.769 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_860-2" data-name="Rectangle 860" width="1.797" height="1.797" transform="translate(16.174 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_811-7" data-name="Rectangle 811" width="3.594" height="1.797" transform="translate(16.174 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_812-7" data-name="Rectangle 812" width="1.797" height="3.594" transform="translate(21.566 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_813-7" data-name="Rectangle 813" width="7.189" height="1.797" transform="translate(8.986 23.363)" fill="#0d66fb"/>
                    <rect id="Rectangle_814-7" data-name="Rectangle 814" width="1.797" height="1.797" transform="translate(3.594 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_847-2" data-name="Rectangle 847" width="1.797" height="1.797" transform="translate(5.391 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_815-7" data-name="Rectangle 815" width="3.594" height="1.797" transform="translate(5.391 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_816-7" data-name="Rectangle 816" width="1.797" height="3.594" transform="translate(1.797 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_819-2" data-name="Rectangle 819" width="1.797" height="1.797" transform="translate(5.391 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_820-2" data-name="Rectangle 820" width="1.797" height="1.797" transform="translate(7.189 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_821-2" data-name="Rectangle 821" width="1.797" height="1.797" transform="translate(3.594 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_822-2" data-name="Rectangle 822" width="1.797" height="1.797" transform="translate(5.391 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_823-2" data-name="Rectangle 823" width="1.797" height="1.797" transform="translate(3.594 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_824-2" data-name="Rectangle 824" width="1.797" height="1.797" transform="translate(1.797 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_825-2" data-name="Rectangle 825" width="1.797" height="1.797" transform="translate(5.391 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_826-2" data-name="Rectangle 826" width="1.797" height="1.797" transform="translate(5.391 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_827-2" data-name="Rectangle 827" width="1.797" height="1.797" transform="translate(7.189 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_846-2" data-name="Rectangle 846" width="1.797" height="1.797" transform="translate(7.189 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_828-2" data-name="Rectangle 828" width="1.797" height="1.797" transform="translate(3.594 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_829-2" data-name="Rectangle 829" width="1.797" height="1.797" transform="translate(12.58 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_830-2" data-name="Rectangle 830" width="1.797" height="1.797" transform="translate(14.377 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_855-2" data-name="Rectangle 855" width="1.797" height="1.797" transform="translate(17.971 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_862-2" data-name="Rectangle 862" width="1.797" height="1.797" transform="translate(19.769 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_863-2" data-name="Rectangle 863" width="1.797" height="1.797" transform="translate(21.566 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_857-2" data-name="Rectangle 857" width="1.797" height="1.797" transform="translate(19.769 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_858-2" data-name="Rectangle 858" width="1.797" height="1.797" transform="translate(16.174 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_831-2" data-name="Rectangle 831" width="1.797" height="1.797" transform="translate(10.783 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_832-2" data-name="Rectangle 832" width="1.797" height="1.797" transform="translate(8.986 7.189)" fill="#0d66fb"/>
                    <rect id="Rectangle_833-2" data-name="Rectangle 833" width="1.797" height="1.797" transform="translate(10.783 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_834-2" data-name="Rectangle 834" width="1.797" height="1.797" transform="translate(16.174 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_835-2" data-name="Rectangle 835" width="1.797" height="1.797" transform="translate(17.971 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_836-2" data-name="Rectangle 836" width="1.797" height="1.797" transform="translate(19.769 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_838-2" data-name="Rectangle 838" width="1.797" height="1.797" transform="translate(16.174 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_837-2" data-name="Rectangle 837" width="1.797" height="1.797" transform="translate(19.769 7.189)" fill="#0d66fb"/>
                    <rect id="Rectangle_839-2" data-name="Rectangle 839" width="1.797" height="1.797" transform="translate(16.174 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_840-2" data-name="Rectangle 840" width="1.797" height="1.797" transform="translate(10.783 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_841-2" data-name="Rectangle 841" width="1.797" height="1.797" transform="translate(12.58 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_842-2" data-name="Rectangle 842" width="1.797" height="1.797" transform="translate(14.377 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_843-2" data-name="Rectangle 843" width="1.797" height="1.797" transform="translate(12.58 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_844-2" data-name="Rectangle 844" width="1.797" height="1.797" transform="translate(5.391 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_845-2" data-name="Rectangle 845" width="1.797" height="1.797" transform="translate(3.594 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_848-2" data-name="Rectangle 848" width="1.797" height="1.797" transform="translate(8.986 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_849-2" data-name="Rectangle 849" width="1.797" height="1.797" transform="translate(12.58 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_850-2" data-name="Rectangle 850" width="1.797" height="1.797" transform="translate(12.58 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_861-2" data-name="Rectangle 861" width="1.797" height="1.797" transform="translate(10.783 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_851-2" data-name="Rectangle 851" width="1.797" height="1.797" transform="translate(12.58 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_852-2" data-name="Rectangle 852" width="1.797" height="1.797" transform="translate(10.783 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_853-2" data-name="Rectangle 853" width="1.797" height="1.797" transform="translate(14.377 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_854-2" data-name="Rectangle 854" width="1.797" height="1.797" transform="translate(17.971 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_856-2" data-name="Rectangle 856" width="1.797" height="1.797" transform="translate(19.769 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_859-2" data-name="Rectangle 859" width="1.797" height="1.797" transform="translate(14.377 17.971)" fill="#0d66fb"/>
                </g>
                <text id="Is_the_circle_more_than_40_blue_" data-name="Is the circle more than 40% blue?" transform="translate(1367.5 3821.426)" fill="#1266f9" fontSize="20" fontFamily="General Sans Variable"><tspan x="-149.492" y="0">Is the circle more than 40% blue?</tspan></text>
                <text id="Is_it_pixelated_" data-name="Is it pixelated?" transform="translate(1188.723 4260.902)" fill="#1266f9" fontSize="20" fontFamily="General Sans Variable"><tspan x="-63.25" y="0">Is it pixelated?</tspan></text>
                <line id="Line_71" data-name="Line 71" x1="75" transform="translate(1557.875 4155.902) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <line id="Line_72" data-name="Line 72" x1="75" transform="translate(1188.723 4155.902) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1481.297 4250.402)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1513.297 4250.402)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1545.297 4250.402)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1577.297 4250.402)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1609.297 4250.402)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <line id="Line_73" data-name="Line 73" x2="37.303" y2="66.353" transform="translate(1100.406 4324.684) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <line id="Line_76" data-name="Line 76" x2="83.742" y2="145.202" transform="translate(1189.941 4031.762) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <line id="Line_74" data-name="Line 74" y1="66.353" x2="37.303" transform="translate(1200.369 4324.684) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <line id="Line_75" data-name="Line 75" y1="145.202" x2="83.742" transform="translate(1408.695 4031.762) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <circle id="Ellipse_565" data-name="Ellipse 565" cx="12.198" cy="12.198" r="12.198" transform="translate(1122.207 4343.162)" fill="#1266f9"/>
                <circle id="Ellipse_567" data-name="Ellipse 567" cx="12.198" cy="12.198" r="12.198" transform="translate(1054.207 4343.162)" fill="#1266f9"/>
                <circle id="Ellipse_568" data-name="Ellipse 568" cx="12.198" cy="12.198" r="12.198" transform="translate(1088.207 4343.162)" fill="#1266f9"/>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1241.525 4342.398)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <g id="Group_803" data-name="Group 803" transform="translate(1241.525 4342.398)">
                    <rect id="Rectangle_801-13" data-name="Rectangle 801" width="1.797" height="1.797" transform="translate(3.594 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_802-13" data-name="Rectangle 802" width="3.594" height="1.797" transform="translate(5.391 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_803-13" data-name="Rectangle 803" width="1.797" height="3.594" transform="translate(1.797 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_804-13" data-name="Rectangle 804" width="7.189" height="1.797" transform="translate(8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_805-13" data-name="Rectangle 805" width="1.797" height="7.189" transform="translate(0 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_806-13" data-name="Rectangle 806" width="1.797" height="1.797" transform="translate(19.769 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_807-13" data-name="Rectangle 807" width="3.594" height="1.797" transform="translate(16.174 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_808-13" data-name="Rectangle 808" width="1.797" height="3.594" transform="translate(21.566 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_809-13" data-name="Rectangle 809" width="1.797" height="7.189" transform="translate(23.363 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_810-13" data-name="Rectangle 810" width="1.797" height="1.797" transform="translate(19.769 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_860-3" data-name="Rectangle 860" width="1.797" height="1.797" transform="translate(16.174 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_811-13" data-name="Rectangle 811" width="3.594" height="1.797" transform="translate(16.174 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_812-13" data-name="Rectangle 812" width="1.797" height="3.594" transform="translate(21.566 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_813-13" data-name="Rectangle 813" width="7.189" height="1.797" transform="translate(8.986 23.363)" fill="#0d66fb"/>
                    <rect id="Rectangle_814-13" data-name="Rectangle 814" width="1.797" height="1.797" transform="translate(3.594 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_847-3" data-name="Rectangle 847" width="1.797" height="1.797" transform="translate(5.391 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_815-13" data-name="Rectangle 815" width="3.594" height="1.797" transform="translate(5.391 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_816-13" data-name="Rectangle 816" width="1.797" height="3.594" transform="translate(1.797 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_819-3" data-name="Rectangle 819" width="1.797" height="1.797" transform="translate(5.391 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_820-3" data-name="Rectangle 820" width="1.797" height="1.797" transform="translate(7.189 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_821-3" data-name="Rectangle 821" width="1.797" height="1.797" transform="translate(3.594 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_822-3" data-name="Rectangle 822" width="1.797" height="1.797" transform="translate(5.391 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_823-3" data-name="Rectangle 823" width="1.797" height="1.797" transform="translate(3.594 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_824-3" data-name="Rectangle 824" width="1.797" height="1.797" transform="translate(1.797 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_825-3" data-name="Rectangle 825" width="1.797" height="1.797" transform="translate(5.391 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_826-3" data-name="Rectangle 826" width="1.797" height="1.797" transform="translate(5.391 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_827-3" data-name="Rectangle 827" width="1.797" height="1.797" transform="translate(7.189 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_846-3" data-name="Rectangle 846" width="1.797" height="1.797" transform="translate(7.189 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_828-3" data-name="Rectangle 828" width="1.797" height="1.797" transform="translate(3.594 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_829-3" data-name="Rectangle 829" width="1.797" height="1.797" transform="translate(12.58 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_830-3" data-name="Rectangle 830" width="1.797" height="1.797" transform="translate(14.377 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_855-3" data-name="Rectangle 855" width="1.797" height="1.797" transform="translate(17.971 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_862-3" data-name="Rectangle 862" width="1.797" height="1.797" transform="translate(19.769 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_863-3" data-name="Rectangle 863" width="1.797" height="1.797" transform="translate(21.566 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_857-3" data-name="Rectangle 857" width="1.797" height="1.797" transform="translate(19.769 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_858-3" data-name="Rectangle 858" width="1.797" height="1.797" transform="translate(16.174 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_831-3" data-name="Rectangle 831" width="1.797" height="1.797" transform="translate(10.783 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_832-3" data-name="Rectangle 832" width="1.797" height="1.797" transform="translate(8.986 7.189)" fill="#0d66fb"/>
                    <rect id="Rectangle_833-3" data-name="Rectangle 833" width="1.797" height="1.797" transform="translate(10.783 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_834-3" data-name="Rectangle 834" width="1.797" height="1.797" transform="translate(16.174 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_835-3" data-name="Rectangle 835" width="1.797" height="1.797" transform="translate(17.971 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_836-3" data-name="Rectangle 836" width="1.797" height="1.797" transform="translate(19.769 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_838-3" data-name="Rectangle 838" width="1.797" height="1.797" transform="translate(16.174 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_837-3" data-name="Rectangle 837" width="1.797" height="1.797" transform="translate(19.769 7.189)" fill="#0d66fb"/>
                    <rect id="Rectangle_839-3" data-name="Rectangle 839" width="1.797" height="1.797" transform="translate(16.174 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_840-3" data-name="Rectangle 840" width="1.797" height="1.797" transform="translate(10.783 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_841-3" data-name="Rectangle 841" width="1.797" height="1.797" transform="translate(12.58 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_842-3" data-name="Rectangle 842" width="1.797" height="1.797" transform="translate(14.377 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_843-3" data-name="Rectangle 843" width="1.797" height="1.797" transform="translate(12.58 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_844-3" data-name="Rectangle 844" width="1.797" height="1.797" transform="translate(5.391 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_845-3" data-name="Rectangle 845" width="1.797" height="1.797" transform="translate(3.594 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_848-3" data-name="Rectangle 848" width="1.797" height="1.797" transform="translate(8.986 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_849-3" data-name="Rectangle 849" width="1.797" height="1.797" transform="translate(12.58 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_850-3" data-name="Rectangle 850" width="1.797" height="1.797" transform="translate(12.58 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_861-3" data-name="Rectangle 861" width="1.797" height="1.797" transform="translate(10.783 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_851-3" data-name="Rectangle 851" width="1.797" height="1.797" transform="translate(12.58 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_852-3" data-name="Rectangle 852" width="1.797" height="1.797" transform="translate(10.783 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_853-3" data-name="Rectangle 853" width="1.797" height="1.797" transform="translate(14.377 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_854-3" data-name="Rectangle 854" width="1.797" height="1.797" transform="translate(17.971 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_856-3" data-name="Rectangle 856" width="1.797" height="1.797" transform="translate(19.769 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_859-3" data-name="Rectangle 859" width="1.797" height="1.797" transform="translate(14.377 17.971)" fill="#0d66fb"/>
                </g>
                <circle id="Ellipse_559" data-name="Ellipse 559" cx="12.198" cy="12.198" r="12.198"
                        transform="translate(1275.525 4342.398)" stroke="#1266f9" strokeWidth="1.5" fill="#fff"/>
                <g id="Group_804" data-name="Group 804" transform="translate(1275.525 4342.398)">
                    <rect id="Rectangle_801-14" data-name="Rectangle 801" width="1.797" height="1.797" transform="translate(3.594 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_802-14" data-name="Rectangle 802" width="3.594" height="1.797" transform="translate(5.391 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_803-14" data-name="Rectangle 803" width="1.797" height="3.594" transform="translate(1.797 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_804-14" data-name="Rectangle 804" width="7.189" height="1.797" transform="translate(8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_805-14" data-name="Rectangle 805" width="1.797" height="7.189" transform="translate(0 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_806-14" data-name="Rectangle 806" width="1.797" height="1.797" transform="translate(19.769 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_807-14" data-name="Rectangle 807" width="3.594" height="1.797" transform="translate(16.174 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_808-14" data-name="Rectangle 808" width="1.797" height="3.594" transform="translate(21.566 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_809-14" data-name="Rectangle 809" width="1.797" height="7.189" transform="translate(23.363 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_810-14" data-name="Rectangle 810" width="1.797" height="1.797" transform="translate(19.769 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_860-4" data-name="Rectangle 860" width="1.797" height="1.797" transform="translate(16.174 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_811-14" data-name="Rectangle 811" width="3.594" height="1.797" transform="translate(16.174 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_812-14" data-name="Rectangle 812" width="1.797" height="3.594" transform="translate(21.566 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_813-14" data-name="Rectangle 813" width="7.189" height="1.797" transform="translate(8.986 23.363)" fill="#0d66fb"/>
                    <rect id="Rectangle_814-14" data-name="Rectangle 814" width="1.797" height="1.797" transform="translate(3.594 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_847-4" data-name="Rectangle 847" width="1.797" height="1.797" transform="translate(5.391 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_815-14" data-name="Rectangle 815" width="3.594" height="1.797" transform="translate(5.391 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_816-14" data-name="Rectangle 816" width="1.797" height="3.594" transform="translate(1.797 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_819-4" data-name="Rectangle 819" width="1.797" height="1.797" transform="translate(5.391 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_820-4" data-name="Rectangle 820" width="1.797" height="1.797" transform="translate(7.189 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_821-4" data-name="Rectangle 821" width="1.797" height="1.797" transform="translate(3.594 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_822-4" data-name="Rectangle 822" width="1.797" height="1.797" transform="translate(5.391 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_823-4" data-name="Rectangle 823" width="1.797" height="1.797" transform="translate(3.594 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_824-4" data-name="Rectangle 824" width="1.797" height="1.797" transform="translate(1.797 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_825-4" data-name="Rectangle 825" width="1.797" height="1.797" transform="translate(5.391 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_826-4" data-name="Rectangle 826" width="1.797" height="1.797" transform="translate(5.391 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_827-4" data-name="Rectangle 827" width="1.797" height="1.797" transform="translate(7.189 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_846-4" data-name="Rectangle 846" width="1.797" height="1.797" transform="translate(7.189 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_828-4" data-name="Rectangle 828" width="1.797" height="1.797" transform="translate(3.594 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_829-4" data-name="Rectangle 829" width="1.797" height="1.797" transform="translate(12.58 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_830-4" data-name="Rectangle 830" width="1.797" height="1.797" transform="translate(14.377 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_855-4" data-name="Rectangle 855" width="1.797" height="1.797" transform="translate(17.971 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_862-4" data-name="Rectangle 862" width="1.797" height="1.797" transform="translate(19.769 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_863-4" data-name="Rectangle 863" width="1.797" height="1.797" transform="translate(21.566 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_857-4" data-name="Rectangle 857" width="1.797" height="1.797" transform="translate(19.769 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_858-4" data-name="Rectangle 858" width="1.797" height="1.797" transform="translate(16.174 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_831-4" data-name="Rectangle 831" width="1.797" height="1.797" transform="translate(10.783 10.783)" fill="#0d66fb"/>
                    <rect id="Rectangle_832-4" data-name="Rectangle 832" width="1.797" height="1.797" transform="translate(8.986 7.189)" fill="#0d66fb"/>
                    <rect id="Rectangle_833-4" data-name="Rectangle 833" width="1.797" height="1.797" transform="translate(10.783 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_834-4" data-name="Rectangle 834" width="1.797" height="1.797" transform="translate(16.174 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_835-4" data-name="Rectangle 835" width="1.797" height="1.797" transform="translate(17.971 8.986)" fill="#0d66fb"/>
                    <rect id="Rectangle_836-4" data-name="Rectangle 836" width="1.797" height="1.797" transform="translate(19.769 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_838-4" data-name="Rectangle 838" width="1.797" height="1.797" transform="translate(16.174 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_837-4" data-name="Rectangle 837" width="1.797" height="1.797" transform="translate(19.769 7.189)" fill="#0d66fb"/>
                    <rect id="Rectangle_839-4" data-name="Rectangle 839" width="1.797" height="1.797" transform="translate(16.174 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_840-4" data-name="Rectangle 840" width="1.797" height="1.797" transform="translate(10.783 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_841-4" data-name="Rectangle 841" width="1.797" height="1.797" transform="translate(12.58 1.797)" fill="#0d66fb"/>
                    <rect id="Rectangle_842-4" data-name="Rectangle 842" width="1.797" height="1.797" transform="translate(14.377 3.594)" fill="#0d66fb"/>
                    <rect id="Rectangle_843-4" data-name="Rectangle 843" width="1.797" height="1.797" transform="translate(12.58 5.391)" fill="#0d66fb"/>
                    <rect id="Rectangle_844-4" data-name="Rectangle 844" width="1.797" height="1.797" transform="translate(5.391 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_845-4" data-name="Rectangle 845" width="1.797" height="1.797" transform="translate(3.594 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_848-4" data-name="Rectangle 848" width="1.797" height="1.797" transform="translate(8.986 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_849-4" data-name="Rectangle 849" width="1.797" height="1.797" transform="translate(12.58 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_850-4" data-name="Rectangle 850" width="1.797" height="1.797" transform="translate(12.58 19.769)" fill="#0d66fb"/>
                    <rect id="Rectangle_861-4" data-name="Rectangle 861" width="1.797" height="1.797" transform="translate(10.783 21.566)" fill="#0d66fb"/>
                    <rect id="Rectangle_851-4" data-name="Rectangle 851" width="1.797" height="1.797" transform="translate(12.58 16.174)" fill="#0d66fb"/>
                    <rect id="Rectangle_852-4" data-name="Rectangle 852" width="1.797" height="1.797" transform="translate(10.783 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_853-4" data-name="Rectangle 853" width="1.797" height="1.797" transform="translate(14.377 12.58)" fill="#0d66fb"/>
                    <rect id="Rectangle_854-4" data-name="Rectangle 854" width="1.797" height="1.797" transform="translate(17.971 14.377)" fill="#0d66fb"/>
                    <rect id="Rectangle_856-4" data-name="Rectangle 856" width="1.797" height="1.797" transform="translate(19.769 17.971)" fill="#0d66fb"/>
                    <rect id="Rectangle_859-4" data-name="Rectangle 859" width="1.797" height="1.797" transform="translate(14.377 17.971)" fill="#0d66fb"/>
                </g>
            </g>
        </svg>

    );
}

export default LearningDiagram;
import React from "react";
import {XAIContentProps} from "../../types/XAIContentProps";

function XAILeftContent({
  mainText,
  specialContent,
  nextHeadline,
}: XAIContentProps) {
  return (
    <>
      <div className="relative h-full flex flex-col">
        <div
          className={`${mainText ? "sticky top-0 mb-6 md:mb-24" : "hidden"}`}
        >
          {mainText?.map((text) => {
            return (
              <p key={text} className="text-white mt-4 text-base text-left lg:mr-36 mr-6 ml-6">
                {text}
              </p>
            );
          })}
          {specialContent && <div className="mt-4">{specialContent}</div>}
        </div>

        {nextHeadline && (
          <p className="hidden md:block text-white text-md text-left mt-auto ml-6 mr-6">
            {nextHeadline}
          </p>
        )}
      </div>
    </>
  );
}

export default XAILeftContent;
import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/Homepage/HomePage";
import DetailPage from "./components/TrendDetailPage/DetailPage";
import NavigationOverlay from "./components/Header/NavigationOverlay";
import { NavItem } from "./types/NavItem";
import ScrollToTop from "./components/Functionality/ScrollToTop";
import Imprint from "./components/Footer/Imprint";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import XAIPage from "./components/XAIPage/XAIPage";
import AboutPage from "./components/AboutPage/AboutPage";
import Portal from "./components/Functionality/Portal";
import AuthorDetailPage from "./components/AuthorDetailPage/AuthorDetailPage";
import { routerBasePath } from "./constants";
import TrendHistoryPage from "./components/TrendHistoryPage/TrendHistoryPage";
import { SkeletonTheme } from "react-loading-skeleton";
import PolicyChangePage from "./components/PolicyChangePage/PolicyChangePage";


function App() {
  const [visibleOverlay, setVisibleOverlay] = useState(false);
  const navItems: NavItem[] = [
    { to: "/", text: "Home" },
    { to: "/about", text: "About" },
    { to: "/history", text: "Trend History" },
    { to: "/how-it-works", text: "How It Works"}
  ];

  const toggleOverlay = () => {
    setVisibleOverlay(!visibleOverlay);
  };
  return (
    <div className="App mx-auto min-h-screen flex flex-col relative">
      <BrowserRouter basename={routerBasePath}>
        <ScrollToTop />
        {visibleOverlay && (
          <Portal>
            <NavigationOverlay
              navItems={navItems}
              toggleOverlay={toggleOverlay}
              visible={visibleOverlay}
            />
          </Portal>
        )}

        <Header navItems={navItems} toggleOverlay={toggleOverlay} />
        <SkeletonTheme baseColor="transparent" highlightColor="white">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/how-it-works" element={<XAIPage />} />
            <Route path="/trend/:trendName" element={<DetailPage />} />
            <Route
              path="/trend/:trendName/:authorId"
              element={<AuthorDetailPage />}
            />
          <Route path="/history" element={<TrendHistoryPage />} />
          <Route path="/policy-changes" element={<PolicyChangePage />} />
        </Routes>
        </SkeletonTheme>
        <Footer inverted={false} />
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import Splitscreen from "./Splitscreen";
import XAILeftContent from "./XAILeftContent";
import XAILegend from "./XAILegend";
import Button from "../Functionality/Button";
import ClassificationCards from "./ClassificationCards";
import MetricCard from "./MetricCard";
import SupervisedLearningDiagram from "./Diagrams/SupervisedLearningDiagram";
import DataSetDiagram from "./Diagrams/DataSetDiagram";
import FeatureDiagram from "./Diagrams/FeatureDiagram";
import LearningDiagram from "./Diagrams/LearningDiagram";
import ModelDiagram from "./Diagrams/ModelDiagram";

const supervisedLearningText = ["The process of an algorithm learning from the training dataset can be looked at like a " +
"tutor watching closely as their student is trying to solve a problem. We, like the tutor, know the right answers to the " +
"problem. The algorithm on the other hand, like the student, tries its best to find the answer. Supervised learning uses " +
"labeled training data - in our case existing Twitter profiles and tweets that are already labeled as Bot or Human."]

const datasetText = ["Our dataset holds just under 37.500 profiles, 29.000 of which remain after deleted accounts are removed. " +
    "The dataset is divided into 75% Humans and 25% Bots.",
    "We separate our data into two parts (80%/20%). " +
    "The smaller part is saved for testing the algorithm later on. The other part is given to our algorithm to let it " +
    "learn with it. The training data that the algorithm gets is already classified. The algorithm now looks at all the " +
    "data available to it and learns why someone is a bot or human based on features whose importance it determines itself."]

const featureText = ["Feature engineering is a machine learning technique that leverages data to create new variables" +
" that are not directly found in the training set. These new features can help increase the model's accuracy."
    ,
    "We pre-calculate the features ourselves, which we provide to the algorithm for learning. All features are compiled " +
    "from users' public profile data and collected tweets."
    ,
    "Some features are more important than others. This decision is left to the algorithm, it detects patterns in the " +
    "data itself."]

const decisionTrees = [
    "Machine learning algorithms can use decision trees which, at each branch and fork, represent a decision that the " +
    "algorithm makes. In the end, each branch reaches a leaf, the final decision of this path.",
    "Decision trees can be combined to work together, where the final answer regarding the classification of a subject " +
    "is derived by using a multitude of decision trees and reviewing their results together. Such approaches help make " +
    "the model more robust."
]

const xgbText = [
    "XGBoost stands for eXtreme Gradient Boosting and is a type of machine learning algorithm. It is designed to make" +
    " accurate predictions from large amounts of data and is used in many industries, such as credit scoring, sales " +
    "forecasting, and medical diagnostics. It works by combining multiple decision trees to create a model that is" +
    " more accurate than any single tree. ",
    "Each tree is trained on a subset of the data and the predictions are combined to get a more accurate result. " +
    "XGBoost has proven to be more effective than other traditional machine learning algorithms, making it a popular " +
    "choice for many data science projects."
]


const accuracyText = "This score measures how many labels the model got right out of the total number of predictions. " +
    "You can think of this as the percentage of predictions that were correct." +
    " Our model currently reaches an accuracy of "

const recallText = "The number of members of a class that the classifier identified correctly divided by the total " +
    "number of members in that class. In our case, the relevant class is the Not-Human class." +
    " Our model currently reaches a Recall of "

const precisionText = "The number of correctly identified members of a class divided by all the times the " +
    "model predicted that class." +
    " Our model currently reaches a Precision of "

const f1Text =
  "The F1-Score combines the precision and recall metrics of a classifier into a single metric by " +
  "calculating their harmonic mean." +
  " Our model currently reaches an F1-score of ";

function XAIPage(){
    return (
      <React.Fragment>
        <Splitscreen
          leftContent={<XAILeftContent nextHeadline={"Supervised Learning"} />}
          rightContent={<XAILegend />}
        />
        <Splitscreen
          leftContent={
            <XAILeftContent
              nextHeadline={"The Data We Used"}
              mainText={supervisedLearningText}
            />
          }
          rightContent={
            <div className="flex sticky top-8 justify-center mt-8 mb-8">
              <SupervisedLearningDiagram />
            </div>
          }
        />
        <Splitscreen
          leftContent={
            <XAILeftContent
              nextHeadline={
                "Features, Feature Engineering and Feature Importance"
              }
              specialContent={
                <div className="mx-4 text-left">
                  <a title="Dataset Link" href="https://www.kaggle.com/datasets/davidmartngutirrez/twitter-bots-accounts">
                    <Button onClick={() => 0} inverted={true}>
                      <span className="uppercase">Our dataset</span>
                    </Button>
                  </a>
                </div>
              }
              mainText={datasetText}
            />
          }
          rightContent={<DataSetDiagram />}
        />
        <Splitscreen
          leftContent={
            <XAILeftContent
              nextHeadline={"How Does the Algorithm Learn?"}
              mainText={featureText}
            />
          }
          rightContent={
            <div className="flex sticky top-8 justify-center mt-8 ml-4 mr-4 mb-8">
              <FeatureDiagram />
            </div>
          }
        />
        <Splitscreen
          leftContent={
            <XAILeftContent
              nextHeadline={"Which Model Do We Use?"}
              specialContent={<ClassificationCards />}
              mainText={decisionTrees}
            />
          }
          rightContent={
            <div className="flex sticky top-8 justify-center mt-8 mb-8 ml-4 mr-4">
              <LearningDiagram />
            </div>
          }
        />
        <Splitscreen
          leftContent={
            <XAILeftContent
              nextHeadline={"How Do We Evaluate Our Model?"}
              mainText={xgbText}
            />
          }
          rightContent={
            <div className="flex sticky top-8 justify-center mt-8 mb-8 ml-4 mr-4">
              <ModelDiagram />
            </div>
          }
        />
        <div className="bg-blue grid lg:grid-cols-4 sm:grid-cols-1 flex justify-center py-4">
          <MetricCard title={"Accuracy"} text={accuracyText} value={"86%"}/>
          <MetricCard title={"Recall"} text={recallText} value={"65%"}/>
          <MetricCard title={"Precision"} text={precisionText} value={"77%"}/>
          <MetricCard title={"F1-Score"} text={f1Text} value={"70%"}/>
        </div>
      </React.Fragment>
    );
}

export default XAIPage;
import React from "react";
import { AccordionLayoutProps } from "../../../types/AccordionLayoutProps";
import { prepTrendBannerInput } from "../../Functionality/PrepTrendBannerInput";
import ArrowIcon from "./ArrowIcon";

function AccordionLayout({ trendName, countries, index, activeIndex, setActiveIndex, children }: AccordionLayoutProps) {
    const handleSetIndex = (index: number) => {
        activeIndex !== index ? setActiveIndex(index) : setActiveIndex(-1);
    };

    const bannerElements = prepTrendBannerInput(trendName, countries, 7);

    return (
        <>
            <div
                className="overflow-x-hidden relative border-b border-black text-4xl group"
            >
                <div className={`py-4 inline-flex fill-blue group-hover:bg-blue group-hover:text-white group-hover:fill-white whitespace-nowrap 
                    ${activeIndex === index ? "bg-blue text-white fill-white" : ""}`}>
                    {bannerElements.map((bannerContent: JSX.Element) => bannerContent)}
                </div>
                <button
                    onClick={() => handleSetIndex(index)}
                    className={`
                    absolute right-0 top-0 w-full h-full text-right 
                    fill-blue hover:fill-white
                    before:absolute before:right-0 before:top-0 before:h-full before:w-32 before:block 
                    before:bg-gradient-to-l before:from-white before:via-white before:hover:from-blue before:hover:via-blue
                    ${activeIndex === index ? "fill-white before:from-blue before:via-blue" : ""} `}
                >
                    <div className={`w-10 ml-auto mr-4 transition-transform duration-300 
                        ${activeIndex === index ? "rotate-180" : ""}`}>
                        <ArrowIcon />
                    </div>

                </button>
            </div>
            <div
                className={`transition-all duration-500 overflow-hidden w-full 
                    ${activeIndex === index ? "sm:max-h-[140vh] max-h-full" : "max-h-0"}`}
            >
                {children}
            </div>
        </>
    );
}

export default AccordionLayout;

import React from "react";
import {CircleUncertainIconProps} from "../../../types/CircleUncertainIconProps";

function CircleUncertainIcon({inverted = false}: CircleUncertainIconProps) {
    const pixelFillColor = inverted ? "fill-white" : "fill-blue"
    return (
        <svg className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
            <circle className={`${inverted ? "fill-blue stroke-white" : "fill-white stroke-blue"} stroke-[4px]`} cx="28" cy="28" r="26" />

            <rect className={pixelFillColor} x="4" y="24" width="4" height="4"/>

            <rect className={pixelFillColor} x="8" y="12" width="4" height="4"/>
            <rect className={pixelFillColor} x="8" y="20" width="4" height="4"/>
            <rect className={pixelFillColor} x="8" y="28" width="4" height="4"/>
            <rect className={pixelFillColor} x="8" y="40" width="4" height="4"/>
            <rect className={pixelFillColor} x="8" y="44" width="4" height="4"/>

            <rect className={pixelFillColor} x="12" y="8" width="4" height="4"/>
            <rect className={pixelFillColor} x="12" y="12" width="4" height="4"/>
            <rect className={pixelFillColor} x="12" y="24" width="4" height="4"/>
            <rect className={pixelFillColor} x="12" y="28" width="4" height="4"/>
            <rect className={pixelFillColor} x="12" y="36" width="4" height="4"/>
            <rect className={pixelFillColor} x="12" y="44" width="4" height="4"/>

            <rect className={pixelFillColor} x="16" y="4" width="4" height="4"/>
            <rect className={pixelFillColor} x="16" y="12" width="4" height="4"/>
            <rect className={pixelFillColor} x="16" y="28" width="4" height="4"/>
            <rect className={pixelFillColor} x="16" y="32" width="4" height="4"/>

            <rect className={pixelFillColor} x="20" y="16" width="4" height="4"/>
            <rect className={pixelFillColor} x="20" y="40" width="4" height="4"/>

            <rect className={pixelFillColor} x="24" y="4" width="4" height="4"/>
            <rect className={pixelFillColor} x="24" y="20" width="4" height="4"/>
            <rect className={pixelFillColor} x="24" y="24" width="4" height="4"/>
            <rect className={pixelFillColor} x="24" y="32" width="4" height="4"/>
            <rect className={pixelFillColor} x="24" y="48" width="4" height="4"/>

            <rect className={pixelFillColor} x="28" y="4" width="4" height="4"/>
            <rect className={pixelFillColor} x="28" y="12" width="4" height="4"/>
            <rect className={pixelFillColor} x="28" y="24" width="4" height="4"/>
            <rect className={pixelFillColor} x="28" y="36" width="4" height="4"/>
            <rect className={pixelFillColor} x="28" y="40" width="4" height="4"/>
            <rect className={pixelFillColor} x="28" y="44" width="4" height="4"/>

            <rect className={pixelFillColor} x="32" y="8" width="4" height="4"/>
            <rect className={pixelFillColor} x="32" y="24" width="4" height="4"/>
            <rect className={pixelFillColor} x="32" y="28" width="4" height="4"/>
            <rect className={pixelFillColor} x="32" y="40" width="4" height="4"/>

            <rect className={pixelFillColor} x="36" y="8" width="4" height="4"/>
            <rect className={pixelFillColor} x="36" y="12" width="4" height="4"/>
            <rect className={pixelFillColor} x="36" y="20" width="4" height="4"/>
            <rect className={pixelFillColor} x="36" y="36" width="4" height="4"/>
            <rect className={pixelFillColor} x="36" y="44" width="4" height="4"/>

            <rect className={pixelFillColor} x="40" y="20" width="4" height="4"/>
            <rect className={pixelFillColor} x="40" y="28" width="4" height="4"/>
            <rect className={pixelFillColor} x="40" y="32" width="4" height="4"/>

            <rect className={pixelFillColor} x="44" y="12" width="4" height="4"/>
            <rect className={pixelFillColor} x="44" y="16" width="4" height="4"/>
            <rect className={pixelFillColor} x="44" y="28" width="4" height="4"/>
            <rect className={pixelFillColor} x="44" y="36" width="4" height="4"/>
            <rect className={pixelFillColor} x="44" y="40" width="4" height="4"/>

            <rect className={pixelFillColor} x="48" y="20" width="4" height="4"/>
        </svg>
    );
}

export default CircleUncertainIcon;
import React from "react";
import { IconButtonProps } from "../../types/IconButtonProps";
import Button from "./Button";

function IconButton({ iconPath, iconAltText, onClick, label }: IconButtonProps) {

  return (
    <>
      <Button onClick={onClick} inverted={true}>
        <img src={iconPath} className="mr-2 h-4" alt={iconAltText} />{" "}
        {label}
      </Button>
    </>
  );
}

export default IconButton;

import {LineChartAxisBottomProps} from "../../../types/LineChartAxisBottomProps";
import {LineChartAxisLeftProps} from "../../../types/LineChartAxisLeftProps";
import {useEffect, useRef} from "react";
import {ScaleOrdinal, select, line} from "d3";
import {GroupedChartDataType, GroupedChartDataValuesType} from "../../../types/GroupedChartDataType";

type LinesProps = {
    data: GroupedChartDataType[];
    scaleX: LineChartAxisBottomProps["scale"];
    scaleY: LineChartAxisLeftProps["scale"];
    scaleColor: ScaleOrdinal<string, unknown>;
}

function Lines({ data, scaleX, scaleY, scaleColor }: LinesProps) {
    const ref = useRef<SVGGElement>(null);

    const lineGenerator = line<GroupedChartDataValuesType>()
        .defined(function (d) { return d.botResult !== -1; })
        .x(d => scaleX(new Date(d.trendedOn)))
        .y(d => scaleY(d.botResult))

    const filteredData = data.map(group => {
        const filteredValues = group.values.filter(d => d.botResult !== -1)
        return { name: group.name, values: filteredValues }
    })

    useEffect(() => {
        if (ref.current) {
            // CLEAR PREVIOUS CONTENT
            const everything = select(ref.current).selectAll("*");
            everything.remove();

            // DRAW DOTTED LINE FOR MISSING DATA
            select(ref.current)
                .selectAll(".gap-lines")
                .data(filteredData)
                .join("path")
                .attr("class", "gap-line")
                .attr("d", d => lineGenerator(d.values))
                .attr("stroke", d => scaleColor(d.name) as string)
                .style("stroke-width", 3)
                .style("stroke-dasharray", 5)
                .style("fill", "none");

            // DRAW LINES
            select(ref.current)
                .selectAll(".lines")
                .data(data)
                .join("path")
                .attr("class", "line")
                .attr("d", d => lineGenerator(d.values))
                .attr("stroke", d => scaleColor(d.name) as string)
                .style("stroke-width", 3)
                .style("fill", "none");
        }
    }, [data, scaleColor, lineGenerator, filteredData]);

    return <g ref={ref} />;
}

export default Lines;
import React from "react";
import AccordionLayout from "./AccordionLayout";
import {TrendHistoryType} from "../../../types/TrendHistoryType";
import {AccordionProps} from "../../../types/AccordionProps";
import StatisticsTable from "../../Functionality/StatisticsTable";
import {formatNumber} from "../../../utils/formatNumber";
import {THRESHOLD_BOT, THRESHOLD_UNSURE, TWEET_LABELS} from "../../../constants";
import Separator from "../../Functionality/Separator";
import DoughnutChartRow from "../../Functionality/DoughnutChart/DoughnutChartRow";
import Button from "../../Functionality/Button";
import {Link} from "react-router-dom";

function Accordion({ trendName, trendHistory, activeIndex, setActiveIndex }: AccordionProps) {
    const dates: Array<Date> = (Object.values(trendHistory).flat(1) as Array<TrendHistoryType>)
        .map(history => new Date(history.trended_on))
    const lastTrendedOn = dates.reduce((a, b) => a > b ? a : b)
    const dayOnTrendString = trendHistory.length + " day" + (trendHistory.length > 1 ? "s" : "")

    const sumAllResults = trendHistory
        .map(history => history.bot_result + history.human_result + history.unsure_result)
        .reduce((a,b) => a + b, 0)

    const sumBotResults = trendHistory
        .map(history => history.bot_result)
        .reduce((a,b) => a + b, 0)

    const sumHumanResults = trendHistory
        .map(history => history.human_result)
        .reduce((a,b) => a + b, 0)

    const sumUnsureResults = trendHistory
        .map(history => history.unsure_result)
        .reduce((a,b) => a + b, 0)

    const sumAuthorSize = trendHistory
        .map(history => history.author_size)
        .reduce((a,b) => a + b, 0)

    const sumTweetSize = trendHistory
        .map(history => history.tweet_size)
        .reduce((a,b) => a + b, 0)

    const countries = trendHistory
        .map((history) => history.countries)
        .flat()
        .reduce((unique: Array<string>, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
    )

    const doughnutChartData = [
        {
            percentage: Math.round((sumBotResults / sumAllResults) * 100),
            label: TWEET_LABELS.bot,
            thresholdMin: THRESHOLD_BOT * 100,
            thresholdMax: 100
        },
        {
            percentage: Math.round((sumUnsureResults / sumAllResults) * 100),
            label: TWEET_LABELS.unsure,
            thresholdMin: THRESHOLD_UNSURE * 100,
            thresholdMax: THRESHOLD_BOT * 100
        },
        {
            percentage: Math.round((sumHumanResults / sumAllResults) * 100),
            label: TWEET_LABELS.human,
            thresholdMin: 0,
            thresholdMax: THRESHOLD_UNSURE * 100
        }
    ]

    return (
            <AccordionLayout
                trendName={trendName}
                countries={countries}
                index={trendHistory[0].trend_id}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
            >
                <div className="mt-10 mb-10">
                    <div className="block md:hidden">
                        <Separator primaryLabel={"Trend Name"} />
                        <p className="text-3xl text-blue text-left px-6 mt-4 mb-10">
                            <Link to={`/trend/${trendName}`} className="link-underline-blue">{trendName}</Link>
                        </p>
                    </div>
                    <Separator primaryLabel={"Distribution of Accounts"} />
                    <div className="mb-20">
                        <DoughnutChartRow data={doughnutChartData} />
                    </div>
                    <Separator primaryLabel={"Trend Statistics"} />
                    <div className="-mt-[1px] mb-10">
                        <StatisticsTable statistics={[
                            {label: "Trending for", value: dayOnTrendString},
                            {label: "Last Time Trending", value: lastTrendedOn.toLocaleDateString("en-UK",
                                    { timeZone: "GMT", year: "numeric", month: "short", day: "numeric" })},
                            {label: "Tweets Analyzed", value: formatNumber(sumTweetSize)},
                            {label: "Accounts Analyzed", value: formatNumber(sumAuthorSize)}
                        ]} />
                    </div>
                    <div className="px-6">
                        <Link to={`/trend/${encodeURIComponent(trendName)}`} data-cy="get-past-trend-details">
                            <Button onClick={() => {}} inverted={true}>
                                <span className="uppercase">Get more details</span>
                            </Button>
                        </Link>
                    </div>

                </div>
            </AccordionLayout>
    );
}

export default Accordion;
import React from "react";
import ArrowIcon from "../../Functionality/Icons/ArrowIcon";

function NavigationButton({
  onClick,
  label,
  inverted,
}: {
  onClick: () => void;
  label: string;
  inverted: boolean;
}) {
  let styles;
  if (inverted) {
    styles = "text-blue";
  } else {
    styles = "text-white font-bot bg-blue";
  }
  return (
    <button
      className={`w-full sm:w-1/2 relative text-4xl px-8 md:px-2 py-14 sm:py-32 sm:hover:w-3/5  transition-all ${styles}`}
      onClick={onClick}
    >
      <span className="flex justify-center items-center gap-0 md:gap-8">
        {!inverted && <ArrowIcon rotation={180} color={"white"}></ArrowIcon>}
        {label}
        {inverted && <ArrowIcon rotation={0} color={"#1266f9"}></ArrowIcon>}
      </span>
    </button>
  );
}

export default NavigationButton;

import React, { useEffect } from "react";

function PrivacyPolicy() {
  const liMargin = "my-3";
  const html = document.querySelector("html");

  useEffect(() => {
    html?.classList.add("scroll-smooth");

    return () => {
      html?.classList.remove("scroll-smooth");
    };
  });

  return (
    <React.Fragment>
      <div className="bg-blue text-white pt-20 pb-6 text-left">
        <div className="max-w-[850px] mx-auto px-6">
          <h1 className="text-xl max-w-4xl font-bot mb-6">PRIVACY POLICY</h1>
          <p className="pb-4 italic">Last updated on October 24, 2022</p>
        </div>
      </div>
      <div className="px-6 pt-6 pb-12 mx-auto text-left max-w-[850px]">
        <section
          role="contentinfo"
          aria-label="Purpose of the Privacy Policy"
          className="mb-6"
        >
          <p className="mb-4">
            Thank you for choosing to be part of our community at Spot A Bot and
            the University of Applied Sciences Salzburg ("Company," "we," "us,"
            or "our"). <br />
            We are committed to protecting your personal information and your
            right to privacy. If you have any questions or concerns about this
            privacy notice or our practices with regard to your personal
            information, please contact us{" "}
            <a
              target="_blank"
              href="https://twitter.com/SpotABot_"
              rel="noreferrer"
              className="link-underline-blue"
            >
              here
            </a>
            .
          </p>
          <p className="mb-2 mt-10">
            This privacy notice describes how we might use your information if
            you:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li className={liMargin}>
              Visit our website at{" "}
              <a
                target="_blank"
                href="https://twitter.com/SpotABot_"
                rel="noreferrer"
                className="link-underline-blue"
              >
                Spot A Bot
              </a>
            </li>
            <li className={liMargin}>
              Engage with us in other related ways ― including any sales,
              marketing, or events
            </li>
          </ul>
          <p className="mb-2 mt-10">In this privacy notice, if we refer to:</p>
          <ul className="list-disc list-inside mb-4">
            <li className={liMargin}>
              <strong className="font-semibold">Website</strong>, we are
              referring to any website of ours that references or links to this
              policy
            </li>
            <li className={liMargin}>
              <strong className="font-semibold">Services</strong>, we are
              referring to our Website, and other related services, including
              any sales, marketing, or events
            </li>
          </ul>
          <p className="mb-2 mt-10">
            The purpose of this privacy notice is to explain to you in the
            clearest way possible what information we collect, how we use it,
            and what rights you have in relation to it. <br />
            If there are any terms in this privacy notice that you do not agree
            with, please discontinue use of our Services immediately.
          </p>
          <p>
            <strong className={"font-semibold"}>
              Please read this privacy notice carefully, as it will help you
              understand what we do with the information that we collect.
            </strong>
          </p>
        </section>
        <section
          role="contentinfo"
          aria-label="Table of contents"
          className="mb-12 mt-14"
        >
          <h2 className="mb-4 text-md">Table of contents</h2>
          <ul className="list-disc list-inside mb-4">
            <li className={`text-blue ${liMargin}`}>
              <a className="link-underline-blue" href="/privacy#information">
                What information do we collect?
              </a>
            </li>
            <li className={`text-blue ${liMargin}`}>
              <a className="link-underline-blue" href="/privacy#cookies">
                Do we use cookies and other tracking technologies?
              </a>
            </li>
            <li className={`text-blue ${liMargin}`}>
              <a className="link-underline-blue" href="/privacy#rights">
                What are your privacy rights?
              </a>
            </li>
            <li className={`text-blue ${liMargin}`}>
              <a className="link-underline-blue" href="/privacy#controls">
                Controls for do not track features
              </a>
            </li>
            <li className={`text-blue ${liMargin}`}>
              <a className="link-underline-blue" href="/privacy#updates">
                Do we make updates to this notice?
              </a>
            </li>
            <li className={`text-blue ${liMargin}`}>
              <a className="link-underline-blue" href="/privacy#contact">
                How can you contact us about this notice?
              </a>
            </li>
            <li className={`text-blue ${liMargin}`}>
              <a className="link-underline-blue" href="/privacy#data">
                How can you review, update or delete the data we collect from
                you?
              </a>
            </li>
          </ul>
        </section>
        <section
          role="contentinfo"
          aria-label="What information do we collect?"
          className="mb-12"
        >
          <h2 id="information" className="mb-4 text-md">
            What information do we collect?
          </h2>
          <p>
            We currently do not collect any kind of information about our users.
          </p>
        </section>
        <section
          role="contentinfo"
          aria-label="Do we use cookies and other tracking technologies?"
          className="mb-12"
        >
          <h2 id="cookies" className="mb-4 text-md">
            Do we use cookies and other tracking technologies?
          </h2>
          <p className="italic mb-2">
            In short: We currently do not use third-party cookies and other
            tracking technologies to collect and store your information.
          </p>
          <h3 className="mb-2 mt-4 text-base font-semibold">
            Third-party cookies
          </h3>
          <p>
            We do not use cookies or similar tracking technologies (like web
            beacons and pixels) to access or store information.
          </p>
          <h3 className="mb-2 mt-4 text-base font-semibold">
            Functional cookies
          </h3>
          <p>We do not use functional cookies.</p>
        </section>
        <section
          role="contentinfo"
          aria-label="What are your privacy rights?"
          className="mb-12"
        >
          <h2 id="rights" className="mb-4 text-md">
            What are your privacy rights?
          </h2>
          <p className="italic mb-2 mt-4">
            In short: In some regions, such as the European Economic Area (EEA)
            and United Kingdom (UK), you have rights that allow you greater
            access to and control over your personal information. <br />
            You may review, change, or terminate your account at any time.
          </p>
          <p className="mb-2 mt-4">
            In some regions (like the EEA and UK), you have certain rights under
            applicable data protection laws. <br />
            These may include the right (i) to request access and obtain a copy
            of your personal information, (ii) to request rectification or
            erasure; (iii) to restrict the processing of your personal
            information; and (iv) if applicable, to data portability. <br />
            In certain circumstances, you may also have the right to object to
            the processing of your personal information. To make such a request,
            please use the contact details provided below. <br />
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p className="mb-2 mt-4">
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. <br />
            Please note however that this will not affect the lawfulness of the
            processing before its withdrawal, nor will it affect the processing
            of your personal information conducted in reliance on lawful
            processing grounds other than consent.
          </p>
          <p className="mb-2 mt-4">
            If you are a resident in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to If you are a resident in the EEA or UK and you
            believe we are unlawfully processing your personal information, you
            also have the right to complain to{" "}
            <a
              target="_blank"
              href="https://ec.europa.eu/newsroom/article29/items/612080"
              rel="noreferrer"
              className="link-underline-blue"
            >
              your local data protection supervisory authority
            </a>
            .
          </p>
          <p className="mb-2 mt-4">
            If you are a resident in Switzerland, contact{" "}
            <a
              target="_blank"
              href="https://twitter.com/SpotABot_"
              rel="noreferrer"
              className="link-underline-blue"
            >
              this data protection supervisory authority
            </a>
            .
          </p>
          <p className="mb-4 mt-4">
            If you have questions or comments about your privacy rights, you may
            contact us{" "}
            <a
              target="_blank"
              href="https://twitter.com/SpotABot_"
              rel="noreferrer"
              className="link-underline-blue"
            >
              here
            </a>
            .
          </p>
          <h3 className="mb-2 mt-4 text-md">
            Cookies and similar technologies
          </h3>
          <p className="mb-2 mt-4">
            Most web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies.
          </p>
        </section>
        <section
          role="contentinfo"
          aria-label="Controls for do not track features"
          className="mb-12"
        >
          <h2 id="controls" className="mb-4 text-md">
            Controls for do not track features
          </h2>
          <p className="mb-2 mt-4">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected.{" "}
            <br />
            At this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online.{" "}
            <br />
            If a standard for online tracking is adopted that we must follow in
            the future, we will inform you about that practice in a revised
            version of this privacy notice.
          </p>
        </section>
        <section
          role="contentinfo"
          aria-label="Do we make updates to this notice?"
          className="mb-12"
        >
          <h2 id="updates" className="mb-4 text-md">
            Do we make updates to this notice?
          </h2>
          <p className="italic pb-2">
            In short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </p>
          <p className="mb-2 mt-4">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible.{" "}
            <br />
            If we make material changes to this privacy notice, we may notify
            you either by prominently posting a notice of such changes or by
            directly sending you a notification. <br />
            We encourage you to review this privacy notice frequently to be
            informed of how we are protecting your information.
          </p>
        </section>
        <section
          role="contentinfo"
          aria-label="How can you contact us about this notice?"
          className="mb-12"
        >
          <h2 id="contact" className="mb-4 text-md">
            How can you contact us about this notice?
          </h2>
          <p className="mb-2 mt-4">
            If you have questions or comments about this notice, you may contact
            us{" "}
            <a
              target="_blank"
              href="https://twitter.com/SpotABot_"
              rel="noreferrer"
              className="link-underline-blue"
            >
              here
            </a>{" "}
            or by post to:
          </p>
          <address className="not-italic">
            <strong>University of Applied Sciences Salzburg</strong>
            <br />
            Urstein Süd 3
            <br />
            5412 Puch bei Hallein
            <br />
            Austria
          </address>
        </section>
        <section
          role="contentinfo"
          aria-label="How can you review, update or delete the data we collect from you?"
          className="mb-12"
        >
          <h2 id="data" className="mb-4 text-md">
            How can you review, update or delete the data we collect from you?
          </h2>
          <p className="mb-2 mt-4">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please send us a message{" "}
            <a
              target="_blank"
              href="https://twitter.com/SpotABot_"
              rel="noreferrer"
              className="link-underline-blue"
            >
              here
            </a>
            . 🤖
          </p>
        </section>
      </div>
    </React.Fragment>
  );
}

export default PrivacyPolicy;

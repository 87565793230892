import React from "react";
import { AuthorDetails } from "../../../types/AuthorDetails";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";

type AuthorDetailPageHeaderProps = {
  trendName: string | undefined;
  authorDetails?: AuthorDetails;
  loading: boolean;
};

function AuthorDetailPageHeader({
  trendName,
  authorDetails,
  loading,
}: AuthorDetailPageHeaderProps) {
  const getHeaderText = () => {
      if (loading)
        return (
          <Skeleton
            containerTestId={"loading-skeleton"}
            containerClassName="block max-w-md"
            count={2}
          />
        );
      if (!authorDetails) {
        // two invisible lines so header doesnt jump
        return (
          <p className="text-2xl">
            &nbsp;
            <br />
            &nbsp;
          </p>
        );
      }

      return (
        <>
          <h2 className="text-base">
            Author from Trend:{" "}
            <Link to={`/trend/${trendName}`} className="link-underline-white">
              {" "}
              {trendName}
            </Link>
          </h2>
          <h2 className="text-base">
            Bot Probability:{" "}
            {authorDetails?.probability
              ? `${Math.round(authorDetails?.probability * 100)}%`
              : "N/A"}
          </h2>
        </>
      );
  };

  return (
    <div className="bg-blue text-left text-white pt-20 md:pt-32 pb-8 px-4 md:px-6">
      {getHeaderText()}
      <h1 className="text-lg md:text-xl mt-4">
        {loading ? (
          <Skeleton containerClassName="block max-w-lg" />
        ) : (
          <>
            {authorDetails
              ? "Evaluate whether this user might be a bot"
              : "Author not Found"}
          </>
        )}
      </h1>
    </div>
  );
}

export default AuthorDetailPageHeader;

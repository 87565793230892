import React from "react";

function ArrowIcon() {
    return(
        <svg className="rotate-90" id="arrow" data-name="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.2 45.4">
            <path d="M34.5,0l22.7,22.7-22.7,22.7h-7.4l19.9-20.1H0v-5.3H47L27.1,0h7.4Z"/>
        </svg>
    );
}

export default ArrowIcon;
import React from "react";
import CircleIcon from "../../Functionality/Icons/CircleIcon";
import CircleUncertainIcon from "../../Functionality/Icons/CircleUncertainIcon";

function TweetLabel({
  label,
  probability,
}: {
  label: string;
  probability: number;
}) {
  const profileAvatar =
    label === "HUMAN" ? (
      <CircleIcon />
    ) : label === "BOT" ? (
      <CircleIcon filled />
    ) : (
      <CircleUncertainIcon />
    );

  return (
    <div className="flex mb-3 text-blue text-base">
      <div className="h-6 w-6 mr-2">{profileAvatar}</div>
      <p>{label}</p>
      <p className="ml-auto text-sm px-2 py-1">
        BP: {Math.round(probability * 100)}%
      </p>
    </div>
  );
}

export default TweetLabel;

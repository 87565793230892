import React from "react";
import { BarChartSeparatorType } from "../../../types/BarChartSeparatoProps";

function BarChartSeparators({ data, width, scaleY }: BarChartSeparatorType) {
  const getYPosition = (label: string) => {
    const scaleValue = scaleY(label);
    if (scaleValue === undefined) return 0;
    return scaleValue + scaleY.bandwidth();
  };

  return (
    <>
      {data.map(({ label }) => (
        <line
          key={label}
          x1={0}
          y1={getYPosition(label)}
          x2={width}
          y2={getYPosition(label)}
          stroke={"black"}
        />
      ))}
    </>
  );
}

export default BarChartSeparators;

import React, { useEffect } from "react";
import { createPortal } from "react-dom";

function Portal({ children }: { children: React.ReactNode }) {
  const el = React.useRef(document.createElement("div"));
  const portalRoot = document.getElementById("overlay-root");

  useEffect(() => {
    const currentEl = el.current;
    portalRoot?.appendChild(currentEl);
    document.body.classList.add("overflow-hidden");
    return () => {
      portalRoot?.removeChild(currentEl);
      document.body.classList.remove("overflow-hidden");
    };
  }, [portalRoot]);

  return createPortal(children, el.current);
}

export default Portal;

import React from "react";
import {SearchTextProps} from "../../../types/SearchTextProps";
import MagnifyingGlassIcon from "./MagnifyingGlassIcon";

const Search = ({ searchQuery, handleChange } : SearchTextProps) => {
    return (
      <>
        <label htmlFor="search-header" className="w-full block invisible h-0">
          Search for past trends:
        </label>
        <div className="flex justify-between items-center h-full">
          <input
            className="focus-visible:outline-none w-full text-xl lg:text-4xl 2xl:text-6xl p-4 md:px-0 placeholder-gray-300 placeholder-opacity-100"
            type="text"
            id="search-header"
            placeholder="Search for past trends"
            name="search"
            value={searchQuery}
            onInput={(event) =>
              handleChange((event.target as HTMLInputElement).value)
            }
          />
          <div className="fill-blue px-4">
            <MagnifyingGlassIcon />
          </div>
        </div>
      </>
    );
}

export default Search;
import React from "react";
import { TweetProps } from "../../../types/TweetProps";
import TweetLabel from "./TweetLabel";
import TweetVotes from "./TweetVotes";
import TweetLikeCounts from "./TweetLikeCounts";

function Tweet({ tweet, gridView }: TweetProps) {
  const { label, probability, text } = tweet;
  const userVoteCount = tweet.user_bot_classifications?.length;
  const horizontalStyles = [
    "mr-8",
    "flex-shrink-0",
    "max-w-xs",
    "border-blue",
    "last:mr-0",
  ];
  const customStyles = gridView ? [""] : horizontalStyles;

  const getDecodedString = (tweet: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = tweet;
    return txt.value;
  };

  return (
    <div
      className={
        "border border-black rounded-xl p-4 hover:border-blue group " +
        customStyles.join(" ")
      }
    >
      {gridView && (
        <TweetLabel label={label} probability={probability}></TweetLabel>
      )}
      <p data-testid="tweet" className="text-left text-[18px] leading-normal mb-4 break-words">
        {getDecodedString(text)}
      </p>
      {!gridView && (
        <TweetLikeCounts
          likeCount={tweet.like_count}
          retweetCount={tweet.retweet_count}
          replyCount={tweet.reply_count}
        ></TweetLikeCounts>
      )}
      <div
        className={`flex items-center ${
          userVoteCount > 0 ? "justify-between" : "justify-end"
        }`}
      >
        {gridView && <TweetVotes userVoteCount={userVoteCount}></TweetVotes>}
        {gridView && (
          <button className="border px-6 mt-2 py-1 text-sm rounded-full mb-0 md:mb-0 text-blue bg-white border-blue">
            More about this account
          </button>
        )}
      </div>
    </div>
  );
}

export default Tweet;

import React from "react";
import { TweetLikeCountsProps } from "../../../types/TweetLikeCountsProps";

function TweetLikeCounts({
  likeCount,
  retweetCount,
  replyCount,
}: TweetLikeCountsProps) {
  return (
    <div className="flex justify-between">
      <p className="text-xs">
        {likeCount} {likeCount === 1 ? "Like" : "Likes"}
      </p>
      <p className="text-xs mx-3">
        {retweetCount} {retweetCount === 1 ? "Retweet" : "Retweets"}
      </p>
      <p className="text-xs">
        {replyCount} {replyCount === 1 ? "Reply" : "Replies"}
      </p>
    </div>
  );
}

export default TweetLikeCounts;

import { ScaleBand, ScaleLinear } from "d3";
import React from "react";
import BarExplanation from "./BarExplanation";

interface Data {
  label: string;
  value: number;
  explanation: string;
}

interface AxisHorizontalProps {
  scale: ScaleBand<string>;
  transform: string;
}

interface BarChartProps {
  data: Data[];
}

interface AxisLeftProps {
  scale: ScaleLinear<number, number>;
}

interface BarsProps {
  data: BarChartProps["data"];
  scaleX: AxisLeftProps["scale"];
  scaleY: AxisHorizontalProps["scale"];
  isNegative: boolean;
  chartWidth: number;
}

function Bars({ data, scaleX, scaleY, isNegative, chartWidth }: BarsProps) {
  const calcXPos = (value: number) => {
    return scaleX(Math.min(0, value));
  };

  const toggleExplanation = (show: boolean, label: string) => {
    if (chartWidth > 768) {
      const group = document.getElementById("g-" + label);
      if (group) group.style.opacity = show ? "1" : "0";
    }
  };

  return (
    <>
      {data.map(({ value, label, explanation }) => (
        <React.Fragment key={value + label}>
          <rect
            key={`bar-${label}`}
            x={calcXPos(value)}
            y={scaleY(label)}
            height={scaleY.bandwidth()}
            width={Math.abs(scaleX(value) - scaleX(0))}
            fill={isNegative ? "#FB720D" : "#0D66FB"}
          />
          <rect
            key={`hover-${label}`}
            x={0}
            y={scaleY(label)}
            height={scaleY.bandwidth()}
            width={chartWidth}
            fill="transparent"
            onMouseEnter={() => toggleExplanation(true, label)}
            onMouseLeave={() => toggleExplanation(false, label)}
          />

          <BarExplanation
            label={label}
            explanation={explanation}
            isNegative={isNegative}
            onMouseEnter={() => toggleExplanation(true, label)}
            x={!isNegative ? 30 : chartWidth - 15}
            y={(scaleY(label) as number) + 40}
          />
        </React.Fragment>
      ))}
    </>
  );
}

export default Bars;

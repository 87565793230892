import React from "react";
import CircleIcon from "../Functionality/Icons/CircleIcon";

function XAILegend(){
    return (
        <div className="flex mt-2 mb-2 mr-2 float-right hidden md:flex">
            <div className="flex">
                <div title="Bot icon" className="h-6 w-6 mr-2"><CircleIcon filled/></div>
                <span className="text-blue mr-2 align-top">Bot</span>
            </div>

            <div className="flex ml-6">
                <div title="Human icon" className="h-6 w-6 mr-2"><CircleIcon/></div>
                <span className="text-blue mr-2 align-top">Human</span>
            </div>

        </div>
    )
}

export default XAILegend;
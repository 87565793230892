import React, { useEffect, useState } from "react";
import { scaleBand, scaleLinear } from "d3";
import Bars from "./Bars";
import { BarChartProps } from "../../../types/BarChartProps";
import BarChartAxisLeft from "./BarChartAxisLeft";
import BarChartAxisTop from "./BarChartAxisTop";
import BarChartSeparators from "./BarChartSeparators";
import NotAvailable from "../../Functionality/NotAvailable";
import { featureExplanations } from "../../../constants";

const BarChart = ({ initialWidth, initialHeight, data }: BarChartProps) => {
  const margin = { top: 0, right: 0, bottom: 30, left: 0 };
  const rightSpace = window.innerWidth > 768 ? 15 : 0;
  // keep track of width for browser resize
  const [width, setWidth] = useState(
    initialWidth - margin.left - margin.right - rightSpace
  );
  const height = initialHeight - margin.top - margin.bottom;

  useEffect(() => {
    window.addEventListener("resize", () => {
      const rightSpace = window.innerWidth > 768 ? 15 : 0;
      setWidth(window.innerWidth - margin.left - margin.right - rightSpace);
    });
    return () => window.removeEventListener("resize", () => {});
  }, [margin.left, margin.right]);

  if (!data || data.length <= 0)
    return (
      <NotAvailable
        notice={"No feature importance available for this author."}
      />
    );

  let adjustedData = data.filter(({ label }) => label !== "<BIAS>");
  adjustedData.sort((a, b) => Math.abs(b.value) - Math.abs(a.value));
  adjustedData = adjustedData.slice(0, 10);
  adjustedData.sort((a, b) => b.value - a.value);
  const finalData = adjustedData.map((dataPoint) => {
    const identifier = dataPoint.label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return {
      ...dataPoint,
      label: featureExplanations[identifier].title,
      explanation: featureExplanations[identifier].explanation,
    };
  });

  const scaleY = scaleBand()
    .domain(finalData.map(({ label }) => label))
    .range([0, height]);

  const maxValue = Math.max(...finalData.map(({ value }) => Math.abs(value)));
  const scaleX = scaleLinear().domain([-maxValue, maxValue]).range([0, width]);

  const negativeData = finalData.filter(({ value }) => value < 0);
  const positiveData = finalData.filter(({ value }) => value >= 0);

  return (
    <svg
      id={"detail-bar-chart"}
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <BarChartAxisTop
          scale={scaleX}
          transform={`translate(0, ${height + margin.bottom})`}
        />
        <Bars
          data={positiveData}
          scaleX={scaleX}
          scaleY={scaleY}
          isNegative={false}
          chartWidth={width}
        />
        <Bars
          data={negativeData}
          scaleX={scaleX}
          scaleY={scaleY}
          isNegative={true}
          chartWidth={width}
        />
        <BarChartAxisLeft
          data={finalData}
          scaleX={scaleX}
          scaleY={scaleY}
          width={width}
        />
        <BarChartSeparators data={finalData} width={width} scaleY={scaleY} />
      </g>
    </svg>
  );
};

export default BarChart;

import React from "react";
import WorldSvg from "./WorldSvg";
import Skeleton from "react-loading-skeleton";
import { DetailPageHeaderProps } from "../../../types/DetailPageHeaderProps";
import PDFReport from "../../PDFReport/PDFReport";
import { Link } from "react-router-dom";
import Button from "../../Functionality/Button";

function DetailPageHeader({
  trend,
  loading,
  randomAuthorId,
}: DetailPageHeaderProps) {
  const getHeaderText = () => {
    if (loading)
      return (
        <Skeleton containerTestId="loadingskeleton" containerClassName="block text-base max-w-md" count={2} />
      );
    if (!trend) {
      // two invisible lines so header doesnt jump
      return (
        <p>
          &nbsp;
          <br />
          &nbsp;
        </p>
      );
    }

    return (
      <p className="text-base">
        Trending Topic of the Day in {trend.countries.join(", ")}
        <br />
        Completed Analysis on {trend.updated_at}
        <br />
      </p>
    );
  };

  const getHeaderContent = (trendName: string) => {
    return (
      <>
        <h1 className={"text-lg md:text-xl"}>{trendName}</h1>
        <div className="mt-4">
          <Link
            to={`${encodeURIComponent(randomAuthorId)}`}
            className="mb-4 sm:mb-0 mr-0 sm:mr-4 inline-block w-full sm:w-auto"
            data-testid="randomAuthorLink"
          >
            <Button onClick={() => {}} inverted={false}>
              <span className="uppercase">Vote on author</span>
            </Button>
          </Link>
          <PDFReport trendStatistics={trend} />
        </div>
      </>
    );
  };

  return (
    <div className="bg-blue text-white pt-20 md:pt-32 pb-8 px-4 md:px-6 flex">
      <WorldSvg countriesToFill={trend?.countries} />
      <div className="text-left w-full">
        {getHeaderText()}
        <div className="text-left max-w-4xl capitalize mt-4 break-words">
          {loading ? (
            <>
              <Skeleton containerClassName="block text-xl max-w-lg" />
              <Skeleton containerClassName="block text-base max-w-lg" />
            </>
          ) : (
            <>
              {trend ? (
                getHeaderContent(trend.trend_name)
              ) : (
                <span>Trend not Found</span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailPageHeader;

import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import ScrollDragContainer from "../Functionality/ScrollDragContainer";
import Tweet from "../TrendDetailPage/TweetGrid/Tweet";
import { differenceInCalendarDays, formatDistance } from "date-fns";
import StatisticsTable from "../Functionality/StatisticsTable";
import BarChart from "./BarChart/BarChart";
import { Statistic } from "../../types/Statistic";
import Separator from "../Functionality/Separator";
import Infobox from "../Functionality/Infobox";
import AuthorDetailPageHeader from "./Header/AuthorDetailPageHeader";
import { useAuthorAndAuthorTweetsFetch } from "../../hooks/customHooks";
import { formatNumber } from "../../utils/formatNumber";
import NotAvailable from "../Functionality/NotAvailable";
import VotingArea from "./Voting/VotingArea";

function AuthorDetailPage() {
  const { trendName, authorId } = useParams();
  const [votedOnAuthor, setVotedOnAuthor] = useState(
    localStorage.getItem(`${authorId}_voted`) || "false"
  );

  const { authorTweets, authorDetails, loading, error } =
    useAuthorAndAuthorTweetsFetch(authorId || "");

  const barChartData = authorDetails?.features?.map((feature) => ({
    label: feature.feature_name,
    value: feature.weight,
  }));

  const submitUserVote = (isBotVote: boolean) => {
    fetch(`${API_URL}/authors/${authorId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ vote: isBotVote }),
    }).then((res) => {
      if (res.status === 200) {
        setVotedOnAuthor("true");
        localStorage.setItem(`${authorId}_voted`, "true");
      }
    });
  };

  const getReadableAccountAge = (createdAt: string | undefined) => {
    if (createdAt === undefined) return 0;

    return formatDistance(new Date(createdAt), new Date());
  };

  const calculateTweetsPerDay = (
    tweetCount: number | undefined,
    accountDate: string | undefined
  ) => {
    if (tweetCount === undefined || accountDate === undefined) return 0;
    const result =
      tweetCount / differenceInCalendarDays(new Date(), new Date(accountDate));
    return result.toFixed(2);
  };

  const getAlreadyVoted = (): boolean => {
    return (
      localStorage.getItem(`${authorId}_voted`) === "true" ||
      votedOnAuthor === "true"
    );
  };

  const statistics: Statistic[] = [
    {
      label: "Description",
      value: authorDetails?.description,
    },
    {
      label: "Account Age",
      value: getReadableAccountAge(authorDetails?.created_at),
    },
    {
      label: "Total Tweets",
      value: formatNumber(authorDetails?.tweet_count),
    },
    {
      label: "Tweets per Day",
      value: formatNumber(
        calculateTweetsPerDay(
          authorDetails?.tweet_count,
          authorDetails?.created_at
        )
      ),
    },
    {
      label: "Followers",
      value: formatNumber(authorDetails?.follower_count),
    },
    {
      label: "Following",
      value: formatNumber(authorDetails?.following_count),
    },
  ];

  const getAuthorDetailPageContent = () => {
    if (error || !trendName || !authorId)
      return (
        <NotAvailable notice={"We couldn't find the author for this trend."} />
      );

    return (
      <div className="bg-white text-left">
        <Separator primaryLabel={"Recent Tweets"} />
        <div className="px-4 md:px-6">
          <ScrollDragContainer>
            {authorTweets &&
              authorTweets.map((tweet, index) => (
                <Tweet key={index} tweet={tweet} gridView={false} />
              ))}
          </ScrollDragContainer>
          <Infobox>
            Above are the most recent tweets by the author tweeting to the trend
            "{trendName}". <strong>[mention]</strong> and <strong>[url]</strong>{" "}
            are placeholders for the mentioned users and URLs in the tweet,
            which had to be removed.
          </Infobox>
        </div>
        <Separator primaryLabel={"General Information"} />
        <div className="-mt-[1px]">
          <StatisticsTable statistics={statistics} />
        </div>
        <Separator primaryLabel="Feature Importance">
          <Link
            className="md:hidden text-blue underline text-right"
            to={"/about"}
          >
            Find out more about the Features
          </Link>
        </Separator>
        <div className="w-full">
          <div className="justify-center mb-4">
            <BarChart
              initialWidth={window.innerWidth}
              initialHeight={700}
              data={barChartData}
            />
          </div>
          <div className="px-4 md:px-6">
            <Infobox>
              Each of these features above has an impact on the models decision
              to classify the user. The blue bars indicate the{" "}
              <strong>weight of the feature</strong> to classify this user as a{" "}
              <strong className="text-white px-1 font-medium bg-blue">
                bot
              </strong>
              . The <span>orange</span> bars indicate the weight to classify
              this user as a{" "}
              <strong className="text-white px-1 font-medium bg-orange">
                human
              </strong>
              .
            </Infobox>
          </div>
        </div>
        <Separator
          primaryLabel={
            getAlreadyVoted()
              ? "Thank you for your vote!"
              : "Your turn! Do you think this is a bot account or not? \n" +
                "If enough people agree, we'll use this information to enhance our model."
          }
        />
        <VotingArea
          alreadyVoted={getAlreadyVoted()}
          onVote={submitUserVote}
          lastAuthorId={authorId}
          trendName={trendName}
          onNavigate={() => setVotedOnAuthor("false")}
        />
      </div>
    );
  };

  return (
    <div>
      <AuthorDetailPageHeader
        trendName={trendName}
        authorDetails={authorDetails}
        loading={loading}
      />
      {getAuthorDetailPageContent()}
    </div>
  );
}

export default AuthorDetailPage;

import React, {Fragment} from "react";
import {prepAboutPageBannerInput} from "../Functionality/PrepTrendBannerInput";

function AboutPageBanner({name}: {name: string}) {
    const bannerElements = prepAboutPageBannerInput(name, 12);

    return (
        <Fragment>
            <div className="overflow-x-hidden relative border-b border-black text-6xl md:text-8xl">
                <div className="py-6 px-6 inline-flex fill-blue gap-6 md:gap-10 items-center whitespace-nowrap animate-marquee-running md:animate-none">
                    {bannerElements.map((bannerContent) => bannerContent)}
                </div>
            </div>
        </Fragment>
    );
}

export default AboutPageBanner;

import React from "react";
import CalendarIcon from "./CalendarIcon";
import {formatDate} from "../../../utils/formatDate";
import {DatePickerProps} from "../../../types/DatePickerProps";

function DatePicker({ min, max, dateQuery, handleChange } : DatePickerProps) {
    return (
        <>
            <label htmlFor="datepicker" className="w-full block invisible h-0">
                Pick a date:
            </label>
            <div className="flex justify-between items-center relative h-full border-b-[1px]">
                <input
                    className={`focus-visible:outline-none w-full text-xl lg:text-4xl 2xl:text-6xl 
                    py-4 pl-2 z-10 bg-transparent cursor-pointer 
                    ${dateQuery === "" ? "text-gray-300" : "text-black"}`}
                    type="date"
                    id="datepicker"
                    name="datepicker"
                    pattern="\d{2}/\d{2}/\d{4}"
                    min={formatDate(min)}
                    max={formatDate(max)}
                    value={dateQuery || ""}
                    onInput={event => handleChange((event.target as HTMLInputElement).value)}
                />
                <div className="fill-blue px-4">
                    <CalendarIcon />
                </div>
            </div>
        </>
    )
}

export default DatePicker;
import Accordion from "./Accordion";
import React, {useEffect, useState} from "react";
import {AccordionListProps} from "../../../types/AccordionListProps";
import Button from "../../Functionality/Button";

function AccordionList({historyItems}: AccordionListProps) {
    const itemsPerPage = 10;

    const [activeIndex, setActiveIndex] = useState<number>(-1);
    const [numberOfItemsRendered, setNumberOfItemsRendered] = useState<number>(itemsPerPage);

    useEffect(() => {
        if(historyItems.length < itemsPerPage) setNumberOfItemsRendered(historyItems.length)
        else setNumberOfItemsRendered(itemsPerPage)
    }, [historyItems, itemsPerPage])

    const handleShowMoreItems = () => {
        if(numberOfItemsRendered + itemsPerPage > historyItems.length ) setNumberOfItemsRendered(historyItems.length)
        else setNumberOfItemsRendered(numberOfItemsRendered + itemsPerPage);
    };

    return (
        <>
            <ul className="mb-8">
                {historyItems.length === 0 ? (
                    <p>No trends found.</p>
                ) : (
                    historyItems.slice(0, numberOfItemsRendered).map(history => (
                        <li key={history.trendName} data-cy="accordion-list-item">
                            <Accordion
                                trendName={history.trendName}
                                trendHistory={history.histories}
                                activeIndex={activeIndex} setActiveIndex={setActiveIndex}
                            />
                        </li>
                    ))
                )}
            </ul>
            {historyItems.length > 0 &&
                <div data-cy="load-more-area">
                    <p className="text-base mb-2" data-cy="number-of-elements-rendered">{numberOfItemsRendered} of {historyItems.length} {historyItems.length === 1 ? "trend" : "trends"}</p>
                    {numberOfItemsRendered < historyItems.length &&
                        <Button onClick={handleShowMoreItems} inverted={false}>
                            <span className="uppercase">Load more</span>
                        </Button>
                    }
                </div>
            }

        </>
    )
}

export default AccordionList;
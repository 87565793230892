import React, {useCallback, useEffect, useRef} from "react";
import {bisector, pointer, ScaleOrdinal, select, selectAll, timeFormat} from "d3";
import {LineChartAxisBottomProps} from "../../../types/LineChartAxisBottomProps";
import {LineChartAxisLeftProps} from "../../../types/LineChartAxisLeftProps";

type TooltipData = {
    trendName: string;
    trendedOn: Date;
    botResult: number;
}

type TooltipProps = {
    width: number;
    height: number;
    margin: {top: number, right: number, bottom: number, left: number}
    transform: string;
    data: {name: string, values: TooltipData[]}[];
    scaleX: LineChartAxisBottomProps["scale"];
    scaleY: LineChartAxisLeftProps["scale"];
    scaleColor: ScaleOrdinal<string, unknown>;
}

function Tooltip({ width, height, margin, transform, data, scaleX, scaleY, scaleColor }: TooltipProps) {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipAreaRef = useRef<SVGGElement>(null);
    const mobileBreakpoint = 450; //400px = custom breakpoint

    const mouseOut = () => { // on mouse out hide line, circles and text
        select(".mouse-line")
            .style("opacity", "0");
        selectAll(".mouse-per-line circle")
            .style("opacity", "0");
        selectAll(".mouse-per-line text")
            .style("opacity", "0");
        selectAll("#tooltip")
            .style("display", "none")
    }

    const mouseOver = () => { // on mouse in show line, circles and text
        select(".mouse-line")
            .style("opacity", "1");
        selectAll(".mouse-per-line circle")
            .style("opacity", "1");
        selectAll("#tooltip")
            .style("display", "block")
    }

    const mouseMove = useCallback((event: MouseEvent) => { // update tooltip content, line, circles and text when mouse moves
        const mouse = pointer(event)

        // get the closest data point to hovered position
        const getClosestDataPoint = (mouseX: number, d: { name: string, values: TooltipData[] }) => {
            const xDate = scaleX.invert(mouseX) // use 'invert' to get date corresponding to distance from mouse position relative to svg
            const bisect = bisector((d: TooltipData) => d.trendedOn).left // retrieve row index of date
            const idx = bisect(d.values, xDate);
            const rightData = d.values[idx];
            if(rightData === undefined) return d.values[idx - 1]
            if(idx !== 0) {
                const leftData = d.values[idx - 1];
                return xDate.getTime() - leftData.trendedOn.getTime() > rightData.trendedOn.getTime() - xDate.getTime()
                    ? rightData
                    : leftData;
            } else {
                return rightData;
            }
        }

        selectAll(".mouse-per-line")
            // @ts-ignore
            .attr("transform", (d: { name: string, values: TooltipData[] }) => {
                const closestDataPoint = getClosestDataPoint(mouse[0], d)
                select(".mouse-line")
                    .attr("x1", scaleX(closestDataPoint.trendedOn))
                    .attr("x2", scaleX(closestDataPoint.trendedOn))
                return "translate(" + scaleX(closestDataPoint.trendedOn) + "," + scaleY(closestDataPoint.botResult) + ")";
            });

        const tooltipData: Array<{ key: string, trendName: string, botResult: number, trendedOn: Date }> = []
        data.forEach(d => {
            const closestDataPoint = getClosestDataPoint(mouse[0], d)
            tooltipData.push({
                key: d.name,
                trendName: closestDataPoint.trendName,
                botResult: closestDataPoint.botResult,
                trendedOn: new Date(closestDataPoint.trendedOn)
            })
        })

        if(tooltipData.length > 0) {
            select(tooltipRef.current)
                .html(timeFormat("%a, %b %d %Y")(tooltipData[0].trendedOn))
                .style("display", "block")
                .style("text-align", "left")
                .style("font-size", "1rem")
                .style("min-width", "200px")
                .style("position", "absolute")
                .style("left",
                    width < mobileBreakpoint //fix tooltip position on small screens
                        ? margin.left + "px"
                        : event.pageX > (width / 2)
                            ? event.pageX - 200 + "px"
                            : event.pageX + 10 + "px")

            // check if day has at least 1 entry
            if(tooltipData.filter((d) => d.botResult !== -1).length > 0) {
                select(tooltipRef.current)
                    .selectAll()
                    .data(data).enter() // for each country, list out trend name and bot result
                    .append("p")
                    .style("color", d => scaleColor(d.name) as string)
                    .style("font-size", "1rem")
                    .style("line-height", "1.75rem")
                    .style("display", "flex")
                    .style("justify-content", "space-between")
                    .html((d: { name: string, values: TooltipData[] }) => {
                        const closestDataPoint = getClosestDataPoint(mouse[0], d)
                        if(closestDataPoint.botResult !== -1) {
                            return `
                          <span style="margin-right: 0.5rem;">
                            ${closestDataPoint.trendName}:
                          </span>
                          <span>
                            ${Math.round(closestDataPoint.botResult * 100)}%
                          </span>
                        `
                        }
                        return ""
                    })
            } else {
                select(tooltipRef.current)
                    .append("p")
                    .style("font-size", "1rem")
                    .style("line-height", "1.75rem")
                    .html("<span>No data for this day</span>")
            }
        }
    }, [data, scaleX, scaleY, scaleColor, width, margin.left]);

    useEffect(() => {
        if(tooltipRef.current && tooltipAreaRef.current) {
            // CLEAR PREVIOUS CONTENT
            const everything = select(tooltipAreaRef.current).selectAll("*");
            everything.remove();

            select(tooltipRef.current)
                .attr("id", "tooltip")

            select(tooltipAreaRef.current)
                .attr("class", "mouse-over-effects")
                .append("line") // create vertical line to follow mouse
                .attr("class", "mouse-line")
                .attr("y1", 0)
                .attr("y2", height)
                .style("stroke", "black")
                .style("opacity", "0");

            const mousePerLine = select(tooltipAreaRef.current).selectAll(".mouse-per-line")
                .data(data)
                .enter()
                .append("g")
                .attr("class", "mouse-per-line");
            mousePerLine.append("circle")
                .attr("r", 5)
                .style("stroke", d => scaleColor(d.name) as string)
                .style("fill", "none")
                .style("stroke-width", 2)
                .style("opacity", "0");

            select(tooltipAreaRef.current).append("svg:rect") // append a rect to catch mouse movements on canvas
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "none")
                .attr("pointer-events", "all")
                .on("mouseout", mouseOut)
                .on("mouseover", mouseOver)
                .on("mousemove", mouseMove)
        }

    }, [width, height, margin, data, scaleColor, mouseMove])

    return (
        <>
            <svg
                className="absolute top-0"
                width={width}
                height={height + margin.top + margin.bottom}
            >
                <g ref={tooltipAreaRef} transform={transform} />
            </svg>
            <div className="w-full h-[8.25rem] border-y border-black mt-4 py-3">
                <div ref={tooltipRef} />
            </div>

        </>
    );
}

export default Tooltip;


import React from "react";
import { FilterButtonProps } from "../../../types/FilterButtonProps";

function FilterButton({ active, onClick, children }: FilterButtonProps) {
  let styles = "text-blue bg-white border-blue";
  if (active) styles = "text-white bg-blue border-white";
  return (
    <button
      onClick={onClick}
      className={`inline-flex justify-center items-center text-center border px-4 rounded-full sm:mb-0 mr-4 sm:last:mr-0 ${styles}`}
    >
      {children}
    </button>
  );
}

export default FilterButton;

import React from "react";

function ArrowIcon({rotation, color}: {rotation: number, color: string}) {

    return (
        <svg className="inline" xmlns="http://www.w3.org/2000/svg" width="65.994" height="37.722" viewBox="0 0 65.994 37.722" transform={`rotate(${rotation})`}>
            <g id="Group_928" transform="translate(-3003.24 1396.394) rotate(-90)" fill={color} stroke={color}>
                <line id="Line_67" y2="62.428" transform="translate(1377.533 3003.24)" fill="none" strokeWidth="4"/>
                <g id="Group_825" transform="translate(1361.5 3050.374)">
                    <line id="Line_119" x1="16.033" y1="16.033" fill="none" strokeLinecap="square" strokeWidth="4"/>
                    <line id="Line_120" y1="16.033" x2="16.033" transform="translate(16.033)" fill="none" strokeLinecap="square" strokeWidth="4"/>
                </g>
            </g>
        </svg>
    );
}

export default ArrowIcon;
import {axisLeft, format, select} from "d3";
import { useEffect, useRef } from "react";
import {LineChartAxisLeftProps} from "../../../types/LineChartAxisLeftProps";

function AxisLeft({ scale, maxY, width }: LineChartAxisLeftProps) {
    const ref = useRef<SVGGElement>(null);

    useEffect(() => {
        if (ref.current) {
            select(ref.current)
                .attr("text-anchor", "end")
                .style("font-size", "1rem")
                .call(axisLeft(scale)
                    .tickSize(-width)
                    .tickPadding(-40)
                    .ticks(maxY / 0.2)
                    .tickFormat(format("~%")));

            select(ref.current)
                .selectAll("text")
                .attr("y", "-10")
                .attr("x", "50")
                .style("color", "black")
        }
    }, [scale, maxY, width]);

    return <g ref={ref} />;
}

export default AxisLeft;
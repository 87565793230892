import React, { Fragment } from "react";
import GermanyIcon from "./Icons/GermanyIcon";
import UKIcon from "./Icons/UKIcon";
import USAIcon from "./Icons/USAIcon";
import BotEyes from "../AboutPage/BotEyes";

function prepTrendBannerInput(
  trendName: string,
  countries: Array<string>,
  numberOfElements: number
) {
  const bannerElements = Array(numberOfElements).fill(null);
  return bannerElements.map((el, index) => {
    const countryName = countries[index % countries.length]
      .toLowerCase()
      .replaceAll(" ", "");

    let countryIcon;
    switch (countryName) {
      case "germany":
        countryIcon = <GermanyIcon />;
        break;
      case "unitedkingdom" || "united kingdom":
        countryIcon = <UKIcon />;
        break;
      case "usa":
        countryIcon = <USAIcon />;
        break;
      default:
        countryIcon = <GermanyIcon />;
        break;
    }

    return (
      <Fragment key={index}>
        <span className="inline-flex items-center">
          <span className="mr-8 md:mr-16 text-6xl md:text-8xl">
            {trendName.charAt(0).toUpperCase() + trendName.slice(1)}
          </span>
          <span className="mr-8 md:mr-16 h-20 md:h-28">{countryIcon}</span>
        </span>
      </Fragment>
    );
  });
}

const prepAboutPageBannerInput = (name: string, numberOfElements: number) => {
  const bannerElements = Array(numberOfElements).fill(null);
  return bannerElements.map((_, index) => {
    return (
      <React.Fragment key={index}>
        <span>{name}</span>
        <BotEyes lookingDirection={"tl"} lookingAround={true} />
      </React.Fragment>
    );
  });
};

export { prepTrendBannerInput, prepAboutPageBannerInput };
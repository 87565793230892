import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HeaderProps } from "../../types/HeaderProps";
import { routerBasePath } from "../../constants";
import IconButton from "../Functionality/IconButton";

function Header({ navItems, toggleOverlay }: HeaderProps) {
  const handleMenuClick = () => {
    toggleOverlay();
  };
  const location = useLocation();
  let showPolicyBanner = false;
  if (location.pathname === "/") {
    showPolicyBanner = true;
  }

  return (
    <>
      {showPolicyBanner && (
        <p className={"text-blue p-1"}>
          Due to recent changes in Twitter's API policy , which introduced
          monetarization, we are not able to provide daily trend analyses at
          this moment.
          <Link className="underline" to={"policy-changes"}>
            {" "}
            Click for more information
          </Link>
        </p>
      )}

      <header className="grid grid-cols-2 lg:grid-cols-[auto_1fr] justify-items-center p-4 pt-8 md:p-6 border bg-blue border-0 text-white">
        <Link
          to={"/"}
          className="col-span-1 flex items-center justify-self-start lg:justify-self-center"
        >
          <img
            className="h-6"
            src={`${routerBasePath}/img/botwatch_logo.svg`}
            alt="spot a bot logo"
          />
        </Link>
        <nav className="items-center justify-self-end hidden lg:flex">
          <ul>
            {navItems.map(({ to, text }) => (
              <li key={text} className="inline px-4 text-base">
                {
                  <Link to={to} className="underline-link">
                    {text}
                  </Link>
                }
              </li>
            ))}
          </ul>
          <div className="lg:flex items-center px-2 text-md justify-self-end hidden lg:block">
            <a
              target="_blank"
              href="https://twitter.com/SpotABot_"
              rel="noreferrer"
            >
              <IconButton
                iconPath={`${routerBasePath}/img/tweet.svg`}
                iconAltText={"Twitter logo"}
                onClick={() => {}}
                label={"Get in touch"}
              />
            </a>
          </div>
        </nav>

        <button
          className="lg:hidden justify-self-end px-2 text-base"
          onClick={handleMenuClick}
        >
          <span>Menu</span>
        </button>
      </header>
    </>
  );
}

export default Header;

import React from "react";

interface HalfWidthColumnProps {
    inverted?: boolean;
    title: string;
    children: React.ReactNode;
}

function HalfWidthColumn({ inverted, children, title }: HalfWidthColumnProps) {
  return (
    <div
      className={`w-full md:w-1/2 ${
        inverted ? "bg-blue text-white last:border-b border-b-white" : ""
      }`}
    >
      <h3
        className={`${!title ? "hidden" : ""} text-md py-2 border-b ${
          inverted ? "border-b-white" : "border-b-black"
        } px-4 md:px-6`}
      >
        {title}
      </h3>
      {children}
    </div>
  );
}

export default HalfWidthColumn;
import React from "react";

function ModelDiagram() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1027 861">
            <g id="Group_945" data-name="Group 945" transform="translate(-855 -4730.059)">
                <g id="Group_651" data-name="Group 651" transform="translate(-64 -330.715)">
                    <text transform="translate(1075.659 5151.742)" fill="#1266f9" fontSize="14" fontFamily="General Sans Variable"><tspan x="-17.53" y="0">XXYY</tspan></text>
                    <text transform="translate(1149.358 5364.258)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-8.045" y="0">YY</tspan></text>
                    <line id="Line_79" data-name="Line 79" x1="38.242" transform="translate(1150.296 5300.242) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <g id="Group_643" data-name="Group 643" transform="translate(968.359 5236.176)">
                        <g id="Rectangle_818" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#1266f9" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="X" transform="translate(36.139 30.647)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.162" y="0">X</tspan></text>
                    </g>
                    <g id="Group_650" data-name="Group 650" transform="translate(1113.219 5236.176)">
                        <g id="Rectangle_818-2" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#1266f9" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="Y" transform="translate(37.139 30.647)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.023" y="0">Y</tspan></text>
                    </g>
                    <text id="XX" transform="translate(1004.498 5365.258)" fill="#1266f9" fontSize="14" fontFamily="General Sans Variable"><tspan x="-8.866" y="0">XX</tspan></text>
                    <line id="Line_80" data-name="Line 80" x1="38.242" transform="translate(1005.213 5300.242) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <line id="Line_82" data-name="Line 82" x2="39.707" y2="57.245" transform="translate(1005.87 5215.313) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <line id="Line_84" data-name="Line 84" y1="57.245" x2="39.707" transform="translate(1092.113 5215.313) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <g id="Rectangle_1346" data-name="Rectangle 1346" transform="translate(919 5110)" fill="none" stroke="#1266f9" strokeWidth="1">
                        <rect width="313" height="286" rx="19" stroke="none"/>
                        <rect x="0.5" y="0.5" width="312" height="285" rx="18.5" fill="none"/>
                    </g>
                </g>
                <g id="Group_652" data-name="Group 652" transform="translate(293 -330.715)">
                    <text id="AABB" transform="translate(1075.659 5151.742)" fill="#1266f9" fontSize="14" fontFamily="General Sans Variable"><tspan x="-18.43" y="0">AABB</tspan></text>
                    <text id="BB" transform="translate(1149.358 5364.258)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-8.111" y="0">BB</tspan></text>
                    <line id="Line_79-2" data-name="Line 79" x1="38.242" transform="translate(1150.296 5300.242) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <g id="Group_643-2" data-name="Group 643" transform="translate(968.359 5236.176)">
                        <g id="Rectangle_818-3" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#1266f9" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="A" transform="translate(36.139 30.647)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.501" y="0">A</tspan></text>
                    </g>
                    <g id="Group_650-2" data-name="Group 650" transform="translate(1113.219 5236.176)">
                        <g id="Rectangle_818-4" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#1266f9" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="B" transform="translate(37.139 30.647)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.056" y="0">B</tspan></text>
                    </g>
                    <text id="AA" transform="translate(1004.498 5365.258)" fill="#1266f9" fontSize="14" fontFamily="General Sans Variable"><tspan x="-9.695" y="0">AA</tspan></text>
                    <line id="Line_80-2" data-name="Line 80" x1="38.242" transform="translate(1005.213 5300.242) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <line id="Line_82-2" data-name="Line 82" x2="39.707" y2="57.245" transform="translate(1005.87 5215.313) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <line id="Line_84-2" data-name="Line 84" y1="57.245" x2="39.707" transform="translate(1092.113 5215.313) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <g id="Rectangle_1346-2" data-name="Rectangle 1346" transform="translate(919 5110)" fill="none" stroke="#1266f9" strokeWidth="1">
                        <rect width="313" height="286" rx="19" stroke="none"/>
                        <rect x="0.5" y="0.5" width="312" height="285" rx="18.5" fill="none"/>
                    </g>
                </g>
                <rect id="Rectangle_1346-3" data-name="Rectangle 1346" width="1027" height="286" rx="19" transform="translate(855 5244.285)" fill="#1266f9"/>
                <text id="XXYYAABBDDFF" transform="translate(1368.66 5286.027)" fill="#fff" fontSize="14" fontFamily="General Sans Variable"><tspan x="-52.152" y="0">XXYYAABBDDFF</tspan></text>
                <g id="Group_661" data-name="Group 661" transform="translate(20.143 -366.715)">
                    <text id="FF" transform="translate(1442.358 5865.258)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-6.801" y="0">FF</tspan></text>
                    <line id="Line_79-3" data-name="Line 79" x1="38.242" transform="translate(1443.296 5801.242) rotate(90)" fill="none" stroke="#fff" strokeWidth="1"/>
                    <g id="Group_650-3" data-name="Group 650" transform="translate(1406.219 5737.176)">
                        <g id="Rectangle_818-5" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#fff" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="F" transform="translate(37.139 30.647)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-3.4" y="0">F</tspan></text>
                    </g>
                </g>
                <g id="Group_658" data-name="Group 658" transform="translate(-25.361 -366.715)">
                    <text id="BB-2" data-name="BB" transform="translate(1131.862 5865.258)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-8.111" y="0">BB</tspan></text>
                    <line id="Line_89" data-name="Line 89" x1="38.242" transform="translate(1132.8 5801.242) rotate(90)" fill="none" stroke="#fff" strokeWidth="1"/>
                    <g id="Group_655" data-name="Group 655" transform="translate(1095.723 5737.176)">
                        <g id="Rectangle_818-6" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#fff" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="B-2" data-name="B" transform="translate(37.139 30.647)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.056" y="0">B</tspan></text>
                    </g>
                </g>
                <g id="Group_663" data-name="Group 663" transform="translate(86.199 -366.715)">
                    <text id="YY-2" data-name="YY" transform="translate(1732.301 5865.258)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-8.045" y="0">YY</tspan></text>
                    <line id="Line_91" data-name="Line 91" x1="38.242" transform="translate(1733.238 5801.242) rotate(90)" fill="none" stroke="#fff" strokeWidth="1"/>
                    <g id="Group_657" data-name="Group 657" transform="translate(1696.161 5737.176)">
                        <g id="Rectangle_818-7" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#fff" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="YY-3" data-name="YY" transform="translate(37.139 30.647)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-8.045" y="0">YY</tspan></text>
                    </g>
                </g>
                <g id="Group_660" data-name="Group 660" transform="translate(-12.998 -366.715)">
                    <g id="Group_643-3" data-name="Group 643" transform="translate(1261.359 5737.176)">
                        <g id="Rectangle_818-8" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#fff" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="D" transform="translate(36.139 30.647)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.434" y="0">D</tspan></text>
                    </g>
                    <text id="DD" transform="translate(1297.498 5866.258)" fill="#fff" fontSize="14" fontFamily="General Sans Variable"><tspan x="-9.55" y="0">DD</tspan></text>
                    <line id="Line_80-3" data-name="Line 80" x1="38.242" transform="translate(1298.213 5801.242) rotate(90)" fill="none" stroke="#fff" strokeWidth="1"/>
                </g>
                <g id="Group_659" data-name="Group 659" transform="translate(-58.502 -366.715)">
                    <g id="Group_654" data-name="Group 654" transform="translate(950.863 5737.176)">
                        <g id="Rectangle_818-9" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#fff" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="A-2" data-name="A" transform="translate(36.139 30.647)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.501" y="0">A</tspan></text>
                    </g>
                    <text id="AA-2" data-name="AA" transform="translate(987.002 5866.258)" fill="#fff" fontSize="14" fontFamily="General Sans Variable"><tspan x="-9.695" y="0">AA</tspan></text>
                    <line id="Line_88" data-name="Line 88" x1="38.242" transform="translate(987.717 5801.242) rotate(90)" fill="none" stroke="#fff" strokeWidth="1"/>
                </g>
                <g id="Group_662" data-name="Group 662" transform="translate(53.059 -366.715)">
                    <g id="Group_656" data-name="Group 656" transform="translate(1551.302 5737.176)">
                        <g id="Rectangle_818-10" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#fff" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="XX-2" data-name="XX" transform="translate(36.139 30.647)" fill="#fff" fontSize="13" fontFamily="General Sans Variable"><tspan x="-8.232" y="0">XX</tspan></text>
                    </g>
                    <text id="XX-3" data-name="XX" transform="translate(1587.44 5866.258)" fill="#fff" fontSize="14" fontFamily="General Sans Variable"><tspan x="-8.866" y="0">XX</tspan></text>
                    <line id="Line_90" data-name="Line 90" x1="38.242" transform="translate(1588.155 5801.242) rotate(90)" fill="none" stroke="#fff" strokeWidth="1"/>
                </g>
                <line id="Line_82-3" data-name="Line 82" x2="40.426" y2="58.925" transform="translate(1309.734 5349.594) rotate(-90)" fill="none" stroke="#fff" strokeWidth="1"/>
                <g id="Group_653" data-name="Group 653" transform="translate(650 -330.715)">
                    <text id="DDFF" transform="translate(1075.659 5151.742)" fill="#1266f9" fontSize="14" fontFamily="General Sans Variable"><tspan x="-16.874" y="0">DDFF</tspan></text>
                    <text id="FF-2" data-name="FF" transform="translate(1149.358 5364.258)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-6.801" y="0">FF</tspan></text>
                    <line id="Line_79-4" data-name="Line 79" x1="38.242" transform="translate(1150.296 5300.242) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <g id="Group_643-4" data-name="Group 643" transform="translate(968.359 5236.176)">
                        <g id="Rectangle_818-11" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#1266f9" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="D-2" data-name="D" transform="translate(36.139 30.647)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-4.434" y="0">D</tspan></text>
                    </g>
                    <g id="Group_650-4" data-name="Group 650" transform="translate(1113.219 5236.176)">
                        <g id="Rectangle_818-12" data-name="Rectangle 818" transform="translate(0)" fill="none" stroke="#1266f9" strokeWidth="1">
                            <rect width="72.278" height="51.294" rx="19" stroke="none"/>
                            <rect x="0.5" y="0.5" width="71.278" height="50.294" rx="18.5" fill="none"/>
                        </g>
                        <text id="F-2" data-name="F" transform="translate(37.139 30.647)" fill="#1266f9" fontSize="13" fontFamily="General Sans Variable"><tspan x="-3.4" y="0">F</tspan></text>
                    </g>
                    <text id="DD-2" data-name="DD" transform="translate(1004.498 5365.258)" fill="#1266f9" fontSize="14" fontFamily="General Sans Variable"><tspan x="-9.55" y="0">DD</tspan></text>
                    <line id="Line_80-4" data-name="Line 80" x1="38.242" transform="translate(1005.213 5300.242) rotate(90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <line id="Line_82-4" data-name="Line 82" x2="39.707" y2="57.245" transform="translate(1005.87 5215.313) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <line id="Line_84-3" data-name="Line 84" y1="57.245" x2="39.707" transform="translate(1092.113 5215.313) rotate(-90)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                    <g id="Rectangle_1346-4" data-name="Rectangle 1346" transform="translate(919 5110)" fill="none" stroke="#1266f9" strokeWidth="1">
                        <rect width="313" height="286" rx="19" stroke="none"/>
                        <rect x="0.5" y="0.5" width="312" height="285" rx="18.5" fill="none"/>
                    </g>
                </g>
                <path id="Path_237" data-name="Path 237" d="M275,0,0,99.5" transform="translate(1450.5 5095.285)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <path id="Path_238" data-name="Path 238" d="M0,0,275,99.5" transform="translate(1011.5 5095.285)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <line id="Line_87" data-name="Line 87" y1="69.273" transform="translate(1368.5 5095.285)" fill="none" stroke="#1266f9" strokeWidth="1"/>
                <line id="Line_92" data-name="Line 92" y1="40.617" x2="256.938" transform="translate(1111.723 5309.168)" fill="none" stroke="#fff" strokeWidth="1"/>
                <line id="Line_93" data-name="Line 93" y1="40.617" x2="440.159" transform="translate(928.5 5309.168)" fill="none" stroke="#fff" strokeWidth="1"/>
                <g id="Group_664" data-name="Group 664" transform="translate(1367.92 5309.168)">
                    <line id="Line_94" data-name="Line 94" y1="58.925" x2="40.426" transform="translate(0 40.426) rotate(-90)" fill="none" stroke="#fff" strokeWidth="1"/>
                    <line id="Line_95" data-name="Line 95" x1="256.938" y1="40.617" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="1"/>
                    <line id="Line_96" data-name="Line 96" x1="440.159" y1="40.617" transform="translate(0 0)" fill="none" stroke="#fff" strokeWidth="1"/>
                </g>
                <text id="XGBoost" transform="translate(1219 5188.059)" fill="#0d66fb" fontSize="20" fontFamily="General Sans Variable"><tspan x="109.368" y="20">XGBoost</tspan></text>
                <text id="_basically_combined_single_trees_to_get_a_more_accurate_result_" data-name="(basically combined single trees to get a more accurate result)" transform="translate(855 5552.059)" fill="#0d66fb" fontSize="15" fontFamily="General Sans Variable"><tspan x="304.689" y="15">(basically combined single trees to get a more accurate result)</tspan></text>
                <text id="regular_single_decision_trees" data-name="regular, single decision trees" transform="translate(855 4730.059)" fill="#0d66fb" fontSize="20" fontFamily="General Sans Variable"><tspan x="385.77" y="20">regular, single decision trees</tspan></text>
            </g>
        </svg>

    );
}

export default ModelDiagram;
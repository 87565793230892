import { axisLeft, ScaleBand, ScaleLinear, select } from "d3";
import {useCallback, useEffect, useRef} from "react";
import { ChartData } from "../../../types/ChartData";

interface AxisLeftProps {
  scaleX: ScaleLinear<number, number>;
  scaleY: ScaleBand<string>;
  data: ChartData[];
  width: number;
}

function BarChartAxisLeft({ scaleX, scaleY, data, width }: AxisLeftProps) {
  const ref = useRef<SVGGElement>(null);
  const padding = 16;
  const smallerFontBreakpoint = 768; //768px = lg breakpoint from tailwind

  const isNegativeFeature = useCallback((label: string | unknown): boolean => {
    const feature = data.find((dataPoint: any) => dataPoint.label === label);
    return feature && typeof label === "string" ? feature.value < 0 : false;
  }, [data]);

  const getXPositionForFeature = useCallback( (label: string | unknown): number => {
    const feature = data.find((dataPoint: any) => dataPoint.label === label);
    if (!feature || typeof label !== "string") return 0;
    return isNegativeFeature(label)
      ? scaleX(0) - padding
      : scaleX(Math.min(0, feature.value)) + padding;
  }, [data, isNegativeFeature, scaleX]);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      select(currentRef).call(axisLeft(scaleY).tickSizeOuter(0));

      select(currentRef)
        .selectAll("text")
        .style("font-size", "2rem")
        .style("pointer-events", "none")
        .attr("fill", "black")
        .attr("y", "0")
        .attr("x", (label) => getXPositionForFeature(label))
        .attr("text-anchor", (label) =>
          isNegativeFeature(label) ? "end" : "start"
        );

      if (width + 15 < smallerFontBreakpoint) { // add 15 for the scroll bar to break at the correct point (we subtracted this number before)
        select(currentRef)
          .selectAll("text")
          .style("font-size", "1.5rem")
          .attr("x", padding)
          .attr("text-anchor", "start");
      }
    }
  }, [scaleY, width, getXPositionForFeature, isNegativeFeature]);

  return <g ref={ref} />;
}

export default BarChartAxisLeft;

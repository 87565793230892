import React from "react";
import {LineChartLegendProps} from "../../../types/LineChartLegendProps";

function Legend({labels, scaleColor}: LineChartLegendProps) {
    return (
        <div className="flex justify-evenly md:justify-end px-6 mb-6 md:mb-0">
            {labels.map(label => {
                return (
                    <p className="flex items-center text-base mx-0 md:mx-4" key={label}>
                        <svg width={10} height={10}>
                            <circle r={5} cx={5} cy={5} fill={scaleColor(label) as string}/>
                        </svg>
                        <span className="ml-2">{label}</span>
                    </p>
                )
            })}
        </div>
    );
}

export default Legend;
import React from "react";
import { ButtonProps } from "../../types/ButtonProps";

const Button = ({ onClick, inverted, children }: ButtonProps) => {
  let styles = "text-blue bg-white border-blue";
  if (inverted) styles = "text-white bg-blue border-white";

  return (
    <button
      onClick={onClick}
      className={`inline-flex relative transition-all md:hover:bottom-1 bottom-0 justify-center items-center text-center border px-6 py-1 text-base rounded-full mb-4 sm:mb-0 w-full sm:w-auto sm:mr-4 last:mb-0 sm:last:mr-0 ${styles}`}
    >
      {children}
    </button>
  );
};

export default Button;

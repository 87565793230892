import React, { useEffect } from "react";
import { routerBasePath } from "../../constants";

function BotEyes({
  lookingDirection,
  lookingAround,
}: {
  lookingDirection: string;
  lookingAround: boolean;
}) {
  const imgs = [
    `${routerBasePath}/img/eyes-bot-bl.svg`,
    `${routerBasePath}/img/eyes-bot-tr.svg`,
    `${routerBasePath}/img/eyes-bot-br.svg`,
    `${routerBasePath}/img/eyes-bot-tl.svg`,
  ];
  const interval = 5000;
  const randomDelay = Math.floor(Math.random() * 5000);
  const startingImage = imgs.indexOf(
    `${routerBasePath}/img/eyes-bot-${lookingDirection}.svg`
  );

  const [currentSVG, setCurrentSVG] = React.useState(startingImage);

  useEffect(() => {
    if (!lookingAround) return () => {};

    const intervalId = setInterval(() => {
      setCurrentSVG(Math.floor(Math.random() * imgs.length));
    }, interval + randomDelay);
    return () => clearInterval(intervalId);
  }, [currentSVG, lookingAround, imgs.length, randomDelay]);

  return (
    <div className={"relative items-center"}>
      {imgs.map((svg, index) => (
        <img
          key={index}
          className={`absolute max-w-[120px] md:max-w-none top-0 ${
            index === currentSVG ? "visible relative" : "invisible"
          }`}
          src={svg}
          alt={"eyes"}
        />
      ))}
    </div>
  );
}

export default BotEyes;

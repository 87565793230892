import React from "react";
import HalfWidthColumn from "./HalfWidthColumn";

function TwoColumnText() {
    return (
      <div className="flex flex-wrap">
        <HalfWidthColumn title={"Our Method"} inverted={true}>
            <p className="text-base p-4 md:p-6">
              Spot A Bot aims to develop an algorithm for categorizing Twitter
              profiles and tweets as bot- or human-generated. By doing that we can
              better understand the effect that bots have and raise awareness on
              this topic. Our project tries to contribute to the scientific world in
              multiple ways. Detection of bots is not necessarily a novel field, and
              in some parts acclaimed universities have spent a great deal of time
              and effort in creating sophisticated bot detection algorithms.
              <br />
              <br />
              Spot A Bot tries on the one hand to create its own algorithm by
              expanding on these efforts with the knowledge and insights created by
              these projects. On the other hand, we also try to make machine
              learning more accessible and understandable to the average human
              being. We try to visualize the whole process of classification to
              hopefully create a transparent and understandable experience for
              everyone.
            </p>
        </HalfWidthColumn>
        <HalfWidthColumn title={"Our Motivation"}>
            <p className="text-base p-4 md:p-6">
              We are interested in social media bots and their impact on our
              society. Algorithms evolve and bots get better at behaving like humans
              – a fascinating race between developing bot algorithms and finding
              counteracting methods to detect bots is currently ongoing. Therefore
              we've chosen this relevant topic for our master's project Spot A Bot
              as part of our studies at the Salzburg University of Applied Sciences.
            </p>
        </HalfWidthColumn>
      </div>
    );
}

export default TwoColumnText;
import React from "react";
import { TWEET_LABELS } from "../../../constants";
import { FilterContainerProps } from "../../../types/FilterContainerProps";
import FilterButton from "./FilterButton";

function FilterContainer({
  activeFilters,
  setActiveFilters,
  showOnlyVoted,
  setShowOnlyVoted,
  sort,
  setSort,
}: FilterContainerProps) {
  const availableFilters = Object.values(TWEET_LABELS);

  const addOrRemoveFilter = (filter: string) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter((active) => active !== filter));
    } else {
      setActiveFilters([...activeFilters, filter]);
    }
  };

  return (
    <div className="md:flex md:items-center px-4 md:px-6 py-4 border-b border-black">
      <p className="md:mr-2 mb-3 md:m-0 text-left">
        Filter for tweets labeled as:
      </p>
      <div className="flex gap-y-3 flex-wrap">
        {availableFilters.map((label, index) => (
          <FilterButton
            key={index}
            onClick={() => addOrRemoveFilter(label)}
            active={activeFilters.includes(label)}
          >
            {label}
          </FilterButton>
        ))}
        <FilterButton
          active={showOnlyVoted}
          onClick={() => setShowOnlyVoted(!showOnlyVoted)}
        >
          ALREADY VOTED
        </FilterButton>
      </div>
      <div className="md:ml-auto md:items-center md:flex text-left">
        <p className="mt-3 mb-3 md:my-0 md:mr-2">
          Sort by Bot Probability (=BP):{" "}
        </p>
        <FilterButton
          active={sort === "asc"}
          // if button asc is pressed again, filter should be resetted
          onClick={() => setSort(sort === "asc" ? "none" : "asc")}
        >
          ↑
        </FilterButton>
        <FilterButton
          active={sort === "desc"}
          // if button desc is pressed again, filter should be resetted
          onClick={() => setSort(sort === "desc" ? "none" : "desc")}
        >
          ↓
        </FilterButton>
      </div>
    </div>
  );
}

export default FilterContainer;

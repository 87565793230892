import React from "react";
import DoughnutChart from "./DoughnutChart";
import {DoughnutChartRowProps} from "../../../types/DoughnutChartRowProps";

function DoughnutChartRow({ data }: DoughnutChartRowProps) {
  return (
    <div className="px-4 md:px-6 my-8 flex flex-col sm:flex-row gap-6">
      {data.map((d) => (
        <DoughnutChart
          key={d.label}
          percentage={d.percentage}
          label={d.label}
          thresholdMin={d.thresholdMin}
          thresholdMax={d.thresholdMax}
        />
      ))}
    </div>
  );
}

export default DoughnutChartRow;

import React from "react";

function CalendarIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-[40px] w-[40px] lg:h-[60px] lg:w-[60px]" viewBox="0 0 76 76">
            <path d="M 58.53,0.00 C 58.53,0.00 58.53,5.80 58.53,5.80   58.53,5.80 55.60,5.80 55.60,5.80   55.60,5.80 55.60,0.00 55.60,0.00   55.60,0.00 58.53,0.00 58.53,0.00 Z M 19.40,0.00 C 19.40,0.00 19.40,5.80 19.40,5.80   19.40,5.80 16.47,5.80 16.47,5.80   16.47,5.80 16.47,0.00 16.47,0.00   16.47,0.00 19.40,0.00 19.40,0.00 Z M 72.69,22.49 C 72.69,22.49 72.69,25.39 72.69,25.39   72.69,25.39 2.31,25.39 2.31,25.39   2.31,25.39 2.31,22.49 2.31,22.49   2.31,22.49 72.69,22.49 72.69,22.49 Z M 5.85,66.20 C 5.85,66.20 5.85,69.10 5.85,69.10   5.85,69.10 2.92,69.10 2.92,69.10   2.92,69.10 2.92,66.20 2.92,66.20   2.92,66.20 5.85,66.20 5.85,66.20 Z M 69.08,69.10 C 69.08,69.10 69.08,72.00 69.08,72.00   69.08,72.00 5.85,72.00 5.85,72.00   5.85,72.00 5.85,69.10 5.85,69.10   5.85,69.10 69.08,69.10 69.08,69.10 Z M 69.08,5.64 C 69.08,5.64 69.08,8.54 69.08,8.54   69.08,8.54 5.85,8.54 5.85,8.54   5.85,8.54 5.85,5.64 5.85,5.64   5.85,5.64 69.08,5.64 69.08,5.64 Z M 5.85,8.54 C 5.85,8.54 5.85,11.44 5.85,11.44   5.85,11.44 2.92,11.44 2.92,11.44   2.92,11.44 2.92,8.54 2.92,8.54   2.92,8.54 5.85,8.54 5.85,8.54 Z M 2.92,11.44 C 2.92,11.44 2.92,66.32 2.92,66.32   2.92,66.32 0.00,66.32 0.00,66.32   0.00,66.32 0.00,11.44 0.00,11.44   0.00,11.44 2.92,11.44 2.92,11.44 Z M 72.08,66.32 C 72.08,66.32 72.08,69.21 72.08,69.21   72.08,69.21 69.08,69.21 69.08,69.21   69.08,69.21 69.08,66.32 69.08,66.32   69.08,66.32 72.08,66.32 72.08,66.32 Z M 75.00,11.44 C 75.00,11.44 75.00,66.32 75.00,66.32   75.00,66.32 72.08,66.32 72.08,66.32   72.08,66.32 72.08,11.44 72.08,11.44   72.08,11.44 75.00,11.44 75.00,11.44 Z M 72.08,8.65 C 72.08,8.65 72.08,11.55 72.08,11.55   72.08,11.55 69.08,11.55 69.08,11.55   69.08,11.55 69.08,8.65 69.08,8.65   69.08,8.65 72.08,8.65 72.08,8.65 Z" />
        </svg>
    )
}

export default CalendarIcon;
import React from "react";
import Separator from "../Functionality/Separator";
import AboutPageBanner from "./AboutPageBanner";
import TwoColumnText from "./TwoColumnText";
import HalfWidthColumn from "./HalfWidthColumn";

function AboutPage() {

    return (
      <React.Fragment>
        <div className="bg-blue text-left text-white pt-32 pb-8 px-4 md:px-6">
          <h1 className="text-xl">Hey Everybody!</h1>
        </div>
        <div className="pt-8 md:pt-12 text-left">
          <p className="mx-4 md:mx-6 mb-8 text-md max-w-screen-lg">
            Spot A Bot focuses on bot detection on Twitter. Why?
          </p>
          <p className="text-left text-md mr-16 ml-4 md:ml-6 mb-24 max-w-screen-lg">
            Because bots are sometimes difficult to recognize in social media.
            If a single post from a bot appears in the timeline, it can hardly
            be distinguished from a human post. This might have negative
            consequences, as this can be used as a form of mass manipulation,
            message suppression and other nefarious activities.
          </p>

          <div className="mb-16 md:mb-28">
            <Separator primaryLabel={"Concept and Development"} />
            <AboutPageBanner name={"Kevin Harizaj"}></AboutPageBanner>
            <AboutPageBanner name={"Lena Heiglauer"}></AboutPageBanner>
            <AboutPageBanner name={"Nicolas Graf"}></AboutPageBanner>
            <AboutPageBanner name={"Daniela Dottolo"}></AboutPageBanner>
          </div>
          <div className="mb-16 md:mb-28">
            <Separator primaryLabel={"Design"} />
            <AboutPageBanner name={"Denise Hödl"}></AboutPageBanner>
            <AboutPageBanner name={"Magdalena Jo Umkehrer"}></AboutPageBanner>
          </div>
          <TwoColumnText />

          <HalfWidthColumn title={""} inverted={true}>
            <p className="text-4xl pt-24 pb-6 px-4 md:px-6 leading-[2.75rem] md:text-5xl md:leading-[4rem]">
              If you have any feedback or concerns, please feel free to reach
              out to us on{" "}
              <a
                target="_blank"
                href="https://twitter.com/SpotABot_"
                rel="noreferrer"
                className="link-underline-white"
              >
                Twitter
              </a>
            </p>
          </HalfWidthColumn>
        </div>
      </React.Fragment>
    );
}

export default AboutPage;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import {FooterProps} from "../../types/FooterProps";

function Footer({inverted}: FooterProps) {
  const textList = Array(30).fill("Follow us on Twitter");

  const location = useLocation();
  if (location.pathname === "/") {
    inverted = true;
  }

  return (
    <footer className="bottom-0 mt-auto">
      <div className={`overflow-x-hidden ${inverted ? "bg-white" : "bg-blue"}`}>
        <a
          target="_blank"
          href="https://twitter.com/SpotABot_"
          className="text-white py-2 whitespace-nowrap animate-marquee-running inline-block"
          rel="noreferrer"
        >
          {textList.map((text, index) => (
            <span
              className={`${inverted ? "text-blue" : "text-white"} px-4`}
              key={index}
            >
              {text}
            </span>
          ))}
        </a>
      </div>
      <div
        className={`${
          inverted ? "text-white bg-blue" : "text-blue bg-white"
        } text-left p-4 md:p-6`}
      >
        <Link
          to={"/imprint"}
          className={`${
            inverted ? "link-underline-white" : "link-underline-blue"
          } mr-4`}
        >
          Imprint
        </Link>
        <Link
          to={"/privacy"}
          className={`${
            inverted ? "link-underline-white" : "link-underline-blue"
          }`}
        >
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";

function NotAvailable({ notice }: { notice: string }) {
  return (
    <p className="text-base px-4 md:p-6 text-center">
      {notice}{" "}
      <a className="link-underline-blue" href="https://twitter.com/SpotABot_">
        Contact us
      </a>{" "}
      if this problem persists.
    </p>
  );
}

export default NotAvailable;

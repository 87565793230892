import React from "react";
import Tweet from "./Tweet";
import { TweetGridProps } from "../../../types/TweetGridProps";
import { Link } from "react-router-dom";
import { TweetType } from "../../../types/TweetType";
import { TweetTypeLabeled } from "../../../types/TweetTypeLabeled";
import {
  THRESHOLD_BOT,
  THRESHOLD_UNSURE,
  TWEET_LABELS,
} from "../../../constants";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import NotAvailable from "../../Functionality/NotAvailable";

function labelTweets(tweets: TweetType[]): TweetTypeLabeled[] {
  return tweets.map((tweet) => {
    let label;
    if (tweet.probability < THRESHOLD_UNSURE) {
      label = TWEET_LABELS.human;
    } else if (tweet.probability >= THRESHOLD_BOT) {
      label = TWEET_LABELS.bot;
    } else {
      label = TWEET_LABELS.unsure;
    }

    const voted = tweet.user_bot_classifications?.length > 0;
    return { ...tweet, label: label, voted: voted };
  });
}

function TweetGrid({
  trendName,
  tweets,
  activeFilters,
  showOnlyVoted,
  sort,
}: TweetGridProps) {
  if (!tweets) return <NotAvailable notice={"No Tweets available."} />;

  const labeledTweets = labelTweets(tweets);
  let filteredTweets = labeledTweets.filter(
    (tweet) => activeFilters.includes(tweet.label) || activeFilters.length === 0
  );

  filteredTweets = filteredTweets.filter(
    (tweet) => !showOnlyVoted || tweet.voted
  );

  if (sort === "asc") {
    // sort ascending
    filteredTweets.sort(
      (tweetA, tweetB) => tweetA.probability - tweetB.probability
    );
  } else if (sort === "desc") {
    // sort descending
    filteredTweets.sort(
      (tweetA, tweetB) => tweetB.probability - tweetA.probability
    );
  }
  // do nothing if both are active or none are active

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 640: 2, 1024: 3 }}>
      <Masonry gutter={"1.5rem"}>
        {filteredTweets.map((tweet, index: number) => (
          <React.Fragment key={index}>
            <Link
              to={`/trend/${encodeURIComponent(trendName)}/${encodeURIComponent(
                tweet.author_id
              )}`}
              data-cy="tweet"
            >
              <Tweet tweet={tweet} gridView={true} />
            </Link>
          </React.Fragment>
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
}

export default TweetGrid;
import React from "react";
import {TrendHistoryPageHeaderProps} from "../../../types/TrendHistoryPageHeaderProps";
import Skeleton from "react-loading-skeleton";

function TrendHistoryPageHeader({ dateRange, loading}: TrendHistoryPageHeaderProps) {
    const getHeaderText = () => {
        if (loading)
            return <Skeleton containerClassName="block max-w-md" count={2} containerTestId="historypage-loadingskeleton" />;
        if (!dateRange) {
            // two invisible lines so header doesnt jump
            return (
                <p className="text-2xl">
                    &nbsp;
                    <br />
                    &nbsp;
                </p>
            );
        }

        return (
            <p className="text-base">
                Explore Past Trends for the Period From <br />
                {dateRange?.min.toLocaleDateString("en-UK", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                })} to {dateRange?.max.toLocaleDateString("en-UK", {
                year: "numeric",
                month: "short",
                day: "2-digit",
            })}
            </p>
        );
    }

    return (
      <div className="bg-blue text-left text-white pt-20 md:pt-32 pb-8 px-4 md:px-6">
          {getHeaderText()}
          <h1 className="text-lg md:text-xl mt-4">
              {loading ? (
                  <Skeleton containerClassName="block max-w-lg" />
              ) : "Trend History"}
          </h1>
      </div>
    );
}

export default TrendHistoryPageHeader
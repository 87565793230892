import { axisTop, select } from "d3";
import { useEffect, useRef } from "react";
import { AxisHorizontalProps } from "../../../types/AxisHorizontalProps";

function BarChartAxisTop({ scale, transform }: AxisHorizontalProps) {
  const ref = useRef<SVGGElement>(null);
  const smallerFontBreakpoint = 640; //640px = md breakpoint from tailwind

  useEffect(() => {
    const currentSVGGroup = ref.current;
    if (currentSVGGroup) {
      select(currentSVGGroup)
        .style("font-size", "1rem")
        .call(axisTop(scale).tickSizeOuter(0));

      if (currentSVGGroup.getBBox().width < smallerFontBreakpoint) {
        select(currentSVGGroup).style("font-size", "0.75rem");
      }
    }
  }, [scale]);

  return <g ref={ref} transform={transform} />;
}

export default BarChartAxisTop;

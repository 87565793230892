import React from "react";
import {SplitscreenContentProps} from "../../types/SplitscreenContentProps";


function Splitscreen({leftContent, rightContent}: SplitscreenContentProps){
    return (
        <div className="grid lg:grid-cols-10 sm:grid-cols-1 ">
            <div className="border-b-2 bg-blue lg:col-span-4">
                {leftContent}
            </div>
            <div className="border-b-2 border-blue lg:col-span-6">
                {rightContent}
            </div>
        </div>
    )
}

export default Splitscreen;
import React from "react";

function UKIcon() {
    return (
        <svg className="h-full" xmlns="http://www.w3.org/2000/svg" width="71.318" height="129.306" viewBox="0 0 71.318 129.306">
            <g id="gb" className="h-full" transform="translate(-190 -141.22)">
                <rect id="Rectangle_970" width="2.46" height="2.46" transform="translate(224.429 153.707)" />
                <rect id="Rectangle_971" width="2.46" height="2.46" transform="translate(226.889 156.167)" />
                <rect id="Rectangle_972" width="2.46" height="2.46" transform="translate(224.429 158.628)" />
                <rect id="Rectangle_973" width="2.46" height="2.46" transform="translate(202.296 163.543)" />
                <rect id="Rectangle_974" width="2.46" height="2.46" transform="translate(212.132 163.543)" />
                <rect id="Rectangle_975" width="2.46" height="2.46" transform="translate(214.593 163.543)" />
                <rect id="Rectangle_976" width="2.46" height="2.46" transform="translate(217.053 163.543)" />
                <rect id="Rectangle_977" width="2.46" height="2.46" transform="translate(219.514 163.543)" />
                <rect id="Rectangle_978" width="2.46" height="2.46" transform="translate(199.836 166.003)" />
                <rect id="Rectangle_979" width="2.46" height="2.46" transform="translate(202.296 166.003)" />
                <rect id="Rectangle_980" width="2.46" height="2.46" transform="translate(209.672 166.003)" />
                <rect id="Rectangle_981" width="2.46" height="2.46" transform="translate(221.968 166.003)" />
                <rect id="Rectangle_982" width="2.46" height="2.46" transform="translate(197.376 168.464)" />
                <rect id="Rectangle_983" width="2.46" height="2.46" transform="translate(202.296 168.464)" />
                <rect id="Rectangle_984" width="2.46" height="2.46" transform="translate(209.672 168.464)" />
                <rect id="Rectangle_985" width="2.46" height="2.46" transform="translate(219.514 168.464)" />
                <rect id="Rectangle_986" width="2.46" height="2.46" transform="translate(199.836 170.924)" />
                <rect id="Rectangle_987" width="2.46" height="2.46" transform="translate(207.217 170.924)" />
                <rect id="Rectangle_988" width="2.46" height="2.46" transform="translate(217.053 170.924)" />
                <rect id="Rectangle_989" width="2.46" height="2.46" transform="translate(197.376 173.385)" />
                <rect id="Rectangle_990" width="2.46" height="2.46" transform="translate(207.217 173.385)" />
                <rect id="Rectangle_991" width="2.46" height="2.46" transform="translate(217.053 173.385)" />
                <rect id="Rectangle_992" width="2.46" height="2.46" transform="translate(194.921 175.839)" />
                <rect id="Rectangle_993" width="2.46" height="2.46" transform="translate(199.836 175.839)" />
                <rect id="Rectangle_994" width="2.46" height="2.46" transform="translate(202.296 175.839)" />
                <rect id="Rectangle_995" width="2.46" height="2.46" transform="translate(207.217 175.839)" />
                <rect id="Rectangle_996" width="2.46" height="2.46" transform="translate(219.514 175.839)" />
                <rect id="Rectangle_997" width="2.46" height="2.46" transform="translate(221.968 175.839)" />
                <rect id="Rectangle_998" width="2.46" height="2.46" transform="translate(224.429 175.839)" />
                <rect id="Rectangle_999" width="2.46" height="2.46" transform="translate(226.889 175.839)" />
                <rect id="Rectangle_1000" width="2.46" height="2.46" transform="translate(229.35 175.839)" />
                <rect id="Rectangle_1001" width="2.46" height="2.46" transform="translate(194.921 178.3)" />
                <rect id="Rectangle_1002" width="2.46" height="2.46" transform="translate(202.296 178.3)" />
                <rect id="Rectangle_1003" width="2.46" height="2.46" transform="translate(204.757 178.3)" />
                <rect id="Rectangle_1004" width="2.46" height="2.46" transform="translate(231.804 178.3)" />
                <rect id="Rectangle_1005" width="2.46" height="2.46" transform="translate(194.921 180.76)" />
                <rect id="Rectangle_1006" width="2.46" height="2.46" transform="translate(204.757 180.76)" />
                <rect id="Rectangle_1007" width="2.46" height="2.46" transform="translate(229.35 180.76)" />
                <rect id="Rectangle_1008" width="2.46" height="2.46" transform="translate(204.757 183.221)" />
                <rect id="Rectangle_1009" width="2.46" height="2.46" transform="translate(229.35 183.221)" />
                <rect id="Rectangle_1010" width="2.46" height="2.46" transform="translate(202.296 185.675)" />
                <rect id="Rectangle_1011" width="2.46" height="2.46" transform="translate(226.889 185.675)" />
                <rect id="Rectangle_1012" width="2.46" height="2.46" transform="translate(202.296 188.136)" />
                <rect id="Rectangle_1013" width="2.46" height="2.46" transform="translate(226.889 188.136)" />
                <rect id="Rectangle_1014" width="2.46" height="2.46" transform="translate(202.296 190.596)" />
                <rect id="Rectangle_1015" width="2.46" height="2.46" transform="translate(204.757 193.057)" />
                <rect id="Rectangle_1016" width="2.46" height="2.46" transform="translate(224.429 190.596)" />
                <rect id="Rectangle_1017" width="2.46" height="2.46" transform="translate(221.968 193.057)" />
                <rect id="Rectangle_1018" width="2.46" height="2.46" transform="translate(202.296 195.517)" />
                <rect id="Rectangle_1019" width="2.46" height="2.46" transform="translate(224.429 195.517)" />
                <rect id="Rectangle_1020" width="2.46" height="2.46" transform="translate(226.889 195.517)" />
                <rect id="Rectangle_1021" width="2.46" height="2.46" transform="translate(229.35 195.517)" />
                <rect id="Rectangle_1022" width="2.46" height="2.46" transform="translate(202.296 197.972)" />
                <rect id="Rectangle_1023" width="2.46" height="2.46" transform="translate(204.757 197.972)" />
                <rect id="Rectangle_1024" width="2.46" height="2.46" transform="translate(229.35 197.972)" />
                <rect id="Rectangle_1025" width="2.46" height="2.46" transform="translate(231.804 197.972)" />
                <rect id="Rectangle_1026" width="2.46" height="2.46" transform="translate(204.757 200.432)" />
                <rect id="Rectangle_1027" width="2.46" height="2.46" transform="translate(207.217 200.432)" />
                <rect id="Rectangle_1028" width="2.46" height="2.46" transform="translate(209.672 200.432)" />
                <rect id="Rectangle_1029" width="2.46" height="2.46" transform="translate(234.265 200.432)" />
                <rect id="Rectangle_1030" width="2.46" height="2.46" transform="translate(204.757 202.893)" />
                <rect id="Rectangle_1031" width="2.46" height="2.46" transform="translate(212.132 202.893)" />
                <rect id="Rectangle_1032" width="2.46" height="2.46" transform="translate(234.265 202.893)" />
                <rect id="Rectangle_1033" width="2.46" height="2.46" transform="translate(194.921 205.353)" />
                <rect id="Rectangle_1034" width="2.46" height="2.46" transform="translate(197.376 205.353)" />
                <rect id="Rectangle_1035" width="2.46" height="2.46" transform="translate(199.836 205.353)" />
                <rect id="Rectangle_1036" width="2.46" height="2.46" transform="translate(212.132 205.353)" />
                <rect id="Rectangle_1037" width="2.46" height="2.46" transform="translate(234.265 205.353)" />
                <rect id="Rectangle_1038" width="2.46" height="2.46" transform="translate(192.46 207.814)" />
                <rect id="Rectangle_1039" width="2.46" height="2.46" transform="translate(202.296 207.814)" />
                <rect id="Rectangle_1040" width="2.46" height="2.46" transform="translate(209.672 207.814)" />
                <rect id="Rectangle_1041" width="2.46" height="2.46" transform="translate(217.053 207.814)" />
                <rect id="Rectangle_1042" width="2.46" height="2.46" transform="translate(219.514 207.814)" />
                <rect id="Rectangle_1043" width="2.46" height="2.46" transform="translate(234.265 207.814)" />
                <rect id="Rectangle_1044" width="2.46" height="2.46" transform="translate(192.46 210.268)" />
                <rect id="Rectangle_1045" width="2.46" height="2.46" transform="translate(204.757 210.268)" />
                <rect id="Rectangle_1046" width="2.46" height="2.46" transform="translate(209.672 210.268)" />
                <rect id="Rectangle_1047" width="2.46" height="2.46" transform="translate(212.132 210.268)" />
                <rect id="Rectangle_1048" width="2.46" height="2.46" transform="translate(214.593 210.268)" />
                <rect id="Rectangle_1049" width="2.46" height="2.46" transform="translate(221.968 210.268)" />
                <rect id="Rectangle_1050" width="2.46" height="2.46" transform="translate(234.265 210.268)" />
                <rect id="Rectangle_1051" width="2.46" height="2.46" transform="translate(236.725 210.268)" />
                <rect id="Rectangle_1052" width="2.46" height="2.46" transform="translate(190 212.729)" />
                <rect id="Rectangle_1053" width="2.46" height="2.46" transform="translate(207.217 212.729)" />
                <rect id="Rectangle_1054" width="2.46" height="2.46" transform="translate(221.968 212.729)" />
                <rect id="Rectangle_1055" width="2.46" height="2.46" transform="translate(239.186 212.729)" />
                <rect id="Rectangle_1056" width="2.46" height="2.46" transform="translate(241.646 212.729)" />
                <rect id="Rectangle_1057" width="2.46" height="2.46" transform="translate(190 215.189)" />
                <rect id="Rectangle_1058" width="2.46" height="2.46" transform="translate(194.921 215.189)" />
                <rect id="Rectangle_1059" width="2.46" height="2.46" transform="translate(197.376 215.189)" />
                <rect id="Rectangle_1060" width="2.46" height="2.46" transform="translate(204.757 215.189)" />
                <rect id="Rectangle_1061" width="2.46" height="2.46" transform="translate(212.132 215.189)" />
                <rect id="Rectangle_1062" width="2.46" height="2.46" transform="translate(214.593 215.189)" />
                <rect id="Rectangle_1063" width="2.46" height="2.46" transform="translate(221.968 215.189)" />
                <rect id="Rectangle_1064" width="2.46" height="2.46" transform="translate(241.646 215.189)" />
                <rect id="Rectangle_1065" width="2.46" height="2.46" transform="translate(192.46 217.65)" />
                <rect id="Rectangle_1066" width="2.46" height="2.46" transform="translate(199.836 217.65)" />
                <rect id="Rectangle_1067" width="2.46" height="2.46" transform="translate(202.296 217.65)" />
                <rect id="Rectangle_1068" width="2.46" height="2.46" transform="translate(212.132 217.65)" />
                <rect id="Rectangle_1069" width="2.46" height="2.46" transform="translate(224.429 217.65)" />
                <rect id="Rectangle_1070" width="2.46" height="2.46" transform="translate(244.101 217.65)" />
                <rect id="Rectangle_1071" width="2.46" height="2.46" transform="translate(244.101 220.11)" />
                <rect id="Rectangle_1072" width="2.46" height="2.46" transform="translate(226.889 220.11)" />
                <rect id="Rectangle_1073" width="2.46" height="2.46" transform="translate(226.889 222.565)" />
                <rect id="Rectangle_1074" width="2.46" height="2.46" transform="translate(246.561 222.565)" />
                <rect id="Rectangle_1075" width="2.46" height="2.46" transform="translate(224.429 225.025)" />
                <rect id="Rectangle_1076" width="2.46" height="2.46" transform="translate(246.561 225.025)" />
                <rect id="Rectangle_1077" width="2.46" height="2.46" transform="translate(217.053 227.486)" />
                <rect id="Rectangle_1078" width="2.46" height="2.46" transform="translate(219.514 227.486)" />
                <rect id="Rectangle_1079" width="2.46" height="2.46" transform="translate(221.968 227.486)" />
                <rect id="Rectangle_1080" width="2.46" height="2.46" transform="translate(224.429 227.486)" />
                <rect id="Rectangle_1081" width="2.46" height="2.46" transform="translate(246.561 227.486)" />
                <rect id="Rectangle_1082" width="2.46" height="2.46" transform="translate(214.593 229.946)" />
                <rect id="Rectangle_1083" width="2.46" height="2.46" transform="translate(246.561 229.946)" />
                <rect id="Rectangle_1084" width="2.46" height="2.46" transform="translate(212.132 232.407)" />
                <rect id="Rectangle_1085" width="2.46" height="2.46" transform="translate(214.593 232.407)" />
                <rect id="Rectangle_1086" width="2.46" height="2.46" transform="translate(246.561 232.407)" />
                <rect id="Rectangle_1087" width="2.46" height="2.46" transform="translate(251.482 232.407)" />
                <rect id="Rectangle_1088" width="2.46" height="2.46" transform="translate(253.943 232.407)" />
                <rect id="Rectangle_1089" width="2.46" height="2.46" transform="translate(256.397 232.407)" />
                <rect id="Rectangle_1090" width="2.46" height="2.46" transform="translate(217.053 234.861)" />
                <rect id="Rectangle_1091" width="2.46" height="2.46" transform="translate(249.022 234.861)" />
                <rect id="Rectangle_1092" width="2.46" height="2.46" transform="translate(258.858 234.861)" />
                <rect id="Rectangle_1093" width="2.46" height="2.46" transform="translate(217.053 237.322)" />
                <rect id="Rectangle_1094" width="2.46" height="2.46" transform="translate(258.858 237.322)" />
                <rect id="Rectangle_1095" width="2.46" height="2.46" transform="translate(214.593 239.782)" />
                <rect id="Rectangle_1096" width="2.46" height="2.46" transform="translate(258.858 239.782)" />
                <rect id="Rectangle_1097" width="2.46" height="2.46" transform="translate(212.132 242.243)" />
                <rect id="Rectangle_1098" width="2.46" height="2.46" transform="translate(256.397 242.243)" />
                <rect id="Rectangle_1099" width="2.46" height="2.46" transform="translate(209.672 244.697)" />
                <rect id="Rectangle_1100" width="2.46" height="2.46" transform="translate(256.397 244.697)" />
                <rect id="Rectangle_1101" width="2.46" height="2.46" transform="translate(212.132 247.158)" />
                <rect id="Rectangle_1102" width="2.46" height="2.46" transform="translate(214.593 247.158)" />
                <rect id="Rectangle_1103" width="2.46" height="2.46" transform="translate(217.053 247.158)" />
                <rect id="Rectangle_1104" width="2.46" height="2.46" transform="translate(224.429 247.158)" />
                <rect id="Rectangle_1105" width="2.46" height="2.46" transform="translate(253.943 247.158)" />
                <rect id="Rectangle_1106" width="2.46" height="2.46" transform="translate(219.514 249.618)" />
                <rect id="Rectangle_1107" width="2.46" height="2.46" transform="translate(221.968 249.618)" />
                <rect id="Rectangle_1108" width="2.46" height="2.46" transform="translate(226.889 249.618)" />
                <rect id="Rectangle_1109" width="2.46" height="2.46" transform="translate(253.943 249.618)" />
                <rect id="Rectangle_1110" width="2.46" height="2.46" transform="translate(226.889 252.079)" />
                <rect id="Rectangle_1111" width="2.46" height="2.46" transform="translate(256.397 252.079)" />
                <rect id="Rectangle_1112" width="2.46" height="2.46" transform="translate(258.858 252.079)" />
                <rect id="Rectangle_1113" width="2.46" height="2.46" transform="translate(217.053 254.539)" />
                <rect id="Rectangle_1114" width="2.46" height="2.46" transform="translate(219.514 254.539)" />
                <rect id="Rectangle_1115" width="2.46" height="2.46" transform="translate(221.968 254.539)" />
                <rect id="Rectangle_1116" width="2.46" height="2.46" transform="translate(224.429 254.539)" />
                <rect id="Rectangle_1117" width="2.46" height="2.46" transform="translate(226.889 254.539)" />
                <rect id="Rectangle_1118" width="2.46" height="2.46" transform="translate(256.397 254.539)" />
                <rect id="Rectangle_1119" width="2.46" height="2.46" transform="translate(214.593 256.994)" />
                <rect id="Rectangle_1120" width="2.46" height="2.46" transform="translate(241.646 256.994)" />
                <rect id="Rectangle_1121" width="2.46" height="2.46" transform="translate(244.101 256.994)" />
                <rect id="Rectangle_1122" width="2.46" height="2.46" transform="translate(246.561 256.994)" />
                <rect id="Rectangle_1123" width="2.46" height="2.46" transform="translate(249.022 256.994)" />
                <rect id="Rectangle_1124" width="2.46" height="2.46" transform="translate(251.482 256.994)" />
                <rect id="Rectangle_1125" width="2.46" height="2.46" transform="translate(253.943 256.994)" />
                <rect id="Rectangle_1126" width="2.46" height="2.46" transform="translate(212.132 259.454)" />
                <rect id="Rectangle_1127" width="2.46" height="2.46" transform="translate(221.968 259.454)" />
                <rect id="Rectangle_1128" width="2.46" height="2.46" transform="translate(224.429 259.454)" />
                <rect id="Rectangle_1129" width="2.46" height="2.46" transform="translate(226.889 259.454)" />
                <rect id="Rectangle_1130" width="2.46" height="2.46" transform="translate(229.35 259.454)" />
                <rect id="Rectangle_1131" width="2.46" height="2.46" transform="translate(231.804 259.454)" />
                <rect id="Rectangle_1132" width="2.46" height="2.46" transform="translate(234.265 259.454)" />
                <rect id="Rectangle_1133" width="2.46" height="2.46" transform="translate(236.725 259.454)" />
                <rect id="Rectangle_1134" width="2.46" height="2.46" transform="translate(239.186 259.454)" />
                <rect id="Rectangle_1135" width="2.46" height="2.46" transform="translate(209.672 261.914)" />
                <rect id="Rectangle_1136" width="2.46" height="2.46" transform="translate(212.132 261.914)" />
                <rect id="Rectangle_1137" width="2.46" height="2.46" transform="translate(214.593 261.914)" />
                <rect id="Rectangle_1138" width="2.46" height="2.46" transform="translate(219.514 261.914)" />
                <rect id="Rectangle_1139" width="2.46" height="2.46" transform="translate(207.217 264.375)" />
                <rect id="Rectangle_1140" width="2.46" height="2.46" transform="translate(209.672 264.375)" />
                <rect id="Rectangle_1141" width="2.46" height="2.46" transform="translate(217.053 264.375)" />
                <rect id="Rectangle_1142" width="2.46" height="2.46" transform="translate(204.757 266.836)" />
                <rect id="Rectangle_1143" width="2.46" height="2.46" transform="translate(207.217 266.836)" />
                <rect id="Rectangle_1144" width="2.46" height="2.46" transform="translate(198.279 268.066)" />
                <rect id="Rectangle_1145" width="2.46" height="2.46" transform="translate(231.709 143.675)" />
                <rect id="Rectangle_1146" width="2.46" height="2.46" transform="translate(234.17 146.135)" />
                <rect id="Rectangle_1147" width="2.46" height="2.46" transform="translate(234.17 141.22)" />
                <rect id="Rectangle_1148" width="2.46" height="2.46" transform="translate(234.17 148.596)" />
            </g>
        </svg>

    );
}

export default UKIcon;
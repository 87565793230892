import React, {useEffect, useState} from "react";
import Separator from "../Functionality/Separator";
import Infobox from "../Functionality/Infobox";
import TrendHistoryPageHeader from "./Header/TrendHistoryPageHeader";
import Search from "./Search/Search";
import DatePicker from "./DatePicker/DatePicker";
import LineChart from "./LineChart/LineChart";
import AccordionList from "./Accordion/AccordionList";
import {TrendHistoryObjectType} from "../../types/TrendHistoryObjectType";
import {useTrendHistoryFetch} from "../../hooks/customHooks";
import NotAvailable from "../Functionality/NotAvailable";

function TrendHistoryPage() {
    const { trendHistory, chartData, dateRange, loading, error } = useTrendHistoryFetch();
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [searchResults, setSearchResults] = useState<Array<TrendHistoryObjectType>>([])
    const [dateQuery, setDateQuery] = useState<string>("")

    useEffect(() => {
        if (trendHistory) setSearchResults(trendHistory)
    }, [trendHistory])

    useEffect(() => {
        if (trendHistory) {
            const filteredTrendsByText = trendHistory
                .filter(history => history.trendName.toLowerCase().includes(searchQuery.toLowerCase()))

            const filteredTrendsByTextAndDate = filteredTrendsByText
                .filter(history => history.dates.find(date => date.includes(dateQuery)))

            setSearchResults(filteredTrendsByTextAndDate)
        }
    }, [searchQuery, dateQuery, trendHistory])

    const getTrendHistoryPageContent = () => {
        if (error)
            return (
                <NotAvailable notice={"We couldn't find the author for this trend."} />
            );

        return (
            <React.Fragment>
                <Separator primaryLabel="Bot Activity From Past Trends" />
                <div className="pt-4 px-4 md:px-6">
                    <Infobox>
                        The following chart shows the <strong>bot percentages</strong> for
                        the past top trends from Germany, the USA and the UK.
                    </Infobox>
                </div>
                <div className="mx-auto mt-12 mb-4">
                    {(dateRange && chartData) &&
                        <LineChart
                            initialWidth={window.innerWidth}
                            initialHeight={400}
                            data={chartData}
                            dateRange={dateRange}
                        />}
                </div>
                <div className="mt-12 mb-16">
                    <form
                        className="px-0 md:px-6 border-t-[1px] border-b-[1px] border-black flex flex-col md:flex-row justify-evenly w-full"
                        onSubmit={(event) => event.preventDefault()}
                    >
                        <div className=" w-full md:w-4/5 border-b-[1px] md:border-b-0 mx-auto md:border-r-[1px] border-black">
                            <Search searchQuery={searchQuery} handleChange={setSearchQuery} />
                        </div>
                        <div className="w-full md:w-4/5 mx-auto">
                            {dateRange &&
                                <DatePicker
                                    min={dateRange.min}
                                    max={dateRange.max}
                                    dateQuery={dateQuery}
                                    handleChange={setDateQuery}
                                />}
                        </div>
                    </form>
                    <AccordionList historyItems={searchResults} />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <TrendHistoryPageHeader
                dateRange={dateRange}
                loading={loading}
            />
            {getTrendHistoryPageContent()}
        </React.Fragment>
    );
}

export default TrendHistoryPage